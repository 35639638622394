import React, { useState } from 'react';
import {
	CommandButton,
	Toggle,
} from 'office-ui-fabric-react';
import styles from './Settings.module.scss';
import { IconWithCallout } from '../atoms/IconWithCallout';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { IMembers, ISurvey } from '../../store/business/interfaces';

export interface Props {
	survey: ISurvey;
	onClose: () => void;
	readOnly?: boolean;
	members: IMembers;
	onUpsertSurvey: (survey: ISurvey) => void;
}

declare class ClipboardItem {
	constructor(input: { [contentType: string]: Blob });
}

export const Settings = (props: Props) => {
	const questions = useSelector((state: AppState) => state.business.questions);
	const [useQuickVotes, setUseQuickVotes] = useState(props.survey.useQuickVotes);
	const [disableResultsInMemberView, setDisableResultsInMemberView] = useState(props.survey.disableResultsInMemberView);
	const [members, setMembers] = useState(props.members.entities);

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Sitzung verwalten</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onClose();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<div className={styles.shareContent}>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Schnelle Abstimmungen'
						checked={useQuickVotes}
						disabled={props.readOnly || questions?.length > 0 || props.survey.useVoteWeight || members?.length > 0}
						onChange={(evt, newUseQuickVotes) => {
							setUseQuickVotes(newUseQuickVotes);
							props.onUpsertSurvey({...props.survey, useQuickVotes: newUseQuickVotes, useVoteWeight: false, useVoteWeightInPercent: false});
						}}
					></Toggle>
					{(!props.readOnly && (
						<IconWithCallout
							iconName='Info'
							renderCalloutContent={()=>{ return (
								<span>Teilnehmer können sich ohne PIN anmelden. Die Teilnehmerliste wird nach der Anmeldung automatisch befüllt. Bei einer aktivierten Stimmengewichtung sind keine schnellen Abstimmungen möglich.</span>
							);}}
							iconContainerClassName="votrInfoIcon"
						/>
					))}
				</div>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Ergebnisse nur Organisatoren anzeigen'
						checked={disableResultsInMemberView}
						disabled={props.readOnly}
						onChange={(evt, newDisableResultsInMemberView) => {
							setDisableResultsInMemberView(newDisableResultsInMemberView);
							props.onUpsertSurvey({...props.survey, disableResultsInMemberView: newDisableResultsInMemberView});
						}}
					></Toggle>
				</div>
			</div>
		</div>
	);
};
