import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const OutlookColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        d="M17.8 9.52L11.5 5.92H11.41C11.3005 5.8427 11.1732 5.79453 11.04 5.78C10.9047 5.77891 10.7711 5.80974 10.65 5.87H10.56L4.25998 9.47C4.20058 9.50425 4.15156 9.55394 4.11811 9.6138C4.08466 9.67366 4.06803 9.74145 4.06998 9.81C4.06975 9.87571 4.08613 9.94042 4.1176 9.99811C4.14906 10.0558 4.1946 10.1046 4.24998 10.14L11.04 15L17.85 10.19C17.9066 10.1551 17.9536 10.1067 17.9868 10.0491C18.0199 9.99144 18.0382 9.92646 18.04 9.86C18.0362 9.78644 18.0116 9.71548 17.9691 9.65531C17.9266 9.59514 17.868 9.54822 17.8 9.52Z"
        fill="#0A2767"
      />
      <path
        d="M16.04 2H6.04004C5.77482 2 5.52047 2.10536 5.33293 2.29289C5.1454 2.48043 5.04004 2.73478 5.04004 3L11.04 5L17.04 3C17.04 2.73478 16.9347 2.48043 16.7471 2.29289C16.5596 2.10536 16.3053 2 16.04 2Z"
        fill="#0364B8"
      />
      <path d="M11 3H5V9L10.99 15.01H16.99V8.99L11 3Z" fill="#28A8EA" />
      <path d="M17 3H11V9H17V3Z" fill="#50D9FF" />
      <path d="M10.99 15.01L5 15V9H11L10.99 15.01Z" fill="#0078D4" />
      <path
        opacity="0.5"
        d="M11.04 15L4.20996 10.17L4.71996 9.29L11.04 12.87L17.32 9.28L17.81 10.19L11.04 15Z"
        fill="#0A2767"
      />
      <path
        d="M17.81 10.19L11.5 13.78C11.3595 13.8697 11.1967 13.9183 11.03 13.92H10.97C10.887 13.9304 10.803 13.9304 10.72 13.92L13.04 17L17.68 17.83C17.7915 17.7483 17.8823 17.6414 17.9448 17.518C18.0074 17.3947 18.04 17.2583 18.04 17.12V9.85999C18.0342 9.9304 18.0099 9.99804 17.9695 10.056C17.9291 10.114 17.874 10.1602 17.81 10.19Z"
        fill="#1490DF"
      />
      <path
        opacity="0.1"
        d="M12.04 13.48L11.5 13.79C11.3595 13.8798 11.1967 13.9283 11.03 13.93H10.97C10.887 13.9405 10.803 13.9405 10.72 13.93L13.04 17L17.68 17.83C17.7915 17.7483 17.8823 17.6414 17.9448 17.518C18.0074 17.3947 18.04 17.2583 18.04 17.12V16.85L12.04 13.48Z"
        fill="black"
      />
      <path
        d="M4.17995 10.19C4.13193 10.1502 4.09415 10.0994 4.06979 10.042C4.04543 9.9846 4.0352 9.92218 4.03995 9.85999V17.13C4.04 17.3099 4.0958 17.4853 4.19967 17.6321C4.30354 17.779 4.45037 17.89 4.61995 17.95C4.71602 17.9651 4.81388 17.9651 4.90995 17.95H17.12C17.3026 17.9522 17.4813 17.8961 17.63 17.79L4.17995 10.19Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.5"
        d="M11.04 16C11.5705 16 12.0792 15.7893 12.4543 15.4142C12.8293 15.0391 13.04 14.5304 13.04 14V7C13.04 6.73478 12.9347 6.48043 12.7471 6.29289C12.5596 6.10536 12.3053 6 12.04 6H5.04004V9.07L4.25004 9.52C4.19344 9.55488 4.14643 9.60332 4.11325 9.66094C4.08008 9.71856 4.06179 9.78354 4.06004 9.85H4.04004V15.99L11.04 16Z"
        fill="black"
      />
      <path
        d="M11 5H3C2.44772 5 2 5.44772 2 6V14C2 14.5523 2.44772 15 3 15H11C11.5523 15 12 14.5523 12 14V6C12 5.44772 11.5523 5 11 5Z"
        fill="#0078D4"
      />
      <path
        d="M4.20009 8.36999C4.4433 7.87674 4.83032 7.46881 5.31009 7.19999C5.84108 6.91364 6.43691 6.76899 7.04009 6.77999C7.6126 6.7651 8.17872 6.90318 8.68009 7.17999C9.1589 7.42919 9.55238 7.81571 9.81009 8.28999C10.0679 8.79774 10.1983 9.36059 10.1901 9.92999C10.2022 10.5265 10.0684 11.117 9.80009 11.65C9.54295 12.1263 9.15414 12.5186 8.68009 12.78C8.15853 13.0617 7.57271 13.203 6.98009 13.19C6.39396 13.2018 5.8149 13.0605 5.30009 12.78C4.83481 12.5183 4.45336 12.1299 4.20009 11.66C3.94353 11.1589 3.81309 10.6029 3.82009 10.04C3.8144 9.46126 3.94456 8.88927 4.20009 8.36999ZM5.39009 11.1C5.51592 11.4124 5.73171 11.6804 6.01009 11.87C6.30578 12.066 6.65563 12.1639 7.01009 12.15C7.36504 12.1564 7.71364 12.0553 8.01009 11.86C8.28645 11.6707 8.4991 11.4023 8.62009 11.09C8.7527 10.7492 8.81725 10.3856 8.81009 10.02C8.8138 9.65222 8.75288 9.28667 8.63009 8.93999C8.51851 8.61009 8.30358 8.32493 8.01718 8.12679C7.73078 7.92865 7.38815 7.82808 7.04009 7.83999C6.68611 7.82999 6.33742 7.92763 6.04009 8.11999C5.75823 8.31096 5.53631 8.57796 5.40009 8.88999C5.24769 9.23983 5.17265 9.61846 5.18009 9.99999C5.16838 10.3776 5.24008 10.7532 5.39009 11.1Z"
        fill="white"
      />
    </svg>
  ),
  displayName: 'OutlookColorIcon',
});
