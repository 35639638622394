import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const NotificationsMutedIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M4.0044 7.56824C4.03648 6.75282 4.2411 5.98297 4.58356 5.29065L2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L17.8536 17.1464C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L14.2972 15.0043L12.4996 15.004L12.4946 15.1653C12.4095 16.469 11.3252 17.5 10 17.5C8.67453 17.5 7.58998 16.4685 7.50533 15.1644L7.49962 15.004L3.99891 15.0046C3.91096 15.0046 3.82358 14.993 3.73902 14.9702L3.61456 14.9277C3.20378 14.7567 2.96181 14.3392 3.01221 13.8757L3.0333 13.7483L3.07572 13.6202L3.99902 11.401L4.0001 7.79281L4.0044 7.56824ZM13.299 14.0061L5.33843 6.04552C5.16766 6.46607 5.05719 6.91575 5.01715 7.38498L5.00393 7.59723L5.00002 7.80214V11.5L4.96161 11.6922L3.9989 14.0046L13.299 14.0061ZM11.4996 15.004H8.49962L8.50697 15.1454C8.57552 15.8581 9.14275 16.425 9.85556 16.4931L10 16.5C10.7797 16.5 11.4205 15.9051 11.4931 15.1445L11.4996 15.004Z" />
        <path d="M15.0384 11.6922L15.9133 13.7919L16.7653 14.644C16.9153 14.4691 17.0013 14.2458 17.0013 14.0046L16.9926 13.8733L16.9774 13.7871C16.9647 13.7302 16.947 13.6743 16.9244 13.6202L15.999 11.398L16 7.80214L15.9955 7.57762L15.9821 7.3554C15.7416 4.33488 13.1466 2 9.99766 2C8.3584 2 6.86951 2.63266 5.78523 3.66389L6.49193 4.3706C7.39369 3.52222 8.63126 3 9.99766 3C12.6947 3 14.8909 5.0349 14.996 7.60831L15.0001 7.81241L15 11.5L15.0384 11.6922Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M4.00433 7.56824C4.03641 6.7528 4.24104 5.98294 4.58352 5.29061L2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L17.8536 17.1464C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L14.2975 15.0046H3.7513C3.6628 15.0046 3.575 14.9889 3.49197 14.9583C3.20507 14.8526 3.0163 14.5865 3.00952 14.2422L3.01412 14.1247L3.04756 13.9952L3.99895 11.411L4.00003 7.79281L4.00433 7.56824Z" />
        <path d="M16.999 14.2546C16.999 14.426 16.9411 14.5856 16.8357 14.7144L5.78519 3.66386C6.86947 2.63265 8.35835 2 9.99759 2C13.1465 2 15.7415 4.33488 15.9821 7.3554L15.9954 7.57762L15.9999 7.80214L15.9989 11.408L16.9527 13.9951L16.9783 14.0796L16.9938 14.1664L16.999 14.2546Z" />
        <path d="M12.4496 16.0016C12.2174 17.1419 11.2089 18 9.99995 18C8.79102 18 7.78251 17.1419 7.55027 16.0016H12.4496Z" />
      </g>
    </svg>
  ),
  displayName: 'NotificationsMutedIcon',
});
