import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const CallEndIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" className={classes.svg} viewBox="2 2 16 16">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M10 6c3.332-.004 5.586 1.276 7.154 2.66.66.582.962 1.458.805 2.28l-.157.83c-.148.776-.875 1.298-1.698 1.218l-1.637-.158c-.714-.069-1.243-.59-1.467-1.33-.304-1.005-.5-1.75-.5-1.75a6.188 6.188 0 00-2.5-.5c-1.014 0-1.738.215-2.5.5 0 0-.204.746-.5 1.75-.198.671-.504 1.257-1.203 1.327l-1.628.164a1.735 1.735 0 01-1.822-1.21l-.248-.828a2.255 2.255 0 01.577-2.28C4.101 7.287 6.673 6.003 10 6zm3.475 3.527l.026.095.098.358c.086.306.208.736.358 1.23.137.451.39.604.606.625l1.637.158c.346.033.575-.177.62-.41l.157-.83a1.454 1.454 0 00-.485-1.344C15.067 8.152 13.04 6.996 10.002 7c-3.076.004-5.396 1.192-6.629 2.39a1.255 1.255 0 00-.316 1.277l.248.829c.091.306.42.534.764.5l1.628-.164c.039-.004.052-.012.054-.013a.196.196 0 00.05-.05c.07-.088.153-.255.24-.551A115.653 115.653 0 006.5 9.615l.027-.097c.102-.367.458-.622.623-.705.819-.306 1.67-.563 2.85-.563 1.165 0 2.033.228 2.88.575.151.062.482.284.587.671l.002.007.006.024z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M17.96 10.94l-.159.83c-.147.776-.874 1.298-1.697 1.218l-1.637-.158c-.714-.069-1.243-.59-1.467-1.33-.304-1.005-.5-1.75-.5-1.75a6.188 6.188 0 00-2.5-.5c-1.014 0-1.738.215-2.5.5 0 0-.204.746-.5 1.75-.198.67-.504 1.257-1.203 1.327l-1.628.163a1.735 1.735 0 01-1.822-1.208l-.248-.83a2.255 2.255 0 01.577-2.28c1.425-1.384 3.99-2.164 7.316-2.168 3.332-.004 5.594.772 7.162 2.156.66.582.962 1.458.805 2.28z"
      />
    </svg>
  ),
  displayName: 'CallEndIcon',
});
