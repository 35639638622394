import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ComputerAudioOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M2.85355 2.1464C2.65829 1.95114 2.34171 1.95114 2.14645 2.1464C1.95118 2.34166 1.95118 2.65825 2.14645 2.85351L17.1464 17.8535C17.3417 18.0488 17.6583 18.0488 17.8536 17.8535C18.0488 17.6582 18.0488 17.3417 17.8536 17.1464L2.85355 2.1464Z" />
        <path d="M2 4C2 3.62909 2.10097 3.28177 2.27692 2.98401L3.03388 3.74097C3.01178 3.82358 3 3.91042 3 4V13C3 13.5523 3.44772 14 4 14H9V15H8V17H10.485L10.5 17.0001H10.8787L11.8786 18H5.5C5.22386 18 5 17.7761 5 17.5C5 17.2239 5.22386 17 5.5 17H7V15H4C2.89543 15 2 14.1046 2 13V4Z" />
        <path d="M11.2929 13L11.7929 12.5L14 14.7071V17.5C14 17.7022 13.8782 17.8846 13.6913 17.962C13.5045 18.0393 13.2894 17.9966 13.1464 17.8536L11.2929 16H10.5C10.2239 16 10 15.7762 10 15.5V13.5C10 13.2239 10.2239 13 10.5 13H11.2929Z" />
        <path d="M15 16.4886V16.5107C14.9999 16.5047 14.9998 16.4988 14.9999 16.4928L15 16.4886Z" />
        <path d="M18.5 14.5C18.5 15.1202 18.4334 15.6847 18.3142 16.1929L17.4518 15.3305C17.4832 15.0696 17.5 14.7928 17.5 14.5C17.5 13.686 17.3705 12.9954 17.1568 12.4256C17.0688 12.1909 16.9838 12.0174 16.9182 11.9052L16.85 11.8C16.6843 11.5791 16.7291 11.2657 16.95 11.1C17.1709 10.9343 17.4843 10.9791 17.65 11.2C17.7712 11.3617 17.9351 11.6531 18.0932 12.0745C18.3482 12.7547 18.5 13.564 18.5 14.5Z" />
        <path d="M16.2243 12.9669C16.382 13.4401 16.4824 13.9161 16.4979 14.3766L15.2054 13.084C15.1456 12.9241 15.0908 12.7997 15.0528 12.7236C14.9293 12.4766 15.0294 12.1763 15.2764 12.0528C15.5234 11.9293 15.8237 12.0294 15.9472 12.2764C16.0196 12.4211 16.1224 12.661 16.2243 12.9669Z" />
        <path d="M16 3H5.12134L4.12134 2H16C17.1046 2 18 2.89543 18 4V10.2006C17.6975 10.0262 17.3425 9.96316 17 10.0207V4C17 3.44772 16.5523 3 16 3Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M2.14619 2.85329C1.95118 2.65801 1.95127 2.34162 2.14645 2.14645C2.34162 1.95127 2.65801 1.95118 2.85329 2.14619C2.85329 2.14619 2.8533 2.14618 2.85329 2.14619L17.8536 17.1464C17.8927 17.1856 17.924 17.2297 17.9475 17.2766C17.9475 17.2766 17.9475 17.2766 17.9475 17.2766C18.041 17.4638 18.0096 17.6975 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L14 14.7071V17.5C14 17.7022 13.8782 17.8846 13.6913 17.962C13.5045 18.0393 13.2894 17.9966 13.1464 17.8536L11.2929 16H10.5C10.2239 16 10 15.7762 10 15.5V13.5C10 13.2239 10.2239 13 10.5 13H11.2929L11.7929 12.5L11.0858 11.7929L10.8787 12.0001H10.5C9.67157 12.0001 9 12.6716 9 13.5001V15H8V17H10.485L10.5 17.0001H10.8787L11.8786 18H5.5C5.22386 18 5 17.7761 5 17.5C5 17.2239 5.22386 17 5.5 17H7V15H3.5C2.67157 15 2 14.3284 2 13.5V3.5C2 3.26843 2.05248 3.04911 2.14619 2.85329Z" />
        <path d="M18.5 14.5C18.5 15.1202 18.4334 15.6847 18.3142 16.1929L17.4518 15.3305C17.4832 15.0696 17.5 14.7928 17.5 14.5C17.5 13.686 17.3705 12.9954 17.1568 12.4256C17.0688 12.1909 16.9838 12.0174 16.9182 11.9052L16.85 11.8C16.6843 11.5791 16.7291 11.2657 16.95 11.1C17.1709 10.9343 17.4843 10.9791 17.65 11.2C17.7712 11.3617 17.9351 11.6531 18.0932 12.0745C18.3482 12.7547 18.5 13.564 18.5 14.5Z" />
        <path d="M16.2243 12.9669C16.382 13.4401 16.4824 13.9161 16.4979 14.3766L15.2054 13.084C15.1456 12.9241 15.0908 12.7997 15.0528 12.7236C14.9293 12.4766 15.0294 12.1763 15.2764 12.0528C15.5234 11.9293 15.8237 12.0294 15.9472 12.2764C16.0196 12.4211 16.1224 12.661 16.2243 12.9669Z" />
        <path d="M14.074 10.1143C13.5401 9.89309 12.9293 9.99906 12.5018 10.3804L4.12134 2H16.5C17.3284 2 18 2.67157 18 3.5V10.2006C17.4956 9.90978 16.8453 9.92862 16.35 10.3001C16.0932 10.4926 15.9158 10.7517 15.8237 11.0354C15.5389 10.9723 15.2342 10.9915 14.9473 11.1055C14.8271 10.6649 14.5094 10.2946 14.074 10.1143Z" />
      </g>
    </svg>
  ),
  displayName: 'ComputerAudioOffIcon',
});
