import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const MicrophoneDisabledIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M9 13C9.07028 13 9.14 12.9976 9.20907 12.9928C9.31908 12.6059 9.47039 12.2363 9.65793 11.8892C9.45189 11.961 9.2305 12 9 12C7.89543 12 7 11.1046 7 9.99998V5C7 3.89543 7.89543 3 9 3C10.1046 3 11 3.89543 11 5V9.99998C11 10.0924 10.9937 10.1833 10.9816 10.2724C11.2937 10.0124 11.6351 9.78627 12 9.59971V5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5V9.99998C6 11.6568 7.34315 13 9 13Z" />
        <path d="M4.5 9.99998C4.5 12.4853 6.51472 14.5 9 14.5C9 15.3189 9.179 16.0961 9.5 16.7945V17.5C9.5 17.7761 9.27614 18 9 18C8.72386 18 8.5 17.7761 8.5 17.5V15.4776C5.69675 15.2249 3.5 12.869 3.5 9.99998C3.5 9.72384 3.72386 9.49998 4 9.49998C4.27614 9.49998 4.5 9.72384 4.5 9.99998Z" />
        <path d="M14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5C19 16.9853 16.9853 19 14.5 19ZM14.5 11C12.567 11 11 12.567 11 14.5C11 15.2862 11.2592 16.0118 11.6968 16.5961L16.5961 11.6968C16.0118 11.2592 15.2862 11 14.5 11ZM12.4039 17.3032C12.9882 17.7408 13.7138 18 14.5 18C16.433 18 18 16.433 18 14.5C18 13.7138 17.7408 12.9882 17.3032 12.4039L12.4039 17.3032Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M9.20907 12.9928C9.62981 11.513 10.6546 10.2875 12 9.59971V5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5V9.99998C6 11.6568 7.34315 13 9 13C9.07028 13 9.14 12.9976 9.20907 12.9928Z" />
        <path d="M9.5 17.5V16.7945C9.179 16.0961 9 15.319 9 14.5C6.51472 14.5 4.5 12.4853 4.5 9.99998C4.5 9.72384 4.27614 9.49998 4 9.49998C3.72386 9.49998 3.5 9.72384 3.5 9.99998C3.5 12.869 5.69675 15.2249 8.5 15.4776V17.5C8.5 17.7761 8.72386 18 9 18C9.27614 18 9.5 17.7761 9.5 17.5Z" />
        <path d="M14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5C19 16.9853 16.9853 19 14.5 19ZM14.5 11C12.567 11 11 12.567 11 14.5C11 15.2862 11.2592 16.0118 11.6968 16.5961L16.5961 11.6968C16.0118 11.2592 15.2862 11 14.5 11ZM12.4039 17.3032C12.9882 17.7408 13.7138 18 14.5 18C16.433 18 18 16.433 18 14.5C18 13.7138 17.7408 12.9882 17.3032 12.4039L12.4039 17.3032Z" />
      </g>
    </svg>
  ),
  displayName: 'MicrophoneDisabledIcon',
});
