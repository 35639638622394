import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const HighlightIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg viewBox="2 2 16 16" role="presentation" focusable="false" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M3 2C3.27614 2 3.5 2.22386 3.5 2.5V5.5C3.5 5.77614 3.72386 6 4 6H16C16.2761 6 16.5 5.77614 16.5 5.5V2.5C16.5 2.22386 16.7239 2 17 2C17.2761 2 17.5 2.22386 17.5 2.5V5.5C17.5 6.32843 16.8284 7 16 7H15.809L12.2236 14.1708C12.0615 14.4951 11.7914 14.7431 11.4695 14.8802C11.4905 15.0808 11.5 15.2891 11.5 15.5C11.5 16.0818 11.4278 16.6623 11.2268 17.1165C11.019 17.5862 10.6266 18 10 18C9.37343 18 8.98105 17.5862 8.77323 17.1165C8.57222 16.6623 8.5 16.0818 8.5 15.5C8.5 15.2891 8.50952 15.0808 8.53051 14.8802C8.20863 14.7431 7.93851 14.4951 7.77639 14.1708L4.19098 7H4C3.17157 7 2.5 6.32843 2.5 5.5V2.5C2.5 2.22386 2.72386 2 3 2ZM9.11803 14H10.882C11.0714 14 11.2445 13.893 11.3292 13.7236L14.691 7H5.30902L8.67082 13.7236C8.75552 13.893 8.92865 14 9.11803 14ZM9.52346 15C9.50787 15.1549 9.5 15.3225 9.5 15.5C9.5 16.0228 9.56841 16.4423 9.6877 16.7119C9.8002 16.9661 9.90782 17 10 17C10.0922 17 10.1998 16.9661 10.3123 16.7119C10.4316 16.4423 10.5 16.0228 10.5 15.5C10.5 15.3225 10.4921 15.1549 10.4765 15H9.52346Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M8.51937 15C8.50601 15.1646 8.5 15.3322 8.5 15.5C8.5 16.0818 8.57222 16.6623 8.77323 17.1165C8.98105 17.5862 9.37343 18 10 18C10.6266 18 11.019 17.5862 11.2268 17.1165C11.4278 16.6623 11.5 16.0818 11.5 15.5C11.5 15.3322 11.494 15.1646 11.4806 15H8.51937ZM2.5 5.5V2.5C2.5 2.22386 2.72386 2 3 2H17C17.2761 2 17.5 2.22386 17.5 2.5V5.5C17.5 6.32843 16.8284 7 16 7H4C3.17157 7 2.5 6.32843 2.5 5.5ZM4.75 8L7.47361 13.4472C7.643 13.786 7.98926 14 8.36803 14H11.632C12.0107 14 12.357 13.786 12.5264 13.4472L15.25 8H4.75Z"
      />
    </svg>
  ),
  displayName: 'HighlightIcon',
});
