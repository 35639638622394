import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const UserBlurIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M6.207 4L2 8.207V6.793L4.793 4h1.414zM3.5 16H5v-1.707l-1.695 1.695c.064.008.13.012.195.012zM15.207 16H15v-1.207l3-3v1.414L15.207 16zM18 9.707l-3.098 3.098a2.492 2.492 0 00-.494-.92L18 8.293v1.414zM12.5 12a1.498 1.498 0 011.5 1.5V16h-1v-2.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5V16H6v-2.5A1.501 1.501 0 017.5 12h5zM9.722 10.985A2.51 2.51 0 017.707 9.5a2.491 2.491 0 01-.198-1.216 2.5 2.5 0 112.213 2.7zM10 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2.12 15.088l4.844-4.845a3.478 3.478 0 01-.39-1.024L2 13.793v.707c0 .209.043.407.12.588zM11.743 5.464a3.478 3.478 0 00-1.024-.39L11.793 4h1.414l-1.464 1.464zM13.32 7.388a3.487 3.487 0 00-.48-.935L15.293 4H16.5c.066 0 .13.004.195.013l-3.376 3.375zM12.45 11c.439-.43.765-.975.93-1.587l4.5-4.5c.078.18.12.378.12.587v.707l-4.872 4.872c-.2-.051-.411-.079-.628-.079h-.05zM2 11.707L9.707 4H8.293L2 10.293v1.414z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M6.207 4L2 8.207V6.793L4.793 4h1.414zM13.207 4l-1.464 1.464a3.478 3.478 0 00-1.024-.39L11.793 4h1.414zM12.12 7.173A2.492 2.492 0 009.783 6.01a2.5 2.5 0 102.335 1.164zM12.84 6.453c.204.283.367.597.48.935l3.375-3.375A1.517 1.517 0 0016.5 4h-1.207L12.84 6.453zM7.5 12A1.501 1.501 0 006 13.5V16h8v-2.5a1.498 1.498 0 00-1.5-1.5h-5zM12.5 11c.217 0 .427.028.628.08L18 6.206V5.5c0-.209-.043-.407-.12-.588l-4.5 4.501A3.497 3.497 0 0112.45 11h.05zM14.408 11.885c.225.265.395.577.494.92L18 9.707V8.293l-3.592 3.592zM15 16v-1.207l3-3v1.414L15.207 16H15zM5 15.707v-1.414l-1.695 1.695c.064.008.13.012.195.012h1.207L5 15.707zM6.574 9.219c.076.365.21.71.39 1.024L2.12 15.088A1.495 1.495 0 012 14.5v-.707l4.574-4.574zM9.707 4L2 11.707v-1.414L8.293 4h1.414z" />
      </g>
    </svg>
  ),
  displayName: 'UserBlurIcon',
});
