import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const SpotlightStopIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      className={classes.svg}
      viewBox="2 2 16 16"
      role="presentation"
      focusable="false"
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M2.85355 2.14646C2.65829 1.9512 2.34171 1.9512 2.14645 2.14646C1.95118 2.34172 1.95118 2.65831 2.14645 2.85357L3.30539 4.01251C2.5689 4.10792 2 4.73752 2 5.5V14.5C2 15.3284 2.67157 16 3.5 16H9.20703C9.11588 15.6777 9.05337 15.3434 9.02242 15H7V13.5C7 13.2239 7.22386 13 7.5 13H9.20703C9.30564 12.6514 9.43777 12.3168 9.59971 12H7.5C6.67157 12 6 12.6716 6 13.5V15H3.5C3.22386 15 3 14.7761 3 14.5V5.5C3 5.22386 3.22386 5 3.5 5H4.29288L7.51524 8.22236C7.50517 8.31352 7.5 8.40616 7.5 8.5C7.5 9.88071 8.61929 11 10 11C10.0909 11 10.1806 10.9952 10.269 10.9857C10.2705 10.984 10.2719 10.9822 10.2734 10.9805L10.984 11.6912C10.3682 12.461 10 13.4375 10 14.5C10 16.9853 12.0147 19 14.5 19C15.5838 19 16.5781 18.6168 17.3549 17.9786C17.5257 18.0302 17.7186 17.9885 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1465L2.85355 2.14646ZM16.4965 17.2036C16.37 17.4707 16.0263 17.6073 15.75 17.398L14.5 16.4513L13.25 17.398C12.8578 17.6951 12.33 17.2953 12.4798 16.8146L12.9572 15.2829L11.7073 14.3362C11.315 14.0391 11.5166 13.3923 12.0015 13.3923H12.6852L16.4965 17.2036Z" />
        <path d="M8.57011 6.44902L9.29615 7.17505C9.50604 7.06332 9.74563 7 10 7C10.8284 7 11.5 7.67157 11.5 8.5C11.5 8.75437 11.4367 8.99396 11.3249 9.20385L11.8181 9.69704C11.9781 9.60754 12.1429 9.5258 12.3122 9.45233C12.4332 9.15881 12.5 8.8372 12.5 8.5C12.5 7.11929 11.3807 6 10 6C9.46827 6 8.9753 6.16601 8.57011 6.44902Z" />
        <path d="M12.5597 10.4386L14.0139 11.8928L14.024 11.8605C14.1738 11.3798 14.8262 11.3798 14.976 11.8605L15.4264 13.3053L15.5134 13.3923H16.9985C17.4834 13.3923 17.685 14.0391 17.2927 14.3362L16.8173 14.6962L18.5614 16.4403C18.8425 15.8528 19 15.1948 19 14.5C19 12.0147 16.9853 10 14.5 10C13.8052 10 13.1472 10.1575 12.5597 10.4386Z" />
        <path d="M6.12109 4L7.12109 5H16.5C16.7761 5 17 5.22386 17 5.5V9.59971C17.3578 9.78261 17.6929 10.0035 18 10.2572V5.5C18 4.67157 17.3284 4 16.5 4H6.12109Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M2.85355 2.14646C2.65829 1.9512 2.34171 1.9512 2.14645 2.14646C1.95118 2.34172 1.95118 2.65831 2.14645 2.85357L3.30539 4.01251C2.5689 4.10792 2 4.73752 2 5.5V14.5C2 15.3284 2.67157 16 3.5 16H9.20703C9.07217 15.5232 9 15.02 9 14.5C9 13.5998 9.21628 12.7501 9.59971 12H7.5C6.67157 12 6 12.6716 6 13.5V15H3.5C3.22386 15 3 14.7761 3 14.5V5.5C3 5.22386 3.22386 5 3.5 5H4.29288L7.51524 8.22236C7.50517 8.31352 7.5 8.40616 7.5 8.5C7.5 9.88071 8.61929 11 10 11C10.0909 11 10.1806 10.9951 10.269 10.9857C10.2705 10.984 10.2719 10.9822 10.2734 10.9805L10.984 11.6912C10.3682 12.461 10 13.4375 10 14.5C10 16.9853 12.0147 19 14.5 19C15.5838 19 16.5781 18.6168 17.3549 17.9786C17.5257 18.0302 17.7186 17.9885 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1465L2.85355 2.14646ZM16.4965 17.2036C16.37 17.4707 16.0263 17.6073 15.75 17.398L14.5 16.4513L13.25 17.398C12.8578 17.6951 12.33 17.2953 12.4798 16.8146L12.9572 15.2829L11.7073 14.3362C11.315 14.0391 11.5166 13.3923 12.0015 13.3923H12.6852L16.4965 17.2036Z" />
        <path d="M8.57011 6.44902L11.8181 9.69704C11.9781 9.60754 12.1429 9.5258 12.3122 9.45233C12.4332 9.15881 12.5 8.8372 12.5 8.5C12.5 7.11929 11.3807 6 10 6C9.46827 6 8.9753 6.16601 8.57011 6.44902Z" />
        <path d="M12.5597 10.4386L14.0139 11.8928L14.024 11.8605C14.1738 11.3798 14.8262 11.3798 14.976 11.8605L15.4264 13.3053L15.5134 13.3923H16.9985C17.4834 13.3923 17.685 14.0391 17.2927 14.3362L16.8173 14.6962L18.5614 16.4403C18.8425 15.8528 19 15.1948 19 14.5C19 12.0147 16.9853 10 14.5 10C13.8052 10 13.1472 10.1575 12.5597 10.4386Z" />
        <path d="M6.12109 4L7.12109 5H16.5C16.7761 5 17 5.22386 17 5.5V9.59971C17.3578 9.78261 17.6929 10.0035 18 10.2572V5.5C18 4.67157 17.3284 4 16.5 4H6.12109Z" />
      </g>
    </svg>
  ),
  displayName: 'SpotlightStopIcon',
});
