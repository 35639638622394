import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const TiltPanZoomIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M8.95235 3.53418C9.61709 3.22738 10.3829 3.22738 11.0476 3.53418L15.7095 5.68582C15.8866 5.76754 16 5.94477 16 6.1398V8H17V6.1398C17 5.55472 16.6598 5.02304 16.1286 4.77786L11.4667 2.62622C10.5361 2.1967 9.46393 2.1967 8.5333 2.62622L3.87141 4.77786C3.34019 5.02304 3 5.55472 3 6.1398V8H4L4 6.1398C4 5.94477 4.1134 5.76754 4.29047 5.68582L8.95235 3.53418Z" />
        <path d="M3.39872 11C3.58077 11.1499 3.7811 11.296 4 11.4374C4.05121 11.4705 4.10344 11.5033 4.15669 11.5358C5.6041 12.4204 7.66726 13 9.9992 13C12.3311 13 14.3943 12.4204 15.8417 11.5358C15.8955 11.503 15.9483 11.4698 16 11.4364C16.2183 11.2953 16.4181 11.1495 16.5997 11C16.7447 10.8806 16.878 10.7589 17 10.6353C17.2046 10.428 17.377 10.2154 17.5179 10H16.0002C16.0002 10 16.0001 10 16.0002 10C15.7241 9.99992 15.5002 9.77609 15.5002 9.5C15.5002 9.22386 15.724 9 16.0002 9H18.5002C18.6328 9 18.7599 9.05268 18.8537 9.14645C18.9475 9.24021 19.0002 9.36739 19.0002 9.5V12C19.0002 12.2761 18.7763 12.5 18.5002 12.5C18.224 12.5 18.0002 12.2761 18.0002 12V11.0211C17.7185 11.3542 17.3827 11.6676 17 11.9567C16.8002 12.1076 16.5876 12.252 16.3632 12.3891C16.2453 12.4611 16.1242 12.5312 16 12.5991C14.4076 13.4703 12.3046 14 9.9992 14C7.69452 14 5.59217 13.4706 4 12.6C3.87523 12.5318 3.75359 12.4614 3.63524 12.3891C3.41142 12.2523 3.19935 12.1084 3 11.9579C2.61739 11.669 2.2817 11.356 2 11.0232V12C2 12.2761 1.77614 12.5 1.5 12.5C1.22386 12.5 1 12.2761 1 12V9.5C1 9.36739 1.05268 9.24021 1.14645 9.14645C1.24021 9.05268 1.36739 9 1.5 9H4C4.27614 9 4.5 9.22386 4.5 9.5C4.5 9.77614 4.27614 10 4 10H2.48054C2.62181 10.216 2.79473 10.4291 3 10.6369C3.12152 10.76 3.25437 10.8812 3.39872 11Z" />
        <path d="M4 13.7241V13.8601C4 14.0552 4.1134 14.2324 4.29047 14.3141L8.95235 16.4658C9.61709 16.7726 10.3829 16.7726 11.0476 16.4657L15.7095 14.3141C15.8866 14.2324 16 14.0552 16 13.8601V13.7233C16.3511 13.5543 16.6852 13.37 17 13.1714V13.8601C17 14.4452 16.6598 14.9769 16.1286 15.2221L11.4667 17.3737C10.5361 17.8032 9.46393 17.8032 8.5333 17.3737L3.87141 15.2221C3.34019 14.9769 3 14.4452 3 13.8601V13.1724C3.31477 13.371 3.64893 13.5552 4 13.7241Z" />
        <path d="M6.04031 6.80305C6.14909 6.54923 6.44303 6.43166 6.69684 6.54043L9.99988 7.95602L13.3029 6.54043C13.5567 6.43166 13.8507 6.54923 13.9595 6.80305C14.0682 7.05686 13.9507 7.3508 13.6968 7.45958L10.4999 8.8297V11C10.4999 11.2761 10.276 11.5 9.99988 11.5C9.72374 11.5 9.49988 11.2761 9.49988 11V8.8297L6.30292 7.45958C6.04911 7.3508 5.93153 7.05686 6.04031 6.80305Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M8.5333 2.62622C9.46393 2.1967 10.5361 2.1967 11.4667 2.62622L16.1286 4.77786C16.6598 5.02304 17 5.55472 17 6.1398V8H16.0002C15.1717 8 14.5002 8.67157 14.5002 9.5C14.5002 10.0291 14.7741 10.4943 15.1879 10.7613C13.9251 11.493 12.1031 12 9.9992 12C7.89585 12 6.0742 11.4932 4.81139 10.7618C5.22567 10.4949 5.5 10.0295 5.5 9.5C5.5 8.67157 4.82843 8 4 8H3V6.1398C3 5.55472 3.34019 5.02304 3.87141 4.77786L8.5333 2.62622ZM6.69684 6.54043C6.44303 6.43166 6.14909 6.54923 6.04031 6.80305C5.93153 7.05686 6.04911 7.3508 6.30292 7.45958L9.49988 8.8297V10.5C9.49988 10.7761 9.72374 11 9.99988 11C10.276 11 10.4999 10.7761 10.4999 10.5V8.8297L13.6968 7.45958C13.9507 7.3508 14.0682 7.05686 13.9595 6.80305C13.8507 6.54923 13.5567 6.43166 13.3029 6.54043L9.99988 7.95602L6.69684 6.54043Z" />
        <path d="M15.5002 9.5C15.5002 9.22386 15.724 9 16.0002 9H18.5002C18.6328 9 18.7599 9.05268 18.8537 9.14645C18.9475 9.24021 19.0002 9.36739 19.0002 9.5V12C19.0002 12.2761 18.7763 12.5 18.5002 12.5C18.224 12.5 18.0002 12.2761 18.0002 12V11.0211C17.7185 11.3542 17.3827 11.6676 17 11.9567C16.8002 12.1076 16.5876 12.252 16.3632 12.3891C14.7345 13.3844 12.4846 14 9.9992 14C7.51384 14 5.26386 13.3844 3.63524 12.3891C3.41142 12.2523 3.19935 12.1084 3 11.9579C2.61739 11.669 2.2817 11.356 2 11.0232V12C2 12.2761 1.77614 12.5 1.5 12.5C1.22386 12.5 1 12.2761 1 12V9.5C1 9.36739 1.05268 9.24021 1.14645 9.14645C1.24021 9.05268 1.36739 9 1.5 9H4C4.27614 9 4.5 9.22386 4.5 9.5C4.5 9.77614 4.27614 10 4 10H2.48054C2.62181 10.216 2.79473 10.4291 3 10.6369C3.12152 10.76 3.25437 10.8812 3.39872 11C3.60933 11.1734 3.84439 11.3418 4.10436 11.5036C4.12169 11.5144 4.13913 11.5251 4.15669 11.5358C4.56794 11.7872 5.0289 12.0139 5.5316 12.2097C6.7982 12.703 8.32982 13 9.9992 13C11.6694 13 13.2017 12.7027 14.4687 12.2089C14.9706 12.0133 15.431 11.7868 15.8417 11.5358C16.1226 11.3642 16.375 11.185 16.5997 11C16.7447 10.8806 16.878 10.7589 17 10.6353C17.2046 10.428 17.377 10.2154 17.5179 10H16.0002C15.724 10 15.5002 9.77614 15.5002 9.5Z" />
        <path d="M9.9992 15C7.33525 15 4.83727 14.3316 3 13.1724V13.8601C3 14.4452 3.34019 14.9769 3.87141 15.2221L8.5333 17.3737C9.46393 17.8032 10.5361 17.8032 11.4667 17.3737L16.1286 15.2221C16.6598 14.9769 17 14.4452 17 13.8601V13.1714C15.1626 14.3312 12.6639 15 9.9992 15Z" />
      </g>
    </svg>
  ),
  displayName: 'TiltPanZoomIcon',
});
