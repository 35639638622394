import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const OcrOnIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="2 2 16 16" role="presentation" focusable="false">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M4.5 3C3.67157 3 3 3.67157 3 4.5V6.5C3 6.77614 2.77614 7 2.5 7C2.22386 7 2 6.77614 2 6.5V4.5C2 3.11929 3.11929 2 4.5 2H6.5C6.77614 2 7 2.22386 7 2.5C7 2.77614 6.77614 3 6.5 3H4.5ZM5 17C3.89543 17 3 16.1046 3 15V13.5C3 13.2239 2.77614 13 2.5 13C2.22386 13 2 13.2239 2 13.5V15C2 16.6569 3.34315 18 5 18H6.5C6.77614 18 7 17.7761 7 17.5C7 17.2239 6.77614 17 6.5 17H5ZM17 4.5C17 3.67157 16.3284 3 15.5 3H13.5C13.2239 3 13 2.77614 13 2.5C13 2.22386 13.2239 2 13.5 2H15.5C16.8807 2 18 3.11929 18 4.5V6.5C18 6.77614 17.7761 7 17.5 7C17.2239 7 17 6.77614 17 6.5V4.5ZM15 17C16.1046 17 17 16.1046 17 15V13.5C17 13.2239 17.2239 13 17.5 13C17.7761 13 18 13.2239 18 13.5V15C18 16.6569 16.6569 18 15 18H13.5C13.2239 18 13 17.7761 13 17.5C13 17.2239 13.2239 17 13.5 17H15ZM6.5 5C6.22386 5 6 5.22386 6 5.5V6.5C6 6.77614 6.22386 7 6.5 7C6.77614 7 7 6.77614 7 6.5V6H9.5V14H9C8.72386 14 8.5 14.2239 8.5 14.5C8.5 14.7761 8.72386 15 9 15H11C11.2761 15 11.5 14.7761 11.5 14.5C11.5 14.2239 11.2761 14 11 14H10.5V6H13V6.5C13 6.77614 13.2239 7 13.5 7C13.7761 7 14 6.77614 14 6.5V5.5C14 5.22386 13.7761 5 13.5 5H6.5Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M4.75 3.5C4.05964 3.5 3.5 4.05964 3.5 4.75V6.25C3.5 6.66421 3.16421 7 2.75 7C2.33579 7 2 6.66421 2 6.25V4.75C2 3.23122 3.23122 2 4.75 2H6.25C6.66421 2 7 2.33579 7 2.75C7 3.16421 6.66421 3.5 6.25 3.5H4.75ZM4.75 16.5C4.05964 16.5 3.5 15.9404 3.5 15.25V13.75C3.5 13.3358 3.16421 13 2.75 13C2.33579 13 2 13.3358 2 13.75V15.25C2 16.7688 3.23122 18 4.75 18H6.25C6.66421 18 7 17.6642 7 17.25C7 16.8358 6.66421 16.5 6.25 16.5H4.75ZM16.5 4.75C16.5 4.05964 15.9404 3.5 15.25 3.5H13.75C13.3358 3.5 13 3.16421 13 2.75C13 2.33579 13.3358 2 13.75 2H15.25C16.7688 2 18 3.23122 18 4.75V6.25C18 6.66421 17.6642 7 17.25 7C16.8358 7 16.5 6.66421 16.5 6.25V4.75ZM15.25 16.5C15.9404 16.5 16.5 15.9404 16.5 15.25V13.75C16.5 13.3358 16.8358 13 17.25 13C17.6642 13 18 13.3358 18 13.75V15.25C18 16.7688 16.7688 18 15.25 18H13.75C13.3358 18 13 17.6642 13 17.25C13 16.8358 13.3358 16.5 13.75 16.5H15.25ZM6.5 5C6.08579 5 5.75 5.33579 5.75 5.75V6.75C5.75 7.16421 6.08579 7.5 6.5 7.5C6.91421 7.5 7.25 7.16421 7.25 6.75V6.5H9.25V13.5H9C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15H11C11.4142 15 11.75 14.6642 11.75 14.25C11.75 13.8358 11.4142 13.5 11 13.5H10.75V6.5H12.75V6.75C12.75 7.16421 13.0858 7.5 13.5 7.5C13.9142 7.5 14.25 7.16421 14.25 6.75V5.75C14.25 5.33579 13.9142 5 13.5 5H6.5Z"
      />
    </svg>
  ),
  displayName: 'OcrOnIcon',
});
