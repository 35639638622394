import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const OneDriveIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 28 28" className={classes.svg}>
      <path
        d="M16.5002 17L21.4542 12.262C20.6262 9.021 17.6872 6.625 14.1872 6.625C11.5692 6.625 9.26522 7.968 7.92322 10.002C8.00022 10 16.5002 17 16.5002 17Z"
        fill="#0364B8"
      />
      <path
        d="M11.1522 10.8938V10.8948C10.2362 10.3278 9.15624 9.9998 8.00024 9.9998C7.97424 9.9998 7.94924 10.0018 7.92324 10.0018C4.64524 10.0428 2.00024 12.7118 2.00024 15.9998C2.00024 17.2758 2.39924 18.4568 3.07824 19.4298L10.5002 18.4998L16.1902 13.9118L11.1522 10.8938Z"
        fill="#0078D4"
      />
      <path
        d="M21.4543 12.2622C21.3453 12.2552 21.2363 12.2502 21.1253 12.2502C20.4383 12.2502 19.7853 12.3922 19.1933 12.6492L19.1923 12.6482L16.1903 13.9112L19.5003 18.0002L25.4173 19.4382C25.7893 18.7502 26.0003 17.9622 26.0003 17.1252C26.0003 14.5432 23.9933 12.4312 21.4543 12.2622Z"
        fill="#1490DF"
      />
      <path
        d="M3.07825 19.4297C4.16225 20.9827 5.96225 21.9997 8.00025 21.9997H21.1252C22.9802 21.9997 24.5932 20.9637 25.4172 19.4387L16.1902 13.9117L3.07825 19.4297Z"
        fill="#28A8EA"
      />
    </svg>
  ),
  displayName: 'OneDriveIcon',
});
