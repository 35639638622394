import React from "react";
import {QuestionFormToggle} from "./QuestionFormToggle";

import styles from './../Sidepanel.module.scss';

export interface Props {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	maxAnswerOptionCount: number;
	answerOptionCount: number;
	onToggleChanged: (checked:boolean)=>void;
	onMaxAnswerOptionCountChange: (maxAnswerOptionCount:number)=>void;
}

export const LimitAnswerOptionsToggle = (props: Props) => {
	return (
		<>
			<QuestionFormToggle
				checkedLabelText="Antworten begrenzen auf"
				uncheckedLabelText="Antworten nicht begrenzen"
				checked={props.checked}
				disabled={props.disabled}
				visible={props.visible}
				onChanged={props.onToggleChanged}
				onRenderSuffix={props.checked && (()=>
					<div className={styles.multiVotesInputContainer}>
						<div className={'ms-TextField-fieldGroup'}>
							<input
								type='number'
								min='1'
								max={props.maxAnswerOptionCount}
								value={props.answerOptionCount}
								onChange={(evt) => props.onMaxAnswerOptionCountChange(parseInt(evt.currentTarget.value))}
								disabled={props.disabled}
								className={styles.numField}
							/>
						</div>
						<span className={styles.label}>Stimmen</span>
					</div>
				)}
			/>
		</>
	);
};