import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const PowerPointIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 28 28" className={classes.svg}>
      <path d="M14.9996 3C8.94963 3 3.99963 7.95 3.99963 14L18.8366 17.837L14.9996 3Z" fill="#ED6C47" />
      <path d="M14.9996 3C21.0496 3 25.9996 7.95 25.9996 14L20.4996 16.95L14.9996 14V3Z" fill="#FF8F6B" />
      <path
        d="M3.99963 14C3.99963 20.05 8.94963 25 14.9996 25C21.0496 25 25.9996 20.05 25.9996 14H3.99963Z"
        fill="#D35230"
      />
      <path
        opacity="0.5"
        d="M16.9999 20.6458V9.1668C16.9999 8.5248 16.4749 7.9998 15.8329 7.9998H5.79988C4.66588 9.7288 3.99988 11.7898 3.99988 13.9998C3.99988 17.1478 5.34688 19.9898 7.48488 21.9998H15.6039C16.3129 21.9998 16.9999 21.2878 16.9999 20.6458Z"
        fill="black"
      />
      <path
        d="M14.8334 21H3.16637C2.52537 21 2.00037 20.475 2.00037 19.833V8.167C2.00037 7.525 2.52537 7 3.16637 7H14.8334C15.4754 7 16.0004 7.525 16.0004 8.167V19.833C16.0004 20.475 15.4754 21 14.8334 21Z"
        fill="#C43E1C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5962 10.6415C11.0882 10.2135 10.3562 9.9995 9.40021 9.9995H6.21521V17.9995H7.77721V15.3955H9.30021C9.91021 15.3955 10.4462 15.2835 10.9102 15.0585C11.3732 14.8335 11.7302 14.5185 11.9812 14.1135C12.2322 13.7075 12.3572 13.0195 12.3572 12.4995C12.3572 11.6885 12.1032 11.0695 11.5962 10.6415ZM9.12121 14.0095H7.77721V11.2385H9.17221C10.1982 11.2385 10.7112 11.6795 10.7112 12.5605C10.7112 13.0145 10.5782 13.4185 10.3102 13.6555C10.0422 13.8915 9.64621 14.0095 9.12121 14.0095Z"
        fill="white"
      />
    </svg>
  ),
  displayName: 'PowerPointIcon',
});
