import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const VideomailIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M4.5 4C3.11929 4 2 5.11929 2 6.5V9.33681C2.31085 9.18915 2.64693 9.08595 3 9.03544V6.5C3 5.67157 3.67157 5 4.5 5H11.5C12.3284 5 13 5.67157 13 6.5V13.5C13 14.3284 12.3284 15 11.5 15H11V16H11.5C12.8807 16 14 14.8807 14 13.5V12.5L16.4 14.3C17.0592 14.7944 18 14.324 18 13.5V6.49998C18 5.67594 17.0592 5.20556 16.4 5.69998L14 7.49998V6.5C14 5.11929 12.8807 4 11.5 4H4.5ZM14 8.74998L17 6.49998V13.5L14 11.25V8.74998Z" />
        <path d="M1 12.5C1 11.1193 2.11929 10 3.5 10H7.5C8.88071 10 10 11.1193 10 12.5V16.5C10 17.8807 8.88071 19 7.5 19H3.5C2.11929 19 1 17.8807 1 16.5V12.5ZM5.02008 12.534C4.94209 12.4811 4.85564 12.4546 4.7607 12.4546C4.69458 12.4546 4.63186 12.4687 4.57252 12.4969C4.51319 12.5234 4.46148 12.5596 4.41741 12.6054C4.37333 12.6513 4.33773 12.7051 4.31061 12.7668C4.28518 12.8286 4.27246 12.8938 4.27246 12.9626V16.0374C4.27246 16.1045 4.28518 16.1689 4.31061 16.2306C4.33773 16.2924 4.37333 16.347 4.41741 16.3947C4.46318 16.4405 4.51573 16.4776 4.57507 16.5058C4.6344 16.5323 4.69628 16.5455 4.7607 16.5455C4.80986 16.5455 4.85564 16.5384 4.89802 16.5243C4.94209 16.5102 4.98532 16.489 5.02771 16.4608L7.32395 14.8943C7.39346 14.8485 7.44771 14.7867 7.4867 14.7091C7.52569 14.6315 7.54519 14.5503 7.54519 14.4656C7.54519 14.3774 7.52485 14.2963 7.48416 14.2222C7.44347 14.1463 7.38753 14.0855 7.31633 14.0396L5.02008 12.534Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M10.5 4C11.8807 4 13 5.11929 13 6.5V13.5C13 14.7095 12.1411 15.7184 11 15.95V12.5C11 10.567 9.433 9 7.5 9H3.5C2.9632 9 2.45463 9.12085 2 9.33681V6.5C2 5.11929 3.11929 4 4.5 4H10.5Z" />
        <path d="M14 12.0815V7.93082L16.7692 5.61756C17.2575 5.20962 18 5.55685 18 6.19315V13.8606C18 14.4999 17.2512 14.8461 16.7642 14.4319L14 12.0815Z" />
        <path d="M1 12.5C1 11.1193 2.11929 10 3.5 10H7.5C8.88071 10 10 11.1193 10 12.5V16.5C10 17.8807 8.88071 19 7.5 19H3.5C2.11929 19 1 17.8807 1 16.5V12.5ZM5.02008 12.534C4.94209 12.4811 4.85564 12.4546 4.7607 12.4546C4.69458 12.4546 4.63186 12.4687 4.57252 12.4969C4.51319 12.5234 4.46148 12.5596 4.41741 12.6054C4.37333 12.6513 4.33773 12.7051 4.31061 12.7668C4.28518 12.8286 4.27246 12.8938 4.27246 12.9626V16.0374C4.27246 16.1045 4.28518 16.1689 4.31061 16.2306C4.33773 16.2924 4.37333 16.347 4.41741 16.3947C4.46318 16.4405 4.51573 16.4776 4.57507 16.5058C4.6344 16.5323 4.69628 16.5455 4.7607 16.5455C4.80986 16.5455 4.85564 16.5384 4.89802 16.5243C4.94209 16.5102 4.98532 16.489 5.02771 16.4608L7.32395 14.8943C7.39346 14.8485 7.44771 14.7867 7.4867 14.7091C7.52569 14.6315 7.54519 14.5503 7.54519 14.4656C7.54519 14.3774 7.52485 14.2963 7.48416 14.2222C7.44347 14.1463 7.38753 14.0855 7.31633 14.0396L5.02008 12.534Z" />
      </g>
    </svg>
  ),
  displayName: 'VideomailIcon',
});
