import React from 'react';
import { QuestionForm } from '../molecules/QuestionForm';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { QuestionService } from '../../services/QuestionService';

export interface Props {
	questionId: string;
	onClose: (wasCanceled: boolean) => void;
	onNotFound?: () => void;
}

export const QuestionEditor = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const questions = useSelector((state: AppState) => state.business.questions);

	const currentQuestion = questions.find((q) => q.id === props.questionId);

	if (!currentQuestion || !survey) {
		props.onNotFound?.();
		return <></>;
	}

	return (
		<QuestionForm
			disabled={currentQuestion.status !== 'unsaved' && currentQuestion.status !== 'new'}
			question={currentQuestion}
			onCancel={() => {
				props.onClose(true);
			}}
			onSave={(question) => {
				QuestionService.upsertQuestion(survey, question).then(() => {
					props.onClose(false);
				});
			}}
		/>
	);
};
