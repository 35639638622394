import styles from './Sidepanel.module.scss'; // TODO: use own module.scss
import memberStyles from './MembersForm.module.scss';

import React, { useState, useEffect, useCallback } from 'react';

import { EntityList } from '../molecules/EntityList';
import { IMember, IMembers, ISurvey } from '../../store/business/interfaces';
import { CommandButton, TextField, Toggle, Label, IContextualMenuItem, ContextualMenuItemType, Icon, PrimaryButton, IconButton } from 'office-ui-fabric-react';

import { createGuid, formatDateTime } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState, store } from '../../store';
import { IconWithCallout } from '../atoms/IconWithCallout';
import { NumberTextField } from '../atoms/NumberTextField';
import { MemberService } from '../../services/MemberService';
import { OnlineStatus } from '../atoms/OnlineStatus';
import round from 'lodash/round';
import { validate } from 'email-validator';
import { upsertMembers } from '../../store/business/actions';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';

export interface Props {
	members: IMembers;
	survey: ISurvey;
	onUpsertMembers: (members: IMember[]) => void;
	onDeleteMember: (member: IMember) => void;
	onDeleteAllMembers: () => void;
	onExportMembers: () => void;
	onExportMembersProtocol: () => void;
	onCancel: () => void;
	onUpsertSurvey: (survey: ISurvey) => void;
	readOnly?: boolean;
	onMembersImport: () => void;
}

export const MembersForm = (props: Props) => {
	const questions = useSelector((state: AppState) => state.business.questions);
	const [members, setMembers] = useState(props.members?.entities);
	const [lastChanged, setLastChanged] = useState(props.members?.lastChanged);
	const [showPins, setShowPins] = useState(false);
	const [searchProperty, setSearchProperty] = useState("");
	const [selectedMemberId, setSelectedMemberId] = useState(null);
	const [voteWeightSum, setVoteWeightSum] = useState(0);
	const [searchInputFocus, setSearchInputFocus] = useState(false);
	const [numberOfMembersShown, setNumberOfMembersShown] = useState(40);

	const [hideDeleteMembersDialog, setHideDeleteMembersDialog] = useState(true);
	const [hideUpdateVoteWeightDialog, setHideUpdateVoteWeightDialog] = useState(true);

	const percentualVoteWeightPrecision = 5;
	let saveTimer = null;

	const recalculateVoteWeightSum = (membersWithVoteWeight:IMember[]) => {
		const newVoteWeightSum = round(membersWithVoteWeight?.map((m) => isNaN(m.voteWeight)?0:m.voteWeight).reduce((sum, current) => sum + current, 0), percentualVoteWeightPrecision);
		setVoteWeightSum(newVoteWeightSum);
	};

	useEffect(() => {
		setMembers(props.members?.entities);
		setLastChanged(props.members?.lastChanged);

		recalculateVoteWeightSum(props.members?.entities);
	}, [props.members.lastChanged, props.members.entities]);

	useEffect(() => {
		setMembers((newMembers) => {
			if (!props.survey.useVoteWeight) {
				newMembers = newMembers.map((member) => ({ ...member, voteWeight: 1 }));
			}
			else if (!props.survey.useVoteWeightInPercent) {
				newMembers = newMembers.map((member)=> ({ ...member, voteWeight: Math.round(isNaN(member.voteWeight)?0:member.voteWeight)}));
			}
			recalculateVoteWeightSum(newMembers);
			store.dispatch(upsertMembers(props.survey.id, newMembers, new Date(Date.now())));
			return newMembers;
		});
	}, [props.survey.useVoteWeight, props.survey.useVoteWeightInPercent]);

	const onChangeMember = useCallback(
		(data: { id: string; title?: string; eMail?: string; voteWeight?: number }) => {
			onChangeMembers([data]);
		}, [members, lastChanged]
	);

	const onChangeMembers = useCallback(
		(list: { id: string; title?: string; eMail?: string; voteWeight?: number }[]) => {
			clearTimeout(saveTimer);

			let changedMembers = [];

			list.forEach(data => {
				const changedMember = { ...members.find((m) => m.id === data.id), ...data };
				const changedMemberIndex = members.findIndex((m) => m.id === changedMember.id);

				let needsUpdate = false;

				if (changedMemberIndex === -1) {
					needsUpdate = true;
				} else {
					const oldMember = members[changedMemberIndex];
					if(
						oldMember.eMail !== changedMember.eMail ||
						oldMember.title !== changedMember.title ||
						oldMember.voteWeight !== changedMember.voteWeight ||
						oldMember.pin !== changedMember.pin
					) {
						needsUpdate = true;
					}
				}

				if(needsUpdate) {
					changedMember.isSaving = true;
					changedMembers.push(changedMember);
				}
			});

			if(changedMembers.length > 0) {
				props.onUpsertMembers(changedMembers);
			}
		}, [members, lastChanged]
	);

	const changeMemberTimeout = (data: { id: string; title?: string; eMail?: string; voteWeight?: number }) => {
		clearTimeout(saveTimer);
		saveTimer = setTimeout(() => onChangeMember(data), 3000);
	};

	const moreOptions: IContextualMenuItem[] = [
		{
			key: 'togglePins',
			text: showPins?'PINs ausblenden':'PINs einblenden',
			iconProps: { iconName: 'PasswordField' },
			onClick: () => {
				setShowPins(!showPins);
			},
			disabled: !members || members.length === 0 || props.survey.useQuickVotes,
		},
		{
			key: 'sendPins',
			onRenderContent: () => {
				return (
					<>
						<Icon iconName="Send" className={["ms-ContextualMenu-icon", styles.contextualMenuIcon].join(' ')} />
						<span className={["ms-ContextualMenu-itemText", styles.contextualMenuItemText].join(' ')}>PINs versenden</span>
						<Icon className={styles.inlineIcon} style={{marginBottom: '3px'}} iconName="NavigateExternalInline" /></>
				);
			},
			iconProps: { iconName: 'Send' },
			onClick: () => {
				window.open('https://rebrand.ly/5zv2foy');
			},
			disabled: !members || members.length === 0 || props.survey.useQuickVotes,
		},
		{
			key: 'divider1',
			itemType: ContextualMenuItemType.Divider
		},
		{
			key: 'downloadProtocol',
			text: 'Teilnehmerprotokoll herunterladen',
			iconProps: { iconName: 'TimeEntry' },
			onClick: () => {
				props.onExportMembersProtocol();
			},
			disabled: false,
		},
		{
			key: 'deleteAllMembers',
			text: 'Alle Teilnehmer löschen',
			iconProps: { iconName: 'Delete' },
			onClick: () => { setHideDeleteMembersDialog(false);},
			disabled: !members || members.length === 0 || props.readOnly || props.survey.useQuickVotes,
		},
	];

	const onGetMailErrorMessage = (value: string): string => {
		if(!value) return '';

		const isValidEMail = validate(value);
		if(!isValidEMail) return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
	};

	const onGetNameErrorMessage = (value: string): string => {
		if(!value) return 'Bitte geben Sie einen Namen ein.';

		if(members.filter(m=>m.title === value).length > 1){
			return 'Bitte geben Sie einen noch nicht verwendeten Namen ein.';
		}
	};

	console.log("rendering", members);
	
	return (
		<div className={[styles.formContainer, memberStyles.memberform].join(' ')}>
			<ConfirmationDialog
				hidden={hideDeleteMembersDialog}
				title={'Alle Teilnehmer löschen'}
				message={'Sind Sie sich sicher, dass Sie alle Teilnehmer löschen wollen?'}
				onConfirm={()=>{
					props.onDeleteAllMembers();
					setHideDeleteMembersDialog(true);
				}}
				onDismiss={()=>{
					setHideDeleteMembersDialog(true);
				}}
			/>
			<ConfirmationDialog
				hidden={hideUpdateVoteWeightDialog}
				title={'Stimmgewichtung deaktivieren'}
				message={'Es gibt Mitglieder mit einer gesetzten Stimmengewichtung. Wenn Sie die Stimmengewichtung deaktivieren, wird die Stimmengewichtung aller Mitglieder auf 1 gesetzt. Wollen Sie das tun?'}
				onConfirm={()=>{
					props.onUpsertSurvey({...props.survey, useVoteWeight: false, useVoteWeightInPercent: false});
					setHideUpdateVoteWeightDialog(true);
				}}
				onDismiss={()=>{
					setHideUpdateVoteWeightDialog(true);
				}}
			/>
			<div className={styles.fixedHeader}>
				<header>
					<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
						<div className={styles.panelHeader}>
							<h1>Teilnehmer</h1>
							{!props.members?.lastChanged ? (
								<></>
							) : (
								<Label className={styles.lastChanged}>({members.some(m => m.isSaving)?'Wird gespeichert...':`Gespeichert - ${formatDateTime(props.members?.lastChanged)} Uhr`})</Label>
							)}
						</div>
						<div className={[styles.flexContainer, styles.panelButtons].join(' ')}>
							<CommandButton
								text='Teilnehmer importieren'
								iconProps={{ iconName: 'AddFriend' }}
								onClick={() => {
									props.onMembersImport();
								}}
								disabled={props.survey.useQuickVotes || props.readOnly}
								className={styles.memberFormButton}
							/>
							<CommandButton
								text='Teilnehmer exportieren'
								iconProps={{ iconName: 'FollowUser' }}
								onClick={() => {
									props.onExportMembers();								
								}}
								disabled={!members || members.length === 0}
								className={styles.memberFormButton}
							/>
							{moreOptions.length > 0 ? (
								<div className={styles.moreImageButton}>
									<CommandButton
										iconProps={{ iconName: 'More' }}
										menuIconProps={{ iconName: '' }}
										menuProps={{
											items: moreOptions,
										}}
									/>
								</div>
							) : (
								<div className={styles.emptyMoreOptionsContainer}></div>
							)}
							<CommandButton
								onClick={() => {
									props.onCancel();
								}}
								iconProps={{ iconName: 'ChromeClose' }}
								className={styles.closeIcon}
							/>
						</div>
					</div>
				</header>
				<div className={[styles.flexContainer, memberStyles.switchMarginBottom].join(' ')}>
					<Toggle
						inlineLabel
						label='Schnelle Abstimmungen'
						checked={props.survey.useQuickVotes}
						disabled={props.readOnly || questions?.length > 0 || props.survey.useVoteWeight || members?.length > 0}
						onChange={(evt, newUseQuickVotes) => {
							props.onUpsertSurvey({...props.survey, useQuickVotes: newUseQuickVotes, useVoteWeight: false, useVoteWeightInPercent: false});
						}}
					></Toggle>
					{(!props.readOnly && (
						<IconWithCallout
							iconName='Info'
							renderCalloutContent={()=>{ return (
								<span>Teilnehmer können sich ohne PIN anmelden. Die Teilnehmerliste wird nach der Anmeldung automatisch befüllt. Bei einer aktivierten Stimmengewichtung sind keine schnellen Abstimmungen möglich.</span>
							);}}
							iconContainerClassName="votrInfoIcon"
						/>
					))}
				</div>
				<div className={[styles.flexContainer].join(' ')}>
					<Toggle
						inlineLabel
						label='Stimmengewichtung'
						checked={props.survey.useVoteWeight}
						disabled={props.readOnly || (props.survey.useVoteWeightInPercent && questions?.length > 0) || props.survey.useQuickVotes}
						onChange={(evt, newUseVoteWeight) => {
							if(newUseVoteWeight === false && members.some(m=>m.voteWeight!==1)) {
								setHideUpdateVoteWeightDialog(false);
							}
							else {
								props.onUpsertSurvey({...props.survey, useVoteWeight: newUseVoteWeight, useVoteWeightInPercent: false});
							}
						}}
					></Toggle>
					{props.survey.useVoteWeight && (
						<Toggle
							inlineLabel
							label='in %'
							checked={props.survey.useVoteWeightInPercent}
							disabled={props.readOnly || (questions?.length > 0)}
							onChange={(evt, newUseVoteWeightInPercent) => {
								props.onUpsertSurvey({...props.survey, useVoteWeightInPercent: newUseVoteWeightInPercent});
							}}
						></Toggle>
					)}
					{(!props.readOnly && props.survey.useVoteWeight && questions?.length > 0 && (
						<IconWithCallout
							iconName='Info'
							renderCalloutContent={()=>{ return (
								<span>Die Veränderung der Stimmgewichtung mit oder ohne % ist nur möglich, wenn noch keine Abstimmungen angelegt wurden.</span>
							);}}
							iconContainerClassName="votrInfoIcon"
						/>
					))}
				</div>
				<div className={[styles.flexContainer, styles.headerRow, styles.spaceBetween, memberStyles.searchHeader].join(' ')}>
					<div className={[memberStyles.searchBox, searchInputFocus ? memberStyles.searchBoxFocus : ''].join(' ')}> 
						<TextField
							styles={{root:{width:300}}}
							placeholder="Nach Teilnehmern suchen"
							onChange={(_, newValue) => {
								setSearchProperty(newValue);
							}}
							disabled={members.length === 0}
							onFocus={() => {
								setSearchInputFocus(true);
							}}
							onBlur={() => {
								setSearchInputFocus(false);
							}}
							value={searchProperty}
						/>
						{searchProperty.length === 0 ? 
							<IconButton
								iconProps={{
									iconName: 'Zoom',
								}}
								onClick={() => {
									setSearchProperty(searchProperty);
								}}
								className={[memberStyles.searchZoomIconButton, memberStyles.searchIconButton].join(' ')}
							/>
						:
							<IconButton
								iconProps={{
									iconName: 'Cancel',
								}}
								onClick={() => {
									setSearchProperty('');
								}}
								className={memberStyles.searchIconButton}
							/>
						}
					</div>
					<div className={[styles.flexContainer, styles.buttonRow, memberStyles.addMemberButton].join(' ')}>
						<PrimaryButton
							text="Teilnehmer hinzufügen"
							iconProps={{ iconName: 'AddFriend' }}
							onClick={(ev) => {
								let newMember = {...MemberService.emptyMember(), id: createGuid()};

								setSelectedMemberId(newMember.id);

								let newMembers = members;
								newMembers.splice(0,0,newMember);
								setMembers(newMembers);


								props.onUpsertMembers([newMember]);
							}}
							disabled={props.readOnly || props.survey.useQuickVotes || (members?.length > 0 && !members[0]?.title)}
						/>
					</div>
				</div>
			</div>
			<div
				className={styles.scrollContent} 
				onScroll={(evt)=>{
					const scrolledDiv = evt.target as HTMLDivElement;
					if(scrolledDiv.scrollHeight > scrolledDiv.clientHeight && scrolledDiv.scrollHeight > 0 && scrolledDiv.scrollHeight - scrolledDiv.clientHeight*1.5 < scrolledDiv.scrollTop && members?.length > numberOfMembersShown){
						setNumberOfMembersShown(numberOfMembersShown+40);
					}
				}}
			>
				<EntityList
					entities={members.filter((member) => member.title.toLowerCase().includes(searchProperty.toLowerCase()) || member.eMail.toLowerCase().includes(searchProperty.toLowerCase())).slice(0,numberOfMembersShown)}
					eTag={lastChanged?.toString()}
					onChange={()=>{}}
					defaultEmptyEntity={MemberService.emptyMember()}
					onDelete={(m) => {
						props.onDeleteMember(m);
					}}
					deleteTitle={'Teilnehmer löschen'}
					deleteMessage={`Sind Sie sich sicher, dass Sie '{0}' löschen wollen?`}
					renderDeleteColumn={true}
					renderHeaderItem={() => {
						return (
							members.length !== 0 && 
							<>
							 	{props.survey.useQuickVotes ? 
									<th className={styles.inputHeader}>Name</th>
								:	
									<>
										<th className={styles.inputHeader}>Name</th>
										<th className={styles.inputHeader}>E-Mail-Adresse (optional)</th>
										{props.survey.useVoteWeight ? 
											<th className={[styles.voteWeightHeader, styles.inputHeader].join(' ')}>
												Stimmen{props.survey.useVoteWeightInPercent?' in %':''}
												{props.survey.useVoteWeightInPercent && 
													<div className={[memberStyles.voteWeightPercent, voteWeightSum===100 ? memberStyles.voteWeightPercentMatch : '' ].join(' ')}>
														<div>{voteWeightSum}</div><div>%</div>
													</div>
												}
											</th> 
										:
											<></>
										}
										<th className={styles.pinHeader}>PIN</th>
										<th className={styles.deleteHeader}>&nbsp;</th>
									</>
								}
							</>
						);					
					}}
					disabled={props.readOnly || props.survey.useQuickVotes}
					renderItem={(renderedMember, index) => {
						return (
							<>
								{props.survey.useQuickVotes && (
									<td className={[styles.flexContainer, memberStyles.nameContainer].join(' ')}>
										<OnlineStatus member={renderedMember} /><span className={memberStyles.name}>{renderedMember.title}</span>
									</td>
								)}
								{!props.survey.useQuickVotes && (
									<>
										<td>
											<div className={[styles.flexContainer, memberStyles.combinedTableField, onGetNameErrorMessage(renderedMember.title)?'error':''].join(' ')}>
												{selectedMemberId !== renderedMember.id && <div className={memberStyles.onlineStatus}><OnlineStatus member={renderedMember} /></div>}
												<TextField
													autoFocus={selectedMemberId === renderedMember.id}
													key={`${renderedMember.id}-title`}
													defaultValue={renderedMember.title}
													placeholder='Name'
													onChange={(e, newValue) => {
														if(!renderedMember.pin) {
															onChangeMember({ id: renderedMember.id, title: newValue});
														}
														else {
															changeMemberTimeout({ id: renderedMember.id, title: newValue});
														}
													}}
													onBlur={(evt) => {
														evt.currentTarget.setAttribute('aria-invalid',onGetNameErrorMessage(evt.currentTarget.value)?'true':'false');
														onChangeMember({ id: renderedMember.id, title: evt.currentTarget.value });
														setSelectedMemberId(null);
													}}
													onFocus={(evt) => {
														evt.currentTarget.select();
														evt.currentTarget.setAttribute('aria-invalid','false');
														setSelectedMemberId(renderedMember.id);
													}}
													disabled={props.readOnly || props.survey.useQuickVotes}
													onPaste={(evt) => {
														if (index === 0 && renderedMember.title === '') {
															const input = evt.clipboardData.getData('text');
															if (input) {
																const rows = input.split(/\r?\n/);
																if (rows.length > 1) {
																	evt.preventDefault();
																	const pastedMembers = rows.map((row) => {
																		const parts = row.split(/\t|;/);
																		const voteWeightString = parts[2]?.trim().replace(/,/,'.');
																		const voteWeight = round(parseFloat(voteWeightString), percentualVoteWeightPrecision);
																		const member: IMember = {
																			id: createGuid(),
																			title: parts[0]?.trim() || '',
																			eMail: parts[1]?.trim() || '',
																			pin: '',
																			voteWeight: (Number.isNaN(voteWeight) || voteWeight===0) ? 1 : voteWeight,
																		};
																		return member;
																	}).filter(m => !!m.title || !!m.eMail).reverse();

																	if(pastedMembers.length > 0) {
																		evt.currentTarget.blur();
																		props.onDeleteMember(renderedMember);
																		onChangeMembers(pastedMembers);
																	}
																}
															}
														}
													}}
													onGetErrorMessage={selectedMemberId !== renderedMember.id ? onGetNameErrorMessage : ()=>{return '';}}
													validateOnFocusOut={true}
												/>
											</div>
										</td>
										<td>
											<TextField
												key={`${renderedMember.id}-eMail`}
												defaultValue={renderedMember.eMail}
												placeholder='E-Mail-Adresse'
												onBlur={(evt) => {
													onChangeMember({ id: renderedMember.id, eMail: evt.currentTarget.value });
												}}
												onChange={(e, newValue) => {
													changeMemberTimeout({ id: renderedMember.id, eMail: newValue});
												}}
												onFocus={(evt) => {
													evt.currentTarget.select();
												}}
												onGetErrorMessage={onGetMailErrorMessage}
												validateOnFocusOut={true}
												disabled={props.readOnly || props.survey.useQuickVotes}
											/>
										</td>
										{props.survey.useVoteWeight && (
											<td>
												<NumberTextField
													key={`${renderedMember.id}-voteWeight`}
													defaultValue={renderedMember.voteWeight}
													placeholder='Stimmengewichtung'
													title='' //suppress validation tooltip
													className={[styles.voteWeightField,props.survey.useVoteWeightInPercent?styles.percent:''].join(' ')}
													disabled={props.readOnly || props.survey.useQuickVotes}
													suffix={props.survey.useVoteWeightInPercent ? '%' : ''}
													precision={props.survey.useVoteWeightInPercent ? percentualVoteWeightPrecision : 0}
													validateOnFocusOut={true}
													onBlur={(evt) => {
														onChangeMember({id: renderedMember.id, voteWeight: parseFloat(evt.currentTarget.value)});
													}}
													onChange={(e, value)=>{
														changeMemberTimeout({ id: renderedMember.id, voteWeight: value });
													}}
													onFocus={(evt) => {
														evt.currentTarget.select();
													}}
													styles={{suffix:{background:'transparent', color:'inherit', padding:'0 8px 0 0', display:props.survey.useVoteWeightInPercent?'flex':'none'}}}
												/>
											</td>
										)}
										<td style={{ width: '60px' }}>
											<span>{showPins?renderedMember.pin:'******'}</span>
										</td>
									</>
								)}
							</>
						);
					}}
					textNewEntity=''
					textNewEmptyEntity=''
					onRenderNewEntityContainer={()=>{return <></>;}}
					renderNewEntryBelow={false}
				/>
				{members.length === 0 && 
					<div>
						{props.survey.useQuickVotes?'Bisher haben sich keine Teilnehmer angemeldet':'Es wurden noch keine Teilnehmer erstellt'}
					</div>
				}
			</div>
		</div>
	);
};
