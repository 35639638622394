import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PresenterIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M11.9988 4C11.9988 5.10457 11.1033 6 9.99878 6C8.89421 6 7.99878 5.10457 7.99878 4C7.99878 2.89543 8.89421 2 9.99878 2C11.1033 2 11.9988 2.89543 11.9988 4Z" />
        <path d="M6.99871 14.7842V16.5C6.99871 17.3284 7.67028 18 8.49871 18H11.4987C12.3271 18 12.9987 17.3284 12.9987 16.5V14.7842C12.9987 14.6482 13.054 14.5182 13.152 14.4239L16.8548 10.8603C17.1791 10.5482 16.9582 10 16.5081 10H3.48929C3.0392 10 2.81827 10.5482 3.14257 10.8603L6.84542 14.4239C6.94336 14.5182 6.99871 14.6482 6.99871 14.7842Z" />
        <path d="M8.49878 7C7.67035 7 6.99878 7.67157 6.99878 8.5V9H12.9988V8.5C12.9988 7.67157 12.3272 7 11.4988 7H8.49878Z" />
      </g>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M9.99878 6C11.1033 6 11.9988 5.10457 11.9988 4C11.9988 2.89543 11.1033 2 9.99878 2C8.89421 2 7.99878 2.89543 7.99878 4C7.99878 5.10457 8.89421 6 9.99878 6ZM9.99878 5C9.4465 5 8.99878 4.55228 8.99878 4C8.99878 3.44772 9.4465 3 9.99878 3C10.5511 3 10.9988 3.44772 10.9988 4C10.9988 4.55228 10.5511 5 9.99878 5Z" />
        <path d="M8.49878 8C8.22264 8 7.99878 8.22386 7.99878 8.5V9H6.99878V8.5C6.99878 7.67157 7.67035 7 8.49878 7H11.4988C12.3272 7 12.9988 7.67157 12.9988 8.5V9H11.9988V8.5C11.9988 8.22386 11.7749 8 11.4988 8H8.49878Z" />
        <path d="M6.99871 14.7842V16.5C6.99871 17.3284 7.67028 18 8.49871 18H11.4987C12.3271 18 12.9987 17.3284 12.9987 16.5V14.7842C12.9987 14.6482 13.054 14.5182 13.152 14.4239L16.8548 10.8603C17.1791 10.5482 16.9582 10 16.5081 10H3.48929C3.0392 10 2.81827 10.5482 3.14257 10.8603L6.84542 14.4239C6.94336 14.5182 6.99871 14.6482 6.99871 14.7842ZM7.99871 16.5V14.7842C7.99871 14.3764 7.83268 13.9862 7.53886 13.7034L4.72987 11H15.2675L12.4586 13.7034C12.1647 13.9862 11.9987 14.3764 11.9987 14.7842V16.5C11.9987 16.7761 11.7748 17 11.4987 17H8.49871C8.22256 17 7.99871 16.7761 7.99871 16.5Z" />
      </g>
    </svg>
  ),
  displayName: 'PresenterIcon',
});
