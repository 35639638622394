import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const VideoCameraEmphasisIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g>
        <g className={cx(iconClassNames.outline, classes.outlinePart)}>
          <path d="M5 5a2 2 0 00-2 2v6a2 2 0 002 2h6a2 2 0 002-2v-1.029l2.841 1.847A.75.75 0 0017 13.19V6.811a.75.75 0 00-1.16-.629L13 8.032V7a2 2 0 00-2-2H5zm8 4.224l3-1.952v5.457l-3-1.95V9.224zM12 7v6a1 1 0 01-1 1H5a1 1 0 01-1-1V7a1 1 0 011-1h6a1 1 0 011 1zM6.892 2.03a7.067 7.067 0 00-.85.37 4.67 4.67 0 00-.292.166l-.018.012-.006.004-.002.001-.001.001L6 3l-.277-.416a.5.5 0 00.554.833l.007-.005.041-.025c.038-.023.099-.058.18-.1.162-.085.407-.2.728-.317A8.105 8.105 0 0110 2.5c1.183 0 2.125.236 2.767.47.32.117.566.232.728.317a3.69 3.69 0 01.22.125l.008.004.262-.393-.262.393a.5.5 0 10.554-.832L14 3l.277-.416-.001-.001-.002-.001-.006-.004-.019-.012a4.665 4.665 0 00-.292-.166 7.063 7.063 0 00-.849-.37A9.104 9.104 0 0010 1.5a9.104 9.104 0 00-3.108.53zm-.615 1.387c-.001 0 0 0 0 0zm7.446 0zM6.892 17.97c.733.267 1.79.53 3.108.53a9.104 9.104 0 003.108-.53c.367-.133.653-.268.85-.37a4.626 4.626 0 00.291-.166l.019-.012.006-.004.002-.001s.001-.001-.276-.417l.277.416a.5.5 0 00-.554-.832l.262.393-.262-.393-.008.005a3.67 3.67 0 01-.22.125c-.162.084-.407.2-.728.316A8.106 8.106 0 0110 17.5a8.106 8.106 0 01-2.767-.47 6.075 6.075 0 01-.728-.317 3.663 3.663 0 01-.22-.125l-.008-.005a.5.5 0 00-.554.833L6 17l-.277.416.001.001.002.001.006.004.018.012.063.038a7.063 7.063 0 001.078.497zm-.615-1.386c-.001 0 0 0 0 0zm7.446 0z" />
        </g>

        <g className={cx(iconClassNames.filled, classes.filledPart)}>
          <path d="M6.892 2.03a7.067 7.067 0 00-.85.37 4.67 4.67 0 00-.292.166l-.018.012-.006.004-.002.001-.001.001L6 3l-.277-.416a.5.5 0 00.554.833l.007-.005.041-.025c.038-.023.099-.058.18-.1.162-.085.407-.2.728-.317A8.105 8.105 0 0110 2.5c1.183 0 2.125.236 2.767.47.32.117.566.232.728.317a3.69 3.69 0 01.22.125l.008.004.262-.393-.262.393a.5.5 0 10.554-.832L14 3l.277-.416-.001-.001-.002-.001-.006-.004-.019-.012a4.665 4.665 0 00-.292-.166 7.063 7.063 0 00-.849-.37A9.104 9.104 0 0010 1.5a9.104 9.104 0 00-3.108.53zm-.615 1.387c-.001 0 0 0 0 0zm7.446 0zM6.892 17.97c.733.267 1.79.53 3.108.53a9.104 9.104 0 003.108-.53c.367-.133.653-.268.85-.37a4.626 4.626 0 00.291-.166l.019-.012.006-.004.002-.001s.001-.001-.276-.417l.277.416a.5.5 0 00-.554-.832l.262.393-.262-.393-.008.005a3.67 3.67 0 01-.22.125c-.162.084-.407.2-.728.316A8.106 8.106 0 0110 17.5a8.106 8.106 0 01-2.767-.47 6.075 6.075 0 01-.728-.317 3.663 3.663 0 01-.22-.125l-.008-.005a.5.5 0 00-.554.833L6 17l-.277.416.001.001.002.001.006.004.018.012.063.038a7.063 7.063 0 001.078.497zm-.615-1.386c-.001 0 0 0 0 0zm7.446 0zM2.5 7v6a2 2 0 002 2h6a2 2 0 002-2V7a2 2 0 00-2-2h-6a2 2 0 00-2 2zM13.5 11.55l2.777 2.257a.75.75 0 001.223-.582v-6.45a.75.75 0 00-1.223-.582L13.5 8.45v3.1z" />
        </g>
      </g>
    </svg>
  ),
  displayName: 'VideoCameraEmphasisIcon',
});
