import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/index';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IContext } from './store/system/interfaces';
import * as microsoftTeams from '@microsoft/teams-js';
import { TeamsSettings } from './components/molecules/TeamsSettings';
import { initializeIcons } from 'office-ui-fabric-react';
import { SystemService } from './services/SystemService';

microsoftTeams.initialize();
initializeIcons();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const url = window.location.href.split('?')[0];

if (urlParams.get('init')) {
	ReactDOM.render(
		<React.StrictMode>
			<TeamsSettings />
		</React.StrictMode>,
		document.getElementById('root')
	);

	microsoftTeams.settings.setValidityState(true);
	microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
		microsoftTeams.settings.setSettings({
			websiteUrl: url,
			contentUrl: url,
			entityId: 'itacsVotr',
			suggestedDisplayName: 'VOTR',
		});
		saveEvent.notifySuccess();
	});
} else {
	microsoftTeams.getContext((teamsCtx) => {
		const ctx: IContext = {
			title: teamsCtx.teamName,
			groupId: teamsCtx.groupId,
			userId: teamsCtx.userObjectId,
			url: teamsCtx.teamSiteUrl,
			channelId: teamsCtx.channelId,
		};

		SystemService.getConfig().then((config) => {
			ReactDOM.render(
				<React.StrictMode>
					<App apiUrl={`${config.apiUrl}/api/v1`} memberUrl={config.memberUrl} context={ctx} reversion={1} />
				</React.StrictMode>,
				document.getElementById('root')
			);
		});
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
