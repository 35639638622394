import * as React from 'react';
import cx from 'classnames';
import { iconClassNames } from '../utils/iconClassNames';
import { createSvgIcon } from '../utils/createSvgIcon';

export const NumberListIcon = createSvgIcon({
  svg: ({ classes, rtl }) => {
    return rtl ? (
      <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
        <g className={cx(iconClassNames.outline, classes.outlinePart)}>
          <path d="M17 1.5c0-.2-.2-.4-.4-.5-.2 0-.4.1-.5.3-.1 0-.1.1-.1.1-.1.1-.2.2-.3.4-.2.2-.5.5-.9.8-.3.1-.4.4-.2.6.1.3.4.4.6.2.3-.1.6-.3.8-.5v2.6c0 .3.2.5.5.5s.5-.2.5-.5v-4zM14.6 7.6c-.1.2-.1.6 0 .8.2.1.6.1.8 0v-.1s.1-.1.2-.1c.2-.1.4-.2.7-.2.2 0 .4.1.5.1.1.1.2.2.2.4s-.1.3-.2.4c-.2.2-.4.3-.6.4-.1 0-.1 0-.2.1-.3.1-.7.3-1 .7-.3.3-.5.8-.5 1.4 0 .3.2.5.5.5h2.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-1.9c0-.1.1-.2.2-.3.1-.1.4-.3.7-.4 0-.1.1-.1.1-.1.3-.1.6-.3.8-.5.4-.3.6-.7.6-1.2s-.2-.9-.6-1.2c-.3-.2-.7-.3-1.1-.3-.5 0-.9.2-1.2.3-.1.1-.2.2-.3.2l-.1.1h-.1zM15.8 15.5c0-.3.2-.5.5-.5s.5-.1.6-.2c.1-.1.1-.2.1-.3 0-.2-.2-.5-.7-.5-.5 0-.7.1-.8.2h-.1c-.1.3-.4.4-.6.2-.3-.1-.4-.4-.2-.6v-.1s0-.1.1-.1c0-.1.1-.2.3-.3.3-.2.7-.3 1.3-.3 1 0 1.7.7 1.7 1.5 0 .3-.1.7-.4 1 .3.3.4.7.4 1 0 .8-.7 1.5-1.7 1.5-.6 0-1-.1-1.3-.3-.2-.1-.3-.2-.3-.3-.1 0-.1-.1-.1-.1v-.1c-.2-.2-.1-.5.2-.6.2-.2.5-.1.6.2h.1c.1.1.3.2.8.2s.7-.3.7-.5c0-.1 0-.2-.1-.3-.1-.1-.3-.2-.6-.2s-.5-.2-.5-.5zM3.5 4c-.3 0-.5.2-.5.5s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-8zM3.5 9c-.3 0-.5.2-.5.5s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-8zM3.5 14c-.3 0-.5.2-.5.5s.2.5.5.5h8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-8z" />
          <path d="M5 1.5c0-.2-.2-.4-.4-.5-.2 0-.4.1-.5.3-.1 0-.1.1-.1.1-.1.1-.2.2-.3.4-.2.2-.5.5-.9.8-.3.1-.4.4-.2.6.1.3.4.4.6.2.3-.1.6-.3.8-.5v2.6c0 .3.2.5.5.5s.5-.2.5-.5v-4zM8.8 4c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H8.8zM8.8 9c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H8.8zM8 14.8c0-.5.3-.8.8-.8h7.5c.4 0 .7.3.7.8 0 .4-.3.7-.7.7H8.8c-.5 0-.8-.3-.8-.7zM2.6 7.6c-.1.2-.1.6 0 .8.2.1.6.1.8 0v-.1s.1-.1.2-.1c.2-.1.4-.2.7-.2.2 0 .4.1.5.1.1.1.2.2.2.4s-.1.3-.2.4c-.2.2-.4.3-.6.4-.1 0-.1 0-.2.1-.3.1-.7.3-1 .7-.3.3-.5.8-.5 1.4 0 .3.2.5.5.5h2.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H3.6c0-.1.1-.2.2-.3.1-.1.4-.3.7-.4 0-.1.1-.1.1-.1.3-.1.6-.3.8-.5.4-.3.6-.7.6-1.2s-.2-.9-.6-1.2c-.3-.2-.7-.3-1.1-.3-.5 0-.9.2-1.2.3-.1.1-.2.2-.3.2l-.1.1h-.1zm.1 0h-.1.1zM3.8 15.5c0-.3.2-.5.5-.5s.5-.1.6-.2c.1-.1.1-.2.1-.3 0-.2-.2-.5-.7-.5-.5 0-.7.1-.8.2h-.1c-.1.3-.4.4-.6.2-.3-.1-.4-.4-.2-.6v-.1s0-.1.1-.1c0-.1.1-.2.3-.3.3-.2.7-.3 1.3-.3 1 0 1.7.7 1.7 1.5 0 .3-.1.7-.4 1 .3.3.4.7.4 1 0 .8-.7 1.5-1.7 1.5-.6 0-1-.1-1.3-.3-.2-.1-.3-.2-.3-.3-.1 0-.1-.1-.1-.1v-.1c-.2-.2-.1-.5.2-.6.2-.2.5-.1.6.2h.1c.1.1.3.2.8.2s.7-.3.7-.5c0-.1 0-.2-.1-.3-.1-.1-.3-.2-.6-.2s-.5-.2-.5-.5zm-.4-1.3z" />
          <path d="M17 1.5c0-.2-.2-.4-.4-.5-.2 0-.4.1-.5.3-.1 0-.1.1-.1.1-.1.1-.2.2-.3.4-.2.2-.5.5-.9.8-.3.1-.4.4-.2.6.1.3.4.4.6.2.3-.1.6-.3.8-.5v2.6c0 .3.2.5.5.5s.5-.2.5-.5v-4zM14.6 7.6c-.1.2-.1.6 0 .8.2.1.6.1.8 0v-.1s.1-.1.2-.1c.2-.1.4-.2.7-.2.2 0 .4.1.5.1.1.1.2.2.2.4s-.1.3-.2.4c-.2.2-.4.3-.6.4-.1 0-.1 0-.2.1-.3.1-.7.3-1 .7-.3.3-.5.8-.5 1.4 0 .3.2.5.5.5h2.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-1.9c0-.1.1-.2.2-.3.1-.1.4-.3.7-.4 0-.1.1-.1.1-.1.3-.1.6-.3.8-.5.4-.3.6-.7.6-1.2s-.2-.9-.6-1.2c-.3-.2-.7-.3-1.1-.3-.5 0-.9.2-1.2.3-.1.1-.2.2-.3.2l-.1.1h-.1zM15.8 15.5c0-.3.2-.5.5-.5s.5-.1.6-.2c.1-.1.1-.2.1-.3 0-.2-.2-.5-.7-.5-.5 0-.7.1-.8.2h-.1c-.1.3-.4.4-.6.2-.3-.1-.4-.4-.2-.6v-.1s0-.1.1-.1c0-.1.1-.2.3-.3.3-.2.7-.3 1.3-.3 1 0 1.7.7 1.7 1.5 0 .3-.1.7-.4 1 .3.3.4.7.4 1 0 .8-.7 1.5-1.7 1.5-.6 0-1-.1-1.3-.3-.2-.1-.3-.2-.3-.3-.1 0-.1-.1-.1-.1v-.1c-.2-.2-.1-.5.2-.6.2-.2.5-.1.6.2h.1c.1.1.3.2.8.2s.7-.3.7-.5c0-.1 0-.2-.1-.3-.1-.1-.3-.2-.6-.2s-.5-.2-.5-.5zM3.8 4c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H3.8zM3.8 9c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H3.8zM3.8 14c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H3.8z" />
          <path d="M5 1.5c0-.2-.2-.4-.4-.5-.2 0-.4.1-.5.3-.1 0-.1.1-.1.1-.1.1-.2.2-.3.4-.2.2-.5.5-.9.8-.3.1-.4.4-.2.6.1.3.4.4.6.2.3-.1.6-.3.8-.5v2.6c0 .3.2.5.5.5s.5-.2.5-.5v-4zM8.8 4c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H8.8zM8.8 9c-.5 0-.8.3-.8.8 0 .4.3.7.8.7h7.5c.4 0 .7-.3.7-.7 0-.5-.3-.8-.7-.8H8.8zM8 14.8c0-.5.3-.8.8-.8h7.5c.4 0 .7.3.7.8 0 .4-.3.7-.7.7H8.8c-.5 0-.8-.3-.8-.7zM2.6 7.6c-.1.2-.1.6 0 .8.2.1.6.1.8 0v-.1s.1-.1.2-.1c.2-.1.4-.2.7-.2.2 0 .4.1.5.1.1.1.2.2.2.4s-.1.3-.2.4c-.2.2-.4.3-.6.4-.1 0-.1 0-.2.1-.3.1-.7.3-1 .7-.3.3-.5.8-.5 1.4 0 .3.2.5.5.5h2.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H3.6c0-.1.1-.2.2-.3.1-.1.4-.3.7-.4 0-.1.1-.1.1-.1.3-.1.6-.3.8-.5.4-.3.6-.7.6-1.2s-.2-.9-.6-1.2c-.3-.2-.7-.3-1.1-.3-.5 0-.9.2-1.2.3-.1.1-.2.2-.3.2l-.1.1h-.1zm.1 0h-.1.1zM3.8 15.5c0-.3.2-.5.5-.5s.5-.1.6-.2c.1-.1.1-.2.1-.3 0-.2-.2-.5-.7-.5-.5 0-.7.1-.8.2h-.1c-.1.3-.4.4-.6.2-.3-.1-.4-.4-.2-.6v-.1s0-.1.1-.1c0-.1.1-.2.3-.3.3-.2.7-.3 1.3-.3 1 0 1.7.7 1.7 1.5 0 .3-.1.7-.4 1 .3.3.4.7.4 1 0 .8-.7 1.5-1.7 1.5-.6 0-1-.1-1.3-.3-.2-.1-.3-.2-.3-.3-.1 0-.1-.1-.1-.1v-.1c-.2-.2-.1-.5.2-.6.2-.2.5-.1.6.2h.1c.1.1.3.2.8.2s.7-.3.7-.5c0-.1 0-.2-.1-.3-.1-.1-.3-.2-.6-.2s-.5-.2-.5-.5zm-.4-1.3z" />
          <path d="M3.5 9C3.2 9 3 9.2 3 9.5 3 9.8 3.2 10 3.5 10H11.5C11.8 10 12 9.8 12 9.5 12 9.2 11.8 9 11.5 9H3.5Z" />
          <path d="M3.5 14C3.2 14 3 14.2 3 14.5 3 14.8 3.2 15 3.5 15H11.5C11.8 15 12 14.8 12 14.5 12 14.2 11.8 14 11.5 14H3.5Z" />
        </g>
        <g className={cx(iconClassNames.filled, classes.filledPart)}>
          <path d="M17 1.5C17 1.3 16.8 1.1 16.6 1 16.4 1 16.2 1.1 16.1 1.3 16 1.3 16 1.4 16 1.4 15.9 1.5 15.8 1.6 15.7 1.8 15.5 2 15.2 2.3 14.8 2.6 14.5 2.7 14.4 3 14.6 3.2 14.7 3.5 15 3.6 15.2 3.4 15.5 3.3 15.8 3.1 16 2.9V5.5C16 5.8 16.2 6 16.5 6 16.8 6 17 5.8 17 5.5V1.5Z" />
          <path d="M14.6 7.6C14.5 7.8 14.5 8.2 14.6 8.4 14.8 8.5 15.2 8.5 15.4 8.4L15.4 8.4C15.4 8.3 15.4 8.3 15.4 8.3 15.4 8.3 15.5 8.2 15.6 8.2 15.8 8.1 16 8 16.3 8 16.5 8 16.7 8.1 16.8 8.1 16.9 8.2 17 8.3 17 8.5 17 8.7 16.9 8.8 16.8 8.9 16.6 9.1 16.4 9.2 16.2 9.3 16.1 9.3 16.1 9.3 16 9.4 15.7 9.5 15.3 9.7 15 10.1 14.7 10.4 14.5 10.9 14.5 11.5 14.5 11.8 14.7 12 15 12H17.5C17.8 12 18 11.8 18 11.5 18 11.2 17.8 11 17.5 11H15.6C15.6 10.9 15.7 10.8 15.8 10.7 15.9 10.6 16.2 10.4 16.5 10.3 16.5 10.2 16.6 10.2 16.6 10.2 16.9 10.1 17.2 9.9 17.4 9.7 17.8 9.4 18 9 18 8.5 18 8 17.8 7.6 17.4 7.3 17.1 7.1 16.7 7 16.3 7L16.3 7C15.8 7 15.4 7.2 15.1 7.3 15 7.4 14.9 7.5 14.8 7.5 14.7 7.6 14.7 7.6 14.7 7.6 14.7 7.6 14.7 7.6 14.7 7.6L14.7 7.6 14.6 7.6 14.6 7.6 14.6 7.6Z" />
          <path d="M15.8 15.5C15.8 15.2 16 15 16.3 15 16.6 15 16.8 14.9 16.9 14.8 17 14.7 17 14.6 17 14.5 17 14.3 16.8 14 16.3 14 15.8 14 15.6 14.1 15.5 14.2 15.5 14.2 15.4 14.2 15.4 14.2L15.4 14.2C15.4 14.2 15.4 14.2 15.4 14.2L15.4 14.2 15.4 14.2 15.4 14.2 15.4 14.2C15.4 14.2 15.4 14.2 15.4 14.2L15.4 14.2 15.4 14.2C15.3 14.5 15 14.6 14.8 14.4 14.5 14.3 14.4 14 14.6 13.8L14.6 13.8 14.6 13.8 14.6 13.8 14.6 13.8 14.6 13.7C14.6 13.7 14.6 13.7 14.6 13.7 14.6 13.7 14.6 13.6 14.7 13.6 14.7 13.5 14.8 13.4 15 13.3 15.3 13.1 15.7 13 16.3 13 17.3 13 18 13.7 18 14.5 18 14.8 17.9 15.2 17.6 15.5 17.9 15.8 18 16.2 18 16.5 18 17.3 17.3 18 16.3 18 15.7 18 15.3 17.9 15 17.7 14.8 17.6 14.7 17.5 14.7 17.4 14.6 17.4 14.6 17.3 14.6 17.3 14.6 17.3 14.6 17.3 14.6 17.3L14.6 17.2 14.6 17.2 14.6 17.2 14.6 17.2 14.6 17.2C14.4 17 14.5 16.7 14.8 16.6 15 16.4 15.3 16.5 15.4 16.8L15.4 16.8C15.4 16.8 15.5 16.8 15.5 16.8 15.6 16.9 15.8 17 16.3 17 16.8 17 17 16.7 17 16.5 17 16.4 17 16.3 16.9 16.2 16.8 16.1 16.6 16 16.3 16 16 16 15.8 15.8 15.8 15.5Z" />
          <path d="M3.8 4C3.3 4 3 4.3 3 4.8 3 5.2 3.3 5.5 3.8 5.5H11.3C11.7 5.5 12 5.2 12 4.8 12 4.3 11.7 4 11.3 4H3.8Z" />
          <path d="M3.8 9C3.3 9 3 9.3 3 9.8 3 10.2 3.3 10.5 3.8 10.5H11.3C11.7 10.5 12 10.2 12 9.8 12 9.3 11.7 9 11.3 9H3.8Z" />
          <path d="M3.8 14C3.3 14 3 14.3 3 14.8 3 15.2 3.3 15.5 3.8 15.5H11.3C11.7 15.5 12 15.2 12 14.8 12 14.3 11.7 14 11.3 14H3.8Z" />
        </g>
      </svg>
    ) : (
      <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
        <g className={cx(iconClassNames.filled, classes.filledPart)}>
          <path d="M5 1.5C5 1.3 4.8 1.1 4.6 1 4.4 1 4.2 1.1 4.1 1.3 4 1.3 4 1.4 4 1.4 3.9 1.5 3.8 1.6 3.7 1.8 3.5 2 3.2 2.3 2.8 2.6 2.5 2.7 2.4 3 2.6 3.2 2.7 3.5 3 3.6 3.2 3.4 3.5 3.3 3.8 3.1 4 2.9V5.5C4 5.8 4.2 6 4.5 6 4.8 6 5 5.8 5 5.5V1.5Z" />
          <path d="M8.8 4C8.3 4 8 4.3 8 4.8 8 5.2 8.3 5.5 8.8 5.5H16.3C16.7 5.5 17 5.2 17 4.8 17 4.3 16.7 4 16.3 4H8.8Z" />
          <path d="M8.8 9C8.3 9 8 9.3 8 9.8 8 10.2 8.3 10.5 8.8 10.5H16.3C16.7 10.5 17 10.2 17 9.8 17 9.3 16.7 9 16.3 9H8.8Z" />
          <path d="M8 14.8C8 14.3 8.3 14 8.8 14H16.3C16.7 14 17 14.3 17 14.8 17 15.2 16.7 15.5 16.3 15.5H8.8C8.3 15.5 8 15.2 8 14.8Z" />
          <path d="M2.6 7.6C2.5 7.8 2.5 8.2 2.6 8.4 2.8 8.5 3.2 8.5 3.4 8.4L3.4 8.4C3.4 8.3 3.4 8.3 3.4 8.3 3.4 8.3 3.5 8.2 3.6 8.2 3.8 8.1 4 8 4.3 8 4.5 8 4.7 8.1 4.8 8.1 4.9 8.2 5 8.3 5 8.5 5 8.7 4.9 8.8 4.8 8.9 4.6 9.1 4.4 9.2 4.2 9.3 4.1 9.3 4.1 9.3 4 9.4 3.7 9.5 3.3 9.7 3 10.1 2.7 10.4 2.5 10.9 2.5 11.5 2.5 11.8 2.7 12 3 12H5.5C5.8 12 6 11.8 6 11.5 6 11.2 5.8 11 5.5 11H3.6C3.6 10.9 3.7 10.8 3.8 10.7 3.9 10.6 4.2 10.4 4.5 10.3 4.5 10.2 4.6 10.2 4.6 10.2 4.9 10.1 5.2 9.9 5.4 9.7 5.8 9.4 6 9 6 8.5 6 8 5.8 7.6 5.4 7.3 5.1 7.1 4.7 7 4.3 7L4.3 7C3.8 7 3.4 7.2 3.1 7.3 3 7.4 2.9 7.5 2.8 7.5 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6L2.7 7.6 2.6 7.6 2.6 7.6 2.6 7.6ZM2.7 7.6L2.6 7.6C2.6 7.6 2.7 7.6 2.7 7.6Z" />
          <path d="M3.8 15.5C3.8 15.2 4 15 4.3 15 4.6 15 4.8 14.9 4.9 14.8 5 14.7 5 14.6 5 14.5 5 14.3 4.8 14 4.3 14 3.8 14 3.6 14.1 3.5 14.2 3.5 14.2 3.4 14.2 3.4 14.2L3.4 14.2C3.3 14.5 3 14.6 2.8 14.4 2.5 14.3 2.4 14 2.6 13.8L2.6 13.8 2.6 13.8 2.6 13.8 2.6 13.8 2.6 13.7C2.6 13.7 2.6 13.7 2.6 13.7 2.6 13.7 2.6 13.6 2.7 13.6 2.7 13.5 2.8 13.4 3 13.3 3.3 13.1 3.7 13 4.3 13 5.3 13 6 13.7 6 14.5 6 14.8 5.9 15.2 5.6 15.5 5.9 15.8 6 16.2 6 16.5 6 17.3 5.3 18 4.3 18 3.7 18 3.3 17.9 3 17.7 2.8 17.6 2.7 17.5 2.7 17.4 2.6 17.4 2.6 17.3 2.6 17.3 2.6 17.3 2.6 17.3 2.6 17.3L2.6 17.2 2.6 17.2 2.6 17.2 2.6 17.2 2.6 17.2C2.4 17 2.5 16.7 2.8 16.6 3 16.4 3.3 16.5 3.4 16.8L3.4 16.8C3.4 16.8 3.5 16.8 3.5 16.8 3.6 16.9 3.8 17 4.3 17 4.8 17 5 16.7 5 16.5 5 16.4 5 16.3 4.9 16.2 4.8 16.1 4.6 16 4.3 16 4 16 3.8 15.8 3.8 15.5ZM3.4 14.2C3.4 14.2 3.4 14.2 3.4 14.2L3.4 14.2 3.4 14.2 3.4 14.2 3.4 14.2C3.4 14.2 3.4 14.2 3.4 14.2L3.4 14.2 3.4 14.2Z" />
        </g>
        <g className={cx(iconClassNames.outline, classes.outlinePart)}>
          <path d="M5 1.5C5 1.3 4.8 1.1 4.6 1 4.4 1 4.2 1.1 4.1 1.3 4 1.3 4 1.4 4 1.4 3.9 1.5 3.8 1.6 3.7 1.8 3.5 2 3.2 2.3 2.8 2.6 2.5 2.7 2.4 3 2.6 3.2 2.7 3.5 3 3.6 3.2 3.4 3.5 3.3 3.8 3.1 4 2.9V5.5C4 5.8 4.2 6 4.5 6 4.8 6 5 5.8 5 5.5V1.5Z" />
          <path d="M8.8 4C8.3 4 8 4.3 8 4.8 8 5.2 8.3 5.5 8.8 5.5H16.3C16.7 5.5 17 5.2 17 4.8 17 4.3 16.7 4 16.3 4H8.8Z" />
          <path d="M8.8 9C8.3 9 8 9.3 8 9.8 8 10.2 8.3 10.5 8.8 10.5H16.3C16.7 10.5 17 10.2 17 9.8 17 9.3 16.7 9 16.3 9H8.8Z" />
          <path d="M8 14.8C8 14.3 8.3 14 8.8 14H16.3C16.7 14 17 14.3 17 14.8 17 15.2 16.7 15.5 16.3 15.5H8.8C8.3 15.5 8 15.2 8 14.8Z" />
          <path d="M2.6 7.6C2.5 7.8 2.5 8.2 2.6 8.4 2.8 8.5 3.2 8.5 3.4 8.4L3.4 8.4C3.4 8.3 3.4 8.3 3.4 8.3 3.4 8.3 3.5 8.2 3.6 8.2 3.8 8.1 4 8 4.3 8 4.5 8 4.7 8.1 4.8 8.1 4.9 8.2 5 8.3 5 8.5 5 8.7 4.9 8.8 4.8 8.9 4.6 9.1 4.4 9.2 4.2 9.3 4.1 9.3 4.1 9.3 4 9.4 3.7 9.5 3.3 9.7 3 10.1 2.7 10.4 2.5 10.9 2.5 11.5 2.5 11.8 2.7 12 3 12H5.5C5.8 12 6 11.8 6 11.5 6 11.2 5.8 11 5.5 11H3.6C3.6 10.9 3.7 10.8 3.8 10.7 3.9 10.6 4.2 10.4 4.5 10.3 4.5 10.2 4.6 10.2 4.6 10.2 4.9 10.1 5.2 9.9 5.4 9.7 5.8 9.4 6 9 6 8.5 6 8 5.8 7.6 5.4 7.3 5.1 7.1 4.7 7 4.3 7L4.3 7C3.8 7 3.4 7.2 3.1 7.3 3 7.4 2.9 7.5 2.8 7.5 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6 2.7 7.6L2.7 7.6 2.6 7.6 2.6 7.6 2.6 7.6ZM2.7 7.6L2.6 7.6C2.6 7.6 2.7 7.6 2.7 7.6Z" />
          <path d="M3.8 15.5C3.8 15.2 4 15 4.3 15 4.6 15 4.8 14.9 4.9 14.8 5 14.7 5 14.6 5 14.5 5 14.3 4.8 14 4.3 14 3.8 14 3.6 14.1 3.5 14.2 3.5 14.2 3.4 14.2 3.4 14.2L3.4 14.2C3.3 14.5 3 14.6 2.8 14.4 2.5 14.3 2.4 14 2.6 13.8L2.6 13.8 2.6 13.8 2.6 13.8 2.6 13.8 2.6 13.7C2.6 13.7 2.6 13.7 2.6 13.7 2.6 13.7 2.6 13.6 2.7 13.6 2.7 13.5 2.8 13.4 3 13.3 3.3 13.1 3.7 13 4.3 13 5.3 13 6 13.7 6 14.5 6 14.8 5.9 15.2 5.6 15.5 5.9 15.8 6 16.2 6 16.5 6 17.3 5.3 18 4.3 18 3.7 18 3.3 17.9 3 17.7 2.8 17.6 2.7 17.5 2.7 17.4 2.6 17.4 2.6 17.3 2.6 17.3 2.6 17.3 2.6 17.3 2.6 17.3L2.6 17.2 2.6 17.2 2.6 17.2 2.6 17.2 2.6 17.2C2.4 17 2.5 16.7 2.8 16.6 3 16.4 3.3 16.5 3.4 16.8L3.4 16.8C3.4 16.8 3.5 16.8 3.5 16.8 3.6 16.9 3.8 17 4.3 17 4.8 17 5 16.7 5 16.5 5 16.4 5 16.3 4.9 16.2 4.8 16.1 4.6 16 4.3 16 4 16 3.8 15.8 3.8 15.5ZM3.4 14.2C3.4 14.2 3.4 14.2 3.4 14.2L3.4 14.2 3.4 14.2 3.4 14.2 3.4 14.2C3.4 14.2 3.4 14.2 3.4 14.2L3.4 14.2 3.4 14.2Z" />
        </g>
      </svg>
    );
  },
  displayName: 'NumberListIcon',
});
