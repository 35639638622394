import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const AudioLoadingIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M9.20907 12.9928C9.14 12.9976 9.07028 13 9 13C7.34315 13 6 11.6568 6 9.99998V5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5V9.59971C11.6351 9.78627 11.2937 10.0124 10.9816 10.2724C10.9937 10.1833 11 10.0924 11 9.99998V5C11 3.89543 10.1046 3 9 3C7.89543 3 7 3.89543 7 5V9.99998C7 11.1046 7.89543 12 9 12C9.2305 12 9.45189 11.961 9.65793 11.8892C9.47039 12.2363 9.31908 12.6059 9.20907 12.9928Z" />
        <path d="M9.00209 14.653C9.02293 15.4158 9.19906 16.1398 9.5 16.7945V17.5C9.5 17.7761 9.27614 18 9 18C8.72386 18 8.5 17.7761 8.5 17.5V15.4776C5.69675 15.2249 3.5 12.869 3.5 9.99998C3.5 9.72384 3.72386 9.49998 4 9.49998C4.27614 9.49998 4.5 9.72384 4.5 9.99998C4.5 12.4853 6.51472 14.5 9 14.5C9 14.5512 9.0007 14.6022 9.00209 14.653Z" />
        <path d="M19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM12.5 11.5C12.2239 11.5 12 11.7239 12 12V13.5C12 13.7761 12.2239 14 12.5 14H14C14.2761 14 14.5 13.7761 14.5 13.5C14.5 13.2239 14.2761 13 14 13H13.5318C13.5821 12.9722 13.6337 12.9464 13.6865 12.923C13.9318 12.8137 14.1966 12.755 14.465 12.7503C14.7335 12.7456 15.0002 12.795 15.2492 12.8956C15.4982 12.9962 15.7244 13.1459 15.9143 13.3358C16.1095 13.531 16.4261 13.531 16.6214 13.3358C16.8166 13.1405 16.8166 12.8239 16.6214 12.6287C16.3365 12.3439 15.9973 12.1193 15.6238 11.9684C15.2503 11.8175 14.8503 11.7434 14.4476 11.7505C14.0448 11.7575 13.6476 11.8456 13.2797 12.0094C13.1838 12.0521 13.0905 12.0997 13 12.1519V12C13 11.7239 12.7761 11.5 12.5 11.5ZM13.3762 17.0316C13.7497 17.1825 14.1497 17.2566 14.5524 17.2495C14.9552 17.2425 15.3524 17.1544 15.7203 16.9906C15.8162 16.9479 15.9095 16.9003 16 16.8481V17C16 17.2761 16.2239 17.5 16.5 17.5C16.7761 17.5 17 17.2761 17 17V15.5C17 15.2239 16.7761 15 16.5 15H15C14.7239 15 14.5 15.2239 14.5 15.5C14.5 15.7761 14.7239 16 15 16H15.4682C15.4179 16.0278 15.3663 16.0536 15.3135 16.077C15.0682 16.1863 14.8034 16.245 14.535 16.2497C14.2665 16.2544 13.9998 16.205 13.7508 16.1044C13.5018 16.0038 13.2756 15.8541 13.0857 15.6642C12.8905 15.469 12.5739 15.469 12.3786 15.6642C12.1834 15.8595 12.1834 16.1761 12.3786 16.3713C12.6635 16.6561 13.0027 16.8807 13.3762 17.0316Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M12 9.59971C10.6546 10.2875 9.62981 11.513 9.20907 12.9928C9.14 12.9976 9.07028 13 9 13C7.34315 13 6 11.6568 6 9.99998V5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5V9.59971Z" />
        <path d="M9.5 17.5V16.7945C9.179 16.0961 9 15.319 9 14.5C6.51472 14.5 4.5 12.4853 4.5 9.99998C4.5 9.72384 4.27614 9.49998 4 9.49998C3.72386 9.49998 3.5 9.72384 3.5 9.99998C3.5 12.869 5.69675 15.2249 8.5 15.4776V17.5C8.5 17.7761 8.72386 18 9 18C9.27614 18 9.5 17.7761 9.5 17.5Z" />
        <path d="M19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM12.5 11.5C12.2239 11.5 12 11.7239 12 12V13.5C12 13.7761 12.2239 14 12.5 14H14C14.2761 14 14.5 13.7761 14.5 13.5C14.5 13.2239 14.2761 13 14 13H13.5318C13.5821 12.9722 13.6337 12.9464 13.6865 12.923C13.9318 12.8137 14.1966 12.755 14.465 12.7503C14.7335 12.7456 15.0002 12.795 15.2492 12.8956C15.4982 12.9962 15.7244 13.1459 15.9143 13.3358C16.1095 13.531 16.4261 13.531 16.6214 13.3358C16.8166 13.1405 16.8166 12.8239 16.6214 12.6287C16.3365 12.3439 15.9973 12.1193 15.6238 11.9684C15.2503 11.8175 14.8503 11.7434 14.4476 11.7505C14.0448 11.7575 13.6476 11.8456 13.2797 12.0094C13.1838 12.0521 13.0905 12.0997 13 12.1519V12C13 11.7239 12.7761 11.5 12.5 11.5ZM13.3762 17.0316C13.7497 17.1825 14.1497 17.2566 14.5524 17.2495C14.9552 17.2425 15.3524 17.1544 15.7203 16.9906C15.8162 16.9479 15.9095 16.9003 16 16.8481V17C16 17.2761 16.2239 17.5 16.5 17.5C16.7761 17.5 17 17.2761 17 17V15.5C17 15.2239 16.7761 15 16.5 15H15C14.7239 15 14.5 15.2239 14.5 15.5C14.5 15.7761 14.7239 16 15 16H15.4682C15.4179 16.0278 15.3663 16.0536 15.3135 16.077C15.0682 16.1863 14.8034 16.245 14.535 16.2497C14.2665 16.2544 13.9998 16.205 13.7508 16.1044C13.5018 16.0038 13.2756 15.8541 13.0857 15.6642C12.8905 15.469 12.5739 15.469 12.3786 15.6642C12.1834 15.8595 12.1834 16.1761 12.3786 16.3713C12.6635 16.6561 13.0027 16.8807 13.3762 17.0316Z" />
      </g>
    </svg>
  ),
  displayName: 'AudioLoadingIcon',
});
