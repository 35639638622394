import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const CallIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg viewBox="2 2 16 16" role="presentation" focusable="false" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M6.986 2.066l-.717.216a3.5 3.5 0 00-2.454 2.854c-.297 2.068.367 4.486 1.968 7.258 1.597 2.767 3.355 4.55 5.29 5.329a3.5 3.5 0 003.715-.705l.543-.514a2 2 0 00.246-2.623l-1.357-1.88a1.5 1.5 0 00-1.655-.556l-2.05.627-.053.01c-.226.033-.748-.456-1.398-1.582-.68-1.178-.82-1.867-.633-2.045l1.043-.973a2.497 2.497 0 00.575-2.85l-.662-1.471a2 2 0 00-2.4-1.095zm1.49 1.505l.66 1.471a1.497 1.497 0 01-.344 1.71l-1.046.974C7.077 8.36 7.3 9.442 8.198 11c.846 1.466 1.618 2.19 2.448 2.064l.124-.026 2.088-.637a.5.5 0 01.552.185l1.356 1.88a1 1 0 01-.123 1.312l-.543.514a2.5 2.5 0 01-2.653.503c-1.698-.684-3.303-2.311-4.798-4.9C5.15 9.3 4.544 7.091 4.805 5.277a2.5 2.5 0 011.753-2.039l.717-.216a1 1 0 011.2.548z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M6.986 2.066a2 2 0 012.327.946l.074.149.662 1.471a2.497 2.497 0 01-.442 2.718l-.133.132-1.043.973c-.188.178-.047.867.633 2.045.612 1.06 1.11 1.555 1.355 1.582h.043l.053-.01 2.05-.627a1.5 1.5 0 011.564.441l.091.115 1.357 1.88a2 2 0 01-.125 2.497l-.121.126-.543.514a3.5 3.5 0 01-3.715.705c-1.935-.78-3.693-2.562-5.29-5.329-1.6-2.772-2.265-5.19-1.968-7.258a3.5 3.5 0 012.262-2.79l.192-.064.717-.216z"
      />
    </svg>
  ),
  displayName: 'CallIcon',
});
