import React, { ReactNode, useState, useEffect } from 'react';
import { IQuestion, IAnswerOption, IAttendance } from '../../store/business/interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './ResultGraph.module.scss';
import { formatFloat } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import round from 'lodash/round';

export interface Props {
	question: IQuestion;
	renderAppendix?: (option: IAnswerOption) => ReactNode;
}

export const ResultGraph = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const members = useSelector((state: AppState) => state.business.members);
	const [options, setOptions] = useState<IAnswerOption[]>([]);
	const [notVotedOption, setNotVotedOption] = useState<IAnswerOption>(null);
	const [max, setMax] = useState(0);

	useEffect(() => {
		setOptions([...props.question.answerOptions].sort((a, b) => b.votesCount - a.votesCount));
	}, [props.question]);

	useEffect(() => {
		if(survey.useVoteWeightInPercent) {
			setMax(100);
			setNotVotedOption({
				type: 'virtualNotVoted',
				title: 'Nicht abgestimmt',
				attendees: members.entities
					.filter(m => !props.question.attendees.some(a => a.id === m.id))
					.map(m => { 
						return {
							id: m.id,
							voteWeight: m.voteWeight,
							text: m.title
						} as IAttendance;
					}),
				votesCount: 100 - options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0)
			} as IAnswerOption);
		}
		else {
			setMax(options[0]?.votesCount || 1);
		}
	}, [options]);

	if (!options || options.length === 0) {
		return <></>;
	}

	const getOptionResultInPercent = (option: IAnswerOption): number => {
		let optionResultInPercent = 0;
		if(survey.useVoteWeightInPercent) {
			optionResultInPercent = option.votesCount;
		}
		else {
			const totalNumberOfVotes = options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0);
			optionResultInPercent = option.votesCount * 100 / totalNumberOfVotes;
		}

		optionResultInPercent = isNaN(optionResultInPercent) ? 0 : optionResultInPercent;

		if(!survey.useVoteWeightInPercent) {
			optionResultInPercent = round(optionResultInPercent, 1);
		}

		return optionResultInPercent;
	};

	const getAttendeesVoteCountString = (option: IAnswerOption): string => {
		if(!survey.useVoteWeightInPercent) return formatFloat(option.votesCount);
		
		const optionResultInPercent = getOptionResultInPercent(option);
		return `${formatFloat(optionResultInPercent)}`;
	};

	const getAttendeesVoteUnitString = (option: IAnswerOption): string => {
		if(survey.useVoteWeightInPercent) return '%';

		return '';
	};

	const getBarStyle = (option: IAnswerOption): React.CSSProperties => {
		return { width: `calc(${(option.votesCount * 100) / max}% - ${((option.votesCount * 100) / max)}px)` };
	};

	const getBarColor = (option: IAnswerOption): React.CSSProperties => {
		return { background: option.votesCount > 0 ? '#6264A7' : '#ddd' };
	};

	return (
		<div className={styles.resultGraph}>
			<ul className={styles.items}>
				<TransitionGroup enter appear>
					{options.map((o, index) => {
						return (
							<CSSTransition
								key={index}
								classNames={{ appear: styles.itemAppear, appearActive: styles.itemAppearActive, appearDone: styles.itemAppearDone }}
								timeout={100}
							>
								<li className={styles.item}>
									<h6>{o.title}</h6>
									<div className={styles.flexContainer}>
										<span className={styles.bar} style={{ ...getBarStyle(o), ...getBarColor(o) }}></span>
										<span className={styles.scale}><span className={styles.value}>{getAttendeesVoteCountString(o)}</span>{getAttendeesVoteUnitString(o)}</span>{props.renderAppendix && props.renderAppendix(o)}
									</div>
								</li>
							</CSSTransition>
						);
					})}
					{notVotedOption && (
						<CSSTransition
							classNames={{ appear: styles.itemAppear, appearActive: styles.itemAppearActive, appearDone: styles.itemAppearDone }}
							timeout={100}
						>
							<li className={styles.item}>
								<h6>Nicht abgestimmt</h6>
								<div className={styles.flexContainer}>
									<span className={styles.bar} style={{ ...getBarStyle(notVotedOption), ...getBarColor(notVotedOption) }}></span>
									<span className={styles.scale}><span className={styles.value}>{getAttendeesVoteCountString(notVotedOption)}</span>%</span>{props.renderAppendix && props.renderAppendix(notVotedOption)}
								</div>
							</li>
						</CSSTransition>
					)}
				</TransitionGroup>
			</ul>
		</div>
	);
};
