import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const CalendarAgendaIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 20 20" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M17 14.5C17 15.8807 15.8807 17 14.5 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5C3 4.11929 4.11929 3 5.5 3H14.5C15.8807 3 17 4.11929 17 5.5V14.5ZM16 14.5V5.5C16 4.67157 15.3284 4 14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V14.5C4 15.3284 4.67157 16 5.5 16H14.5C15.3284 16 16 15.3284 16 14.5ZM14 6.5C14 6.74546 13.8231 6.94961 13.5899 6.99194L13.5 7H6.5C6.22386 7 6 6.77614 6 6.5C6 6.25454 6.17688 6.05039 6.41012 6.00806L6.5 6H13.5C13.7761 6 14 6.22386 14 6.5ZM14 10C14 10.2455 13.8231 10.4496 13.5899 10.4919L13.5 10.5H6.5C6.22386 10.5 6 10.2761 6 10C6 9.75454 6.17688 9.55039 6.41012 9.50806L6.5 9.5H13.5C13.7761 9.5 14 9.72386 14 10ZM14 13.5C14 13.7455 13.8231 13.9496 13.5899 13.9919L13.5 14H6.5C6.22386 14 6 13.7761 6 13.5C6 13.2545 6.17688 13.0504 6.41012 13.0081L6.5 13H13.5C13.7761 13 14 13.2239 14 13.5Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M17 14.5C17 15.8807 15.8807 17 14.5 17H5.5C4.11929 17 3 15.8807 3 14.5V5.5C3 4.11929 4.11929 3 5.5 3H14.5C15.8807 3 17 4.11929 17 5.5V14.5ZM14 6.5C14 6.25454 13.8231 6.05039 13.5899 6.00806L13.5 6H6.5L6.41012 6.00806C6.17688 6.05039 6 6.25454 6 6.5C6 6.74546 6.17688 6.94961 6.41012 6.99194L6.5 7H13.5L13.5899 6.99194C13.8231 6.94961 14 6.74546 14 6.5ZM14 10C14 9.75454 13.8231 9.55039 13.5899 9.50806L13.5 9.5H6.5L6.41012 9.50806C6.17688 9.55039 6 9.75454 6 10C6 10.2455 6.17688 10.4496 6.41012 10.4919L6.5 10.5H13.5L13.5899 10.4919C13.8231 10.4496 14 10.2455 14 10ZM14 13.5C14 13.2545 13.8231 13.0504 13.5899 13.0081L13.5 13H6.5L6.41012 13.0081C6.17688 13.0504 6 13.2545 6 13.5C6 13.7455 6.17688 13.9496 6.41012 13.9919L6.5 14H13.5L13.5899 13.9919C13.8231 13.9496 14 13.7455 14 13.5Z"
      />
    </svg>
  ),
  displayName: 'CalendarAgendaIcon',
});
