import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ChatOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M4.00058 4.7077L2.14645 2.85357C1.95118 2.65831 1.95118 2.34172 2.14645 2.14646C2.34171 1.9512 2.65829 1.9512 2.85355 2.14646L4.70768 4.00059C4.70768 4.0006 4.70769 4.00059 4.70768 4.00059L5.41638 4.70926C5.41639 4.70925 5.41637 4.70926 5.41638 4.70926L15.2907 14.5836C15.2907 14.5836 15.2907 14.5836 15.2907 14.5836L15.9994 15.2923C15.9994 15.2923 15.9994 15.2923 15.9994 15.2923L17.8536 17.1465C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L15.2923 15.9994C13.882 17.2445 12.0292 18 10 18C8.72679 18 7.49591 17.7018 6.38669 17.1393L6.266 17.075L2.62109 17.9851C2.31127 18.0625 2.02622 17.8369 2.00131 17.5438L2.00114 17.4624L2.01493 17.3787L2.925 13.735L2.86169 13.6153C2.4066 12.7186 2.12433 11.7422 2.03275 10.7283L2.00738 10.3463L2 10C2 7.97079 2.75551 6.11805 4.00058 4.7077ZM14.5836 15.2907L10.9683 11.6755C10.9075 11.8379 10.7645 11.9603 10.5899 11.9919L10.5 12H7.5C7.22386 12 7 11.7761 7 11.5C7 11.2545 7.17688 11.0504 7.41012 11.0081L7.5 11H10.2929L8.29288 9H7.5C7.22386 9 7 8.77614 7 8.5C7 8.2856 7.13495 8.10272 7.32453 8.03165L4.70926 5.41638C3.6444 6.64444 3 8.24696 3 10C3 11.217 3.31054 12.3878 3.89352 13.4249C3.94046 13.5084 3.9621 13.603 3.95692 13.6973L3.94274 13.7912L3.187 16.812L6.21104 16.0583C6.27294 16.0429 6.33662 16.0396 6.39873 16.0479L6.4903 16.0691L6.57701 16.1075C7.61362 16.6898 8.7837 17 10 17C11.753 17 13.3556 16.3556 14.5836 15.2907Z" />
        <path d="M16.6178 14.4964L15.8961 13.7747C16.5948 12.6856 17 11.3901 17 10C17 6.13401 13.866 3 10 3C8.6099 3 7.31443 3.4052 6.22528 4.10394L5.50358 3.38224C6.78499 2.5099 8.33295 2 10 2C14.4183 2 18 5.58172 18 10C18 11.6671 17.4901 13.215 16.6178 14.4964Z" />
        <path d="M12.5 9H11.1213L10.1213 8H12.5C12.7761 8 13 8.22386 13 8.5C13 8.74546 12.8231 8.94961 12.5899 8.99194L12.5 9Z" />
      </g>
      <g className={cx(iconClassNames.outline, classes.filledPart)}>
        <path d="M4.00058 4.7077L2.14645 2.85357C1.95118 2.65831 1.95118 2.34172 2.14645 2.14646C2.34171 1.9512 2.65829 1.9512 2.85355 2.14646L4.70768 4.00059C4.70768 4.0006 4.70769 4.00059 4.70768 4.00059L15.9994 15.2923C15.9994 15.2923 15.9994 15.2923 15.9994 15.2923L17.8536 17.1465C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1464 17.8536L15.2923 15.9994C13.882 17.2445 12.0292 18 10 18C8.72679 18 7.49591 17.7018 6.38669 17.1393L6.266 17.075L2.62109 17.9851C2.31127 18.0625 2.02622 17.8369 2.00131 17.5438L2.00114 17.4624L2.01493 17.3787L2.925 13.735L2.86169 13.6153C2.4066 12.7186 2.12433 11.7422 2.03275 10.7283L2.00738 10.3463L2 10C2 7.97079 2.75551 6.11805 4.00058 4.7077ZM10.9683 11.6755L10.2929 11H7.5L7.41012 11.0081C7.17688 11.0504 7 11.2545 7 11.5C7 11.7455 7.17688 11.9496 7.41012 11.9919L7.5 12H10.5L10.5899 11.9919C10.7645 11.9603 10.9075 11.8379 10.9683 11.6755ZM8.29288 9L7.32453 8.03165C7.13495 8.10272 7 8.2856 7 8.5C7 8.74546 7.17688 8.94961 7.41012 8.99194L7.5 9H8.29288Z" />
        <path d="M12.5 9H11.1213L16.6178 14.4964C17.4901 13.215 18 11.6671 18 10C18 5.58172 14.4183 2 10 2C8.33295 2 6.78499 2.5099 5.50358 3.38224L10.1213 8H12.5L12.5899 8.00806C12.8231 8.05039 13 8.25454 13 8.5C13 8.74546 12.8231 8.94961 12.5899 8.99194L12.5 9Z" />
      </g>
    </svg>
  ),
  displayName: 'ChatOffIcon',
});
