import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const CallVideoOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M2.85355 2.14652C2.65829 1.95126 2.34171 1.95126 2.14645 2.14652C1.95118 2.34179 1.95118 2.65837 2.14645 2.85363L3.50058 4.20777C2.61732 4.59341 2 5.47462 2 6.5V13.5C2 14.8807 3.11929 16 4.5 16H11.5C12.5254 16 13.4066 15.3827 13.7922 14.4994L17.1464 17.8536C17.3417 18.0489 17.6583 18.0489 17.8536 17.8536C18.0488 17.6584 18.0488 17.3418 17.8536 17.1465L2.85355 2.14652ZM12.9875 13.6947C12.892 14.4311 12.2625 15 11.5 15H4.5C3.67157 15 3 14.3284 3 13.5V6.5C3 5.73754 3.56887 5.10795 4.30533 5.01252L12.9875 13.6947Z" />
        <path d="M13 10.8787L16.4692 14.3479C17.1231 14.7616 18 14.2957 18 13.5001V6.50011C18 5.67606 17.0592 5.20568 16.4 5.70011L14 7.50011V6.5C14 5.11929 12.8807 4 11.5 4H6.12134L7.12134 5H11.5C12.3284 5 13 5.67157 13 6.5V10.8787ZM14 8.75011L17 6.50011V13.5001L14 11.2501V8.75011Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M2.85355 2.14652C2.65829 1.95126 2.34171 1.95126 2.14645 2.14652C1.95118 2.34178 1.95118 2.65837 2.14645 2.85363L3.50058 4.20777C2.61732 4.59341 2 5.47461 2 6.5V13.5C2 14.8807 3.11929 16 4.5 16H10.5C11.8136 16 12.8906 14.9869 12.9922 13.6994L17.1464 17.8536C17.3417 18.0489 17.6583 18.0489 17.8536 17.8536C18.0488 17.6584 18.0488 17.3418 17.8536 17.1465L2.85355 2.14652Z" />
        <path d="M16.7642 14.4319L15.3547 13.2334L14 11.8787V7.93076L16.7692 5.61749C17.2575 5.20956 18 5.55679 18 6.19309V13.8605C18 14.4999 17.2512 14.846 16.7642 14.4319Z" />
        <path d="M13 10.8787L6.12134 4H10.5C11.8807 4 13 5.11929 13 6.5V10.8787Z" />
      </g>
    </svg>
  ),
  displayName: 'CallVideoOffIcon',
});
