import { BusinessDataType } from '../store/business/enums';
import { store } from '../store';
import { businessLoading, membersLoaded, removeMember, upsertMembers } from '../store/business/actions';
import { xhr, download, IResponse } from '../xhr';
import { IMember } from '../store/business/interfaces/IMember';
import { ISurvey } from 'src/store/business/interfaces';

export class MemberService {
	public static emptyMember(): IMember {
		return {
			id: '',
			title: '',
			pin: '',
			eMail: '',
			voteWeight: 1,
			isSaving: false,
		};
	}

	public static parse(data: any): IMember {
		return {
			id: data['id'],
			title: data['title'],
			pin: data['pin'],
			eMail: data['eMail'],
			voteWeight: parseFloat(data['voteWeight']) || 1,
			lastLogin: data['lastLogin'] ? new Date(data['lastLogin']) : null,
			lastReaction: data['lastReaction'] ? new Date(data['lastReaction']) : null,
			lastLogout: data['lastLogout'] ? new Date(data['lastLogout']) : null,
			isSaving: false,
		};
	}

	public static parseArray(data: any[]): IMember[] {
		return data.map(MemberService.parse);
	}

	public static async loadForSurvey(survey: ISurvey): Promise<void> {
		const { business } = store.getState();
		if (!survey || survey.id === business.membersSurveyId || business.loading[BusinessDataType.Members]) {
			return;
		}
		if (!survey.id) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Members, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Members`, {
				method: 'GET',
			});
			let members = MemberService.parseArray(response.data.members).sort((a, b) => a.title.localeCompare(b.title));
			store.dispatch(
				membersLoaded(survey.id, members, response.data.lastChanged)
			);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Members, false));
		}
	}

	public static async load(survey: ISurvey, memberId: string): Promise<void> {
		if (!survey || !memberId || !survey.id) {
			return;
		}

		const response = await xhr(`Survey/${survey.id}/Members/${memberId}`, {
			method: 'GET',
		});
		
		let loadedMember = MemberService.parse(response.data.member);
		this.merge(survey, [loadedMember], response.data.lastChanged);
	}

	public static async upsert(survey: ISurvey, members: IMember[]): Promise<void> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Members]) {
			return;
		}

		members.forEach(m => m.isSaving = true);
		store.dispatch(upsertMembers(survey.id, members, new Date(Date.now())));

		const response = await xhr(`Survey/${survey.id}/Members`, {
			method: 'POST',
			data: members,
		});
		let responseMembers = MemberService.parseArray(response.data.members);

		responseMembers.forEach(m => m.isSaving = false);
		this.merge(survey, responseMembers, response.data.lastChanged);
	}

	public static async delete(survey: ISurvey, member: IMember): Promise<void> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Members]) {
			return;
		}

		const response = await xhr(`Survey/${survey.id}/Members/${member.id}`, {
			method: 'DELETE'
		});

		store.dispatch(removeMember(survey.id, member, response.data.lastChanged));
	}

	public static async deleteAll(survey: ISurvey): Promise<void> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Members]) {
			return;
		}

		store.dispatch(businessLoading(BusinessDataType.Members, true));
		const response = await xhr(`Survey/${survey.id}/Members`, {
			method: 'DELETE'
		});
		store.dispatch(membersLoaded(survey.id, [], response.data.lastChanged));
		store.dispatch(businessLoading(BusinessDataType.Members, false));
	}

	public static sort(survey: ISurvey): Promise<void> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Members]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Members, true));
		try {
			let members = business.members.entities || [];
			members.sort((a, b) => a.title.localeCompare(b.title));

			store.dispatch(
				membersLoaded(survey.id, members, business.members.lastChanged)
			);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Members, false));
		}
	}

	public static async export(survey: ISurvey, memberUrl: string) {
		await download(`Survey/${survey.id}/Members/Export?memberUrl=${encodeURIComponent(memberUrl)}`);
	}

	public static async exportProtocol(survey: ISurvey) {
		await download(`Survey/${survey.id}/Members/Protocol/Export`);
	}

	public static async import(survey: ISurvey, file: File): Promise<IResponse> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Members]) return;

		const formData = new FormData();
		formData.append("myFile", file, file.name);

		store.dispatch(businessLoading(BusinessDataType.Members, true));
		const response = await xhr(`Survey/${survey.id}/Members/Import`, {
			method: 'POST',
			data: formData,
			onError: (error) => {
				return Promise.resolve({
					data: {
						error: true,
						errorMessage: error.response.data
					}
				} as IResponse);
			}
		});

		if(!response.data.error) {
			let responseMembers = MemberService.parseArray(response.data.members);
			this.merge(survey, responseMembers, response.data.lastChanged);
		}

		store.dispatch(businessLoading(BusinessDataType.Members, false));
		return response;
	}

	private static merge(survey: ISurvey, upsertedMembers: IMember[], lastChanged?: Date) {
		const { business } = store.getState();

		let members = business.members.entities;

		upsertedMembers?.reverse().forEach(upsertedMember => {
			let memberIndex = members.findIndex(m => m.id === upsertedMember.id);
			if(memberIndex > -1) {
				members[memberIndex] = upsertedMember;
			}
			else {
				members.splice(0, 0, upsertedMember);
			}
		});

		store.dispatch(membersLoaded(survey.id, members, lastChanged));
	}
}
