import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const HeadsetIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path d="M4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8V11C16 12.1046 15.1046 13 14 13H13C12.4477 13 12 12.5523 12 12V9C12 8.44772 12.4477 8 13 8H15C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8H7C7.55228 8 8 8.44772 8 9V12C8 12.5523 7.55228 13 7 13H5V14C5 15.1046 5.89543 16 7 16H8C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C9.25972 18 8.61337 17.5978 8.26756 17H7C5.34315 17 4 15.6569 4 14V8ZM15 9H13V12H14C14.5523 12 15 11.5523 15 11V9ZM5 12H7V9H5V12ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16Z" />
    </svg>
  ),
  displayName: 'HeadsetIcon',
});
