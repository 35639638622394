import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const SkypeColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        d="M17.3887 11.0456C17.4389 10.709 17.4659 10.3695 17.4694 10.0293C17.4663 8.0737 16.6804 6.1991 15.2838 4.81632C13.8871 3.43353 11.9938 2.65535 10.0187 2.65233C9.74187 2.65233 9.47659 2.65233 9.21132 2.65233C8.46018 2.20552 7.59606 1.97974 6.72006 2.00143C5.89443 1.99692 5.08221 2.2081 4.36534 2.61365C3.64848 3.0192 3.05232 3.60478 2.63704 4.31131C2.22175 5.01783 2.00202 5.8203 2.00001 6.63776C1.99801 7.45522 2.2138 8.25874 2.62562 8.96725C2.56532 9.32196 2.53446 9.68097 2.53335 10.0407C2.53338 11.085 2.75773 12.1174 3.1915 13.0694C3.62528 14.0214 4.25856 14.8711 5.04934 15.5623C5.84011 16.2534 6.7703 16.7702 7.77817 17.0782C8.78605 17.3863 9.84857 17.4786 10.8952 17.3491C11.6163 17.7774 12.4418 18.0025 13.2827 18C14.5318 18 15.7299 17.5095 16.6142 16.6361C17.4985 15.7626 17.9969 14.5776 17.9999 13.3409C18.004 12.5358 17.7932 11.744 17.3887 11.0456Z"
        fill="#28A8EA"
      />
      <path
        d="M8.24912 9.84283C7.96903 9.65091 7.75526 9.42543 7.60937 9.16719C7.46347 8.90895 7.39014 8.59844 7.39014 8.23409C7.39014 7.78938 7.52199 7.41255 7.78491 7.10516C8.04861 6.79777 8.3919 6.56839 8.81476 6.41704C9.23762 6.26568 9.68778 6.19 10.1629 6.19C10.5 6.19 10.7988 6.21341 11.0601 6.25866C11.3223 6.30469 11.529 6.35852 11.6819 6.41938C11.9066 6.50832 12.0689 6.61208 12.1688 6.73067C12.2694 6.85004 12.3194 6.98891 12.3194 7.14885C12.3194 7.32907 12.2663 7.47419 12.161 7.58419C12.0564 7.69498 11.9238 7.75037 11.7639 7.75037C11.6648 7.75037 11.5665 7.72853 11.4682 7.68406C11.2029 7.56859 10.9688 7.48277 10.7644 7.4266C10.5608 7.37042 10.3346 7.34234 10.0865 7.34234C9.74708 7.34234 9.46387 7.41489 9.23762 7.55923C9.01214 7.70356 8.89902 7.91499 8.89902 8.19352C8.89902 8.3636 8.95753 8.51262 9.07456 8.63979C9.19237 8.76696 9.34996 8.88008 9.54891 8.97839C9.74786 9.07747 10.0443 9.20854 10.4383 9.3716C10.5062 9.39501 10.5491 9.41217 10.5655 9.42231C11.011 9.60566 11.3777 9.79446 11.6671 9.98795C11.9558 10.1822 12.182 10.4139 12.3451 10.6839C12.5082 10.9538 12.5893 11.276 12.5893 11.6497C12.5893 12.1124 12.4762 12.5001 12.2507 12.8146C12.0244 13.129 11.7085 13.3638 11.3028 13.5183C10.8963 13.6728 10.4212 13.75 9.87737 13.75C9.0925 13.75 8.44495 13.6283 7.93549 13.3833C7.75526 13.2952 7.62185 13.1914 7.53525 13.072C7.44865 12.9534 7.40574 12.8122 7.40574 12.6492C7.40574 12.4658 7.46269 12.3223 7.5766 12.2185C7.68973 12.1147 7.83718 12.0625 8.01741 12.0625C8.18749 12.0625 8.35835 12.1069 8.53233 12.1951C8.72894 12.2942 8.88419 12.3683 8.9981 12.4174C9.11201 12.4666 9.2462 12.5087 9.40146 12.5446C9.55593 12.5805 9.72835 12.5977 9.91872 12.5977C10.3026 12.5977 10.592 12.5212 10.7878 12.366C10.9829 12.2115 11.0804 12 11.0804 11.7317C11.0804 11.5585 11.0196 11.4032 10.8971 11.2675C10.7746 11.1317 10.5944 11.0006 10.3572 10.875C10.1192 10.7494 9.78453 10.5965 9.3523 10.4163C8.89746 10.2259 8.52921 10.0348 8.24912 9.84283"
        fill="white"
      />
    </svg>
  ),
  displayName: 'SkypeColorIcon',
});
