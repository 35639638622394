import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const LiveOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path d="M2.85355 2.14646C2.65829 1.9512 2.34171 1.9512 2.14645 2.14646C1.95118 2.34172 1.95118 2.65831 2.14645 2.85357L4.00062 4.70774C2.75552 6.11809 2 7.97086 2 10.0001C2 12.3002 2.97073 14.3737 4.52487 15.833C4.71256 16.0093 5.00478 15.9953 5.18685 15.8132C5.39483 15.6053 5.37638 15.2641 5.16377 15.0608C3.83047 13.7863 3 11.9902 3 10.0001C3 8.24703 3.64442 6.64449 4.7093 5.41642L5.77334 6.48046C4.9783 7.4342 4.5 8.66122 4.5 9.99995C4.5 11.6179 5.19861 13.0727 6.31064 14.0791C6.49437 14.2453 6.77419 14.2258 6.9494 14.0506C7.16356 13.8364 7.13387 13.4824 6.91351 13.2747C6.04324 12.4541 5.5 11.2905 5.5 9.99995C5.5 8.93746 5.86823 7.96097 6.48402 7.19114L8.83609 9.54321C8.78052 9.68471 8.75 9.83879 8.75 10C8.75 10.6904 9.30964 11.25 10 11.25C10.1612 11.25 10.3153 11.2195 10.4568 11.1639L17.1464 17.8536C17.3417 18.0488 17.6583 18.0488 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1465L15.9995 15.2924C15.9994 15.2924 15.9995 15.2924 15.9995 15.2924L15.2908 14.5837C15.2908 14.5837 15.2908 14.5837 15.2908 14.5837L14.2266 13.5195C14.2266 13.5195 14.2266 13.5195 14.2266 13.5195L13.5159 12.8088C13.5159 12.8088 13.5159 12.8088 13.5159 12.8088L11.1639 10.4568C11.1639 10.4568 11.1639 10.4568 11.1639 10.4568L9.54321 8.83609C9.54322 8.83609 9.54321 8.83609 9.54321 8.83609L2.85355 2.14646Z" />
      <path d="M16.6178 14.4965C17.4901 13.2151 18 11.6671 18 10.0001C18 7.78604 17.1006 5.78206 15.6472 4.33358C15.4606 4.14767 15.1603 4.15776 14.9741 4.344C14.7702 4.54791 14.7829 4.88086 14.9854 5.08624C16.2312 6.35005 17 8.08529 17 10.0001C17 11.3902 16.5948 12.6856 15.8961 13.7748L16.6178 14.4965Z" />
      <path d="M14.803 12.6817C15.247 11.8883 15.5 10.9737 15.5 9.99995C15.5 8.47068 14.8759 7.08719 13.8684 6.0903C13.6859 5.90973 13.3939 5.92403 13.2124 6.10556C13.0043 6.31368 13.0246 6.6551 13.2296 6.86631C14.0159 7.67655 14.5 8.78172 14.5 9.99995C14.5 10.6947 14.3426 11.3526 14.0614 11.9401L14.803 12.6817Z" />
    </svg>
  ),
  displayName: 'LiveOffIcon',
});
