import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const ImageUnavailableIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 20 20" className={classes.svg}>
      <path d="M2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L3.55462 4.26173C3.20539 4.75213 3 5.35207 3 5.99999V14C3 15.6568 4.34315 17 6 17H14C14.6479 17 15.2479 16.7946 15.7383 16.4454L17.1464 17.8536C17.3417 18.0488 17.6583 18.0488 17.8536 17.8536C18.0488 17.6583 18.0488 17.3417 17.8536 17.1464L2.85355 2.14645ZM9.41374 10.1208C9.24519 10.1923 9.08705 10.2961 8.94868 10.432L4.27937 15.0201C4.10191 14.7214 4 14.3726 4 14V5.99999C4 5.62909 4.10096 5.28177 4.2769 4.98401L9.41374 10.1208ZM6 16C5.63085 16 5.28505 15.9 4.98824 15.7256L9.64956 11.1453C9.84409 10.9542 10.1559 10.9542 10.3504 11.1453L15.0118 15.7256C14.7149 15.9 14.3692 16 14 16H6ZM16 5.99999V13.8786L16.8984 14.777C16.9647 14.5292 17 14.2687 17 14V5.99999C17 4.34313 15.6569 2.99999 14 2.99999H6C5.73127 2.99999 5.47078 3.03532 5.22295 3.1016L6.12134 3.99999H14C15.1046 3.99999 16 4.89542 16 5.99999ZM14 7.49999C14 6.67156 13.3284 5.99999 12.5 5.99999C11.6716 5.99999 11 6.67156 11 7.49999C11 8.32841 11.6716 8.99999 12.5 8.99999C13.3284 8.99999 14 8.32841 14 7.49999ZM13 7.49999C13 7.77613 12.7761 7.99999 12.5 7.99999C12.2239 7.99999 12 7.77613 12 7.49999C12 7.22384 12.2239 6.99999 12.5 6.99999C12.7761 6.99999 13 7.22384 13 7.49999Z" />
    </svg>
  ),
  displayName: 'ImageUnavailableIcon',
});
