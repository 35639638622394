import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PanoramaOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="2 2 16 16" role="presentation" focusable="false">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M6 10.9999c.55228 0 1-.4477 1-.99997 0-.55229-.44772-1-1-1s-1 .44771-1 1c0 .55227.44772.99997 1 .99997zM2.85355 2.14645c-.19526-.19527-.51184-.19527-.7071 0-.19527.19526-.19527.51184 0 .7071l1.18477 1.18477C2.58037 4.20469 2 4.87419 2 5.69776v8.60454c0 1.0462.93657 1.8437 1.96942 1.6771a37.86227 37.86227 0 0111.16998-.1329l2.007 2.0071c.1953.1952.5119.1952.7072 0 .1952-.1953.1952-.5119 0-.7072l-1.1848-1.1847-6.1234-6.12344c0 .00001 0 0 0 0L9.16168 8.45454c0-.00001-.00001.00001 0 0L6.00519 5.29808c-.00001 0 .00001 0 0 0L4.86067 4.15353c.00001 0-.00001 0 0 0L2.85355 2.14645zM13 13.7071v.9049a38.8862 38.8862 0 00-1-.0644v-1.8405l1 1zm-2.7072-2.7072H9c-.55228 0-1 .4477-1 1v2.5477a38.8804 38.8804 0 00-1 .0644v-2.6121H5c-.55228 0-1 .4477-1 1v1.9621c-.0633.0099-.12659.02-.18985.0302C3.38527 15.0607 3 14.7326 3 14.3023V5.69776c0-.43037.38527-.75847.81015-.68992a38.8873 38.8873 0 00.57961.08903l5.90304 5.90303zm3.8282.9998L16 13.8786v-.8789c0-.5523-.4477-1-1-1h-.879zm2.7318 2.7318l.7094.7093c.2708-.2992.4378-.6967.4378-1.1385V5.69776c0-1.04621-.9366-1.84379-1.9694-1.67716a37.86285 37.86285 0 01-9.57121.31744l1.08847 1.08847a38.86251 38.86251 0 008.64194-.41867c.4249-.06855.8102.25955.8102.68992v8.60454c0 .1631-.0553.3115-.1472.4292zM14 11.0002c.5523 0 1-.4477 1-1 0-.55231-.4477-1.00003-1-1.00003s-1 .44772-1 1.00003c0 .5523.4477 1 1 1z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M2.85355 2.14645c-.19526-.19527-.51184-.19527-.7071 0-.19527.19526-.19527.51184 0 .7071l1.18479 1.18478C2.58038 4.20468 2 4.87419 2 5.69778v8.60452c0 1.0462.93657 1.8438 1.96942 1.6771.49932-.0805.99985-.151 1.50129-.2114a37.8594 37.8594 0 019.66869.0785l2.007 2.0071c.1953.1952.5119.1952.7072 0 .1952-.1953.1952-.5119 0-.7072L2.85355 2.14645zM13.9949 14.702c-.3314-.0343-.663-.0643-.9949-.09v-.9049l.9949.9949zM12 12.7071v1.8405a38.84187 38.84187 0 00-4 0V12c0-.5523.44772-1 1-1h1.2929L12 12.7071zM4 14.962V13c0-.5523.44772-1 1-1h2v2.612a38.91714 38.91714 0 00-3 .35zM5.5 11c-.55228 0-1-.4477-1-1 0-.55228.44772-1 1-1s1 .44772 1 1c0 .5523-.44772 1-1 1zm8.6212.9998L6.45939 4.33805a37.86331 37.86331 0 009.57121-.31743C17.0634 3.85398 18 4.65156 18 5.69778v8.60452c0 .4418-.167.8393-.4378 1.1385L16 13.8787v-.8789c0-.5523-.4477-1-1-1h-.8788zm.3788-.9995c.5523 0 1-.4477 1-1 0-.55228-.4477-.99999-1-.99999s-1 .44771-1 .99999c0 .5523.4477 1 1 1z"
      />
    </svg>
  ),
  displayName: 'PanoramaOffIcon',
});
