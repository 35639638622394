import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import {
	IconButton,
	TextField,
	CommandButton,
	PrimaryButton,
} from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { dataUrlToFile, delay } from '../../shared/utils';
import styles from './Share.module.scss';
import { download } from '../../xhr';

export interface Props {
	surveyCode: string;
	surveyId: string;
	onClose: () => void;
}

declare class ClipboardItem {
	constructor(input: { [contentType: string]: Blob });
}

export const Share = (props: Props) => {
	const memberUrl = useSelector((state: AppState) => state.system.memberBasePath);
	const [surveyUrl, setSurveyUrl] = useState<string>();
	const [imageFile, setImageFile] = useState<File>();
	const [imageUrl, setImageUrl] = useState<string>();
	// const [copyText, setCopyText] = useState<string>('In Zwischenablage kopieren');
	const qrImageElement = useRef(null);
	const urlTextElement = useRef(null);

	useEffect(() => {
		const url = `${memberUrl}?survey=${props.surveyCode}`;
		setSurveyUrl(url);
	}, [props.surveyCode, memberUrl]);

	useEffect(() => {
		const initQrCode = async () => {
			if (!surveyUrl) return;
			let qrCodeOptions = {} as QRCode.QRCodeToDataURLOptions;
			qrCodeOptions.errorCorrectionLevel = 'Q';
			qrCodeOptions.version = 10;
			const base64Image = await QRCode.toDataURL(surveyUrl, qrCodeOptions);
			const file = dataUrlToFile(base64Image, 'Sitzungs-ID.png');
			const url = URL.createObjectURL(file);

			setImageFile(file);
			setImageUrl(url);
		};
		initQrCode();
	}, [surveyUrl]);

	const writeConfirmation = async (): Promise<void> => {
		//setCopyText('kopiert!');
		await delay(1000);
		//setCopyText('In Zwischenablage kopieren');
	};

	const writeUrl = async (): Promise<void> => {
		const permissions = await (navigator.permissions as any).query({ name: 'clipboard-write' });
		if (permissions.state === 'granted') {
			navigator.clipboard.writeText(urlTextElement.current.value);
			await writeConfirmation();
		}
	};

	const writeImage = async (): Promise<void> => {
		const permissions = await (navigator.permissions as any).query({ name: 'clipboard-write' });
		if (permissions.state === 'granted') {
			const cbItem = new ClipboardItem({ [imageFile.type]: imageFile });

			qrImageElement.current.focus();
			await (navigator.clipboard as any).write([cbItem]);
			await writeConfirmation();
		}
	};

	const copyUrl = () => {
		urlTextElement.current.select();
		document.execCommand('copy');
	};

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Sitzung teilen</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onClose();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<div className={styles.shareContent}>
				<div className={styles.label}>QR-Code</div>
				<div className={styles.qrContent}>
					<div className={styles.qrClipboard}>
						<span className={styles.qrHintText}>
							Empfänger können den Code mit einem Smartphone oder Tablet scannen, um an der Sitzung teilzunehmen.
						</span>
						<PrimaryButton
							text='Herunterladen'
							ariaLabel='Bild kopieren'
							onClick={
								true
									? () => download(`Survey/${props.surveyId}/ShareQrCode?memberUrl=${encodeURIComponent(memberUrl)}`)
									: writeImage
							}
						/>
					</div>
					<div className={styles.qrImage}>
						{imageUrl === '' ? <></> : <img src={imageUrl} ref={qrImageElement} alt='QR-Code' />}
					</div>
				</div>
				<div className={styles.orContainer}>- oder -</div>
				<div className={styles.urlContent}>
					<TextField
						className={styles.urlField}
						label={'Sitzungs-URL'}
						value={surveyUrl}
						readOnly
						componentRef={urlTextElement}
					/>
					<div className={styles.urlClipboard}>
						<IconButton
							iconProps={{ iconName: 'Copy' }}
							ariaLabel='Url kopieren'
							onClick={true ? copyUrl : writeUrl}
							title='In Zwischenablage kopieren'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
