import { ISystemState } from './interfaces';
import {
	ISystemActionTypes,
	MESSAGE_ADD,
	MESSAGE_REMOVE,
	SYSTEM_LOADING,
	CONFIG_SET,
	CONFIG_LOADED,
	TOKEN_LOADED,
	LOGGED_IN,
	ERROR_SET,
	TOKEN_UPDATED,
} from './types';

const initialState: ISystemState = {
	loading: { config: false, login: false },
	messages: [],
};

function innerReducer(state = initialState, action: ISystemActionTypes): ISystemState {
	switch (action.type) {
		case SYSTEM_LOADING: {
			return { ...state, loading: { ...state.loading, [action.loading]: action.isLoading } };
		}
		case MESSAGE_ADD: {
			return {
				...state,
				messages: state.messages.concat({
					created: action.created || new Date(),
					type: action.messageType,
					message: action.message,
					sticky: action.sticky,
				}),
			};
		}
		case MESSAGE_REMOVE: {
			return { ...state, messages: state.messages.filter((m, i) => !!m && i !== action.index) };
		}
		case CONFIG_SET: {
			return {
				...initialState,
				apiBasePath: (action.apiUrl || '').replace(/\/$/, ''),
				memberBasePath: (action.memberUrl || '').replace(/\/$/, ''),
				context: action.context,
			};
		}
		case CONFIG_LOADED: {
			return { ...state, title: action.title, reversion: action.reversion, appClientId: action.appClientId };
		}
		case LOGGED_IN: {
			return { ...state, apiKey: action.apiKey };
		}
		case TOKEN_LOADED: {
			return { ...state, token: action.token, groupId: action.groupId };
		}
		case TOKEN_UPDATED: {
			return { ...state, token: action.token };
		}
		case ERROR_SET: {
			return { ...state, error: action.error, errorStatus: action.errorStatus };
		}
		default: {
			return state;
		}
	}
}

function systemReducer(state = initialState, action: ISystemActionTypes): ISystemState {
	const result = innerReducer(state, action);
	if (process.env.NODE_ENV !== 'production') console.log(`systemReducer`, action, state, result);
	return result;
}

export default systemReducer;
