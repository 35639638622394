import { DefaultButton, Dialog, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import styles from './ConfirmationDialog.module.scss';



export interface Props {
	hidden: boolean;
	title: string;
	message: string;
	onDismiss: () => void;
	onConfirm: () => void;
}

export const ConfirmationDialog = (props: Props) => {
	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={props.onDismiss}
			dialogContentProps={{
				type: DialogType.normal,
				title: props.title,
				subText: props.message
			}}
			modalProps={{className: styles.dialog}}
		>
			<DefaultButton onClick={props.onDismiss} text='Abbrechen' />
			<PrimaryButton onClick={props.onConfirm} text='Ok' />
		</Dialog>
	);
};

