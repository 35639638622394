import {
	ISystemActionTypes,
	SYSTEM_LOADING,
	MESSAGE_ADD,
	MESSAGE_REMOVE,
	MESSAGES_CLEAR,
	CONFIG_SET,
	CONFIG_LOADED,
	TOKEN_LOADED,
	LOGGED_IN,
	ERROR_SET,
	TOKEN_UPDATED,
} from './types';
import { MessageType, SystemLoading } from './enums';
import { IContext } from './interfaces';

export const systemLoading = (loading: SystemLoading, isLoading: boolean): ISystemActionTypes => {
	return {
		type: SYSTEM_LOADING,
		loading,
		isLoading,
	};
};

export const messagesClear = (): ISystemActionTypes => {
	return {
		type: MESSAGES_CLEAR,
	};
};


export const messageAdd = (type: MessageType, message: string | undefined, sticky: boolean): ISystemActionTypes => {
	return {
		type: MESSAGE_ADD,
		created: new Date(),
		messageType: type,
		message,
		sticky,
	};
};

export const messageRemove = (index: number): ISystemActionTypes => {
	return {
		type: MESSAGE_REMOVE,
		index,
	};
};

export const config = (apiUrl: string, memberUrl: string, context: IContext): ISystemActionTypes => {
	return {
		type: CONFIG_SET,
		apiUrl,
		memberUrl,
		context,
	};
};

export const configLoaded = (
	title: string | null,
	reversion: number | null,
	appClientId: string | null
): ISystemActionTypes => {
	return {
		type: CONFIG_LOADED,
		title,
		reversion,
		appClientId,
	};
};

export const loggedIn = (apiKey: string | null): ISystemActionTypes => {
	return {
		type: LOGGED_IN,
		apiKey,
	};
};

export const tokenLoaded = (token: string, groupId: string): ISystemActionTypes => {
	return {
		type: TOKEN_LOADED,
		token,
		groupId,
	};
};

export const tokenUpdated = (token: string): ISystemActionTypes => {
	return {
		type: TOKEN_UPDATED,
		token,
	};
};

export const errorSet = (error?: string, errorStatus?: number): ISystemActionTypes => {
	return {
		type: ERROR_SET,
		error,
		errorStatus,
	};
};
