import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PanoramaIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="2 2 16 16" role="presentation" focusable="false">
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M11 8.99994C11 9.55222 10.5523 9.99994 10 9.99994C9.44772 9.99994 9 9.55222 9 8.99994C9 8.44765 9.44772 7.99994 10 7.99994C10.5523 7.99994 11 8.44765 11 8.99994Z" />
        <path d="M7 9.99994C7 10.5522 6.55228 10.9999 6 10.9999C5.44772 10.9999 5 10.5522 5 9.99994C5 9.44765 5.44772 8.99994 6 8.99994C6.55228 8.99994 7 9.44765 7 9.99994Z" />
        <path d="M15 10.0002C15 10.5525 14.5523 11.0002 14 11.0002C13.4477 11.0002 13 10.5525 13 10.0002C13 9.4479 13.4477 9.00018 14 9.00018C14.5523 9.00018 15 9.4479 15 10.0002Z" />
        <path d="M3.96942 4.02062C2.93657 3.85398 2 4.65156 2 5.69778V14.3023C2 15.3485 2.93657 16.1461 3.96942 15.9794C7.96398 15.335 12.036 15.335 16.0306 15.9794C17.0634 16.1461 18 15.3485 18 14.3023V5.69777C18 4.65156 17.0634 3.85398 16.0306 4.02062C12.036 4.66507 7.96398 4.66507 3.96942 4.02062ZM3 5.69778C3 5.2674 3.38527 4.93931 3.81015 5.00785C7.91021 5.66933 12.0898 5.66933 16.1898 5.00785C16.6147 4.93931 17 5.2674 17 5.69777V14.3023C17 14.7326 16.6147 15.0607 16.1899 14.9922C16.1266 14.982 16.0633 14.9719 16 14.962V12.9997C16 12.4474 15.5523 11.9997 15 11.9997H13V14.612C12.6669 14.5863 12.3335 14.5648 12 14.5476V11.9999C12 11.4477 11.5523 10.9999 11 10.9999H9C8.44772 10.9999 8 11.4477 8 11.9999V14.5476C7.66647 14.5648 7.33311 14.5863 7 14.612V11.9999H5C4.44772 11.9999 4 12.4477 4 12.9999V14.962C3.9367 14.9719 3.87341 14.982 3.81015 14.9922C3.38527 15.0607 3 14.7326 3 14.3023V5.69778Z" />
      </g>
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M3.96942 4.02062C2.93657 3.85398 2 4.65156 2 5.69778V14.3023C2 15.3485 2.93657 16.1461 3.96942 15.9794C4.46874 15.8989 4.96927 15.8284 5.47071 15.768C8.98078 15.345 12.5353 15.4155 16.0306 15.9794C16.0951 15.9898 16.1593 15.9965 16.2229 15.9995C17.1768 16.0454 18 15.2831 18 14.3023V5.69777C18 4.65156 17.0634 3.85398 16.0306 4.02062C12.036 4.66507 7.96398 4.66507 3.96942 4.02062ZM16 14.962C15.0038 14.8064 14.003 14.6897 13 14.612V11.9998H15C15.5523 11.9998 16 12.4475 16 12.9998V14.962ZM12 14.5476C10.6676 14.4789 9.33245 14.4789 8 14.5476V12C8 11.4477 8.44772 11 9 11H11C11.5523 11 12 11.4477 12 12V14.5476ZM7 14.612C5.99697 14.6897 4.99621 14.8064 4 14.962V13C4 12.4477 4.44772 12 5 12H7V14.612ZM10 10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8C10.5523 8 11 8.44772 11 9C11 9.55228 10.5523 10 10 10ZM6.5 10C6.5 10.5523 6.05228 11 5.5 11C4.94772 11 4.5 10.5523 4.5 10C4.5 9.44772 4.94772 9 5.5 9C6.05228 9 6.5 9.44772 6.5 10ZM15.5 10.0003C15.5 10.5526 15.0523 11.0003 14.5 11.0003C13.9477 11.0003 13.5 10.5526 13.5 10.0003C13.5 9.44802 13.9477 9.00031 14.5 9.00031C15.0523 9.00031 15.5 9.44802 15.5 10.0003Z"
      />
    </svg>
  ),
  displayName: 'PanoramaIcon',
});
