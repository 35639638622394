import React from "react";
import {QuestionFormToggle} from "./QuestionFormToggle";

export interface Props {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	onChanged: (checked:boolean)=>void;
}

export const EnableVotesSplittingToggle = (props: Props) => {
	return (
		<>
			{props.visible && (
				<QuestionFormToggle
					checkedLabelText="Stimmensplitting"
					uncheckedLabelText="Stimmensplitting"
					checked={props.checked}
					disabled={props.disabled}
					visible={props.visible}
					onChanged={props.onChanged}
				/>
			)}
		</>
	);
};