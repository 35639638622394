import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const ArrowDownIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path d="M16.8664 10.8371C17.0526 10.6332 17.0382 10.3169 16.8342 10.1307C16.6303 9.94456 16.314 9.95895 16.1278 10.1629L10.4971 16.3307V2.5C10.4971 2.22386 10.2733 2 9.99712 2C9.72097 2 9.49712 2.22386 9.49712 2.5V16.3279L3.86903 10.1629C3.68285 9.95895 3.36659 9.94456 3.16265 10.1307C2.95871 10.3169 2.94431 10.6332 3.13049 10.8371L9.44454 17.7535C9.5723 17.8934 9.73984 17.9732 9.91298 17.993C9.94033 17.9976 9.96844 18 9.99712 18C10.024 18 10.0504 17.9979 10.0761 17.9938C10.252 17.9756 10.4227 17.8955 10.5523 17.7535L16.8664 10.8371Z" />
    </svg>
  ),
  displayName: 'ArrowDownIcon',
});
