import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const TeamsMonochromeIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 28 28" className={classes.svg}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 11H24.8732C25.4952 11 26.0002 11.505 26.0002 12.127V17.716C26.0002 19.791 24.3182 21.473 22.2432 21.473C21.7092 21.473 21.2052 21.356 20.7472 21.153C20.0452 23.383 17.9622 25 15.5002 25C12.9822 25 10.8602 23.308 10.2072 21H3.16724C2.52524 21 2.00024 20.475 2.00024 19.833V8.167C2.00024 7.525 2.52524 7 3.16724 7H12.6862C12.6862 5.17 14.1702 3.687 16.0002 3.687C17.8302 3.687 19.3132 5.17 19.3132 7C19.3132 8.83 17.8302 10.313 16.0002 10.313V11ZM16.0002 4.687C17.2752 4.687 18.3132 5.724 18.3132 7C18.3132 8.276 17.2752 9.313 16.0002 9.313V8.167C16.0002 7.525 15.4752 7 14.8332 7H13.6862C13.6862 5.724 14.7242 4.687 16.0002 4.687ZM7.97224 18.25V11.828H5.56924V9.75H12.4302V11.828H10.0422V18.25H7.97224ZM25.0002 17.716C25.0002 19.236 23.7632 20.473 22.2432 20.473C21.7782 20.473 21.3442 20.353 20.9622 20.146C20.9872 19.934 21.0002 19.719 21.0002 19.5V12.12C21.0002 12.08 20.9982 12.04 20.9932 12H24.8732C24.9432 12 25.0002 12.057 25.0002 12.127V17.716ZM15.5002 24C17.9812 24 20.0002 21.981 20.0002 19.5V12.12C20.0002 12.054 19.9462 12 19.8802 12H16.0002V19.833C16.0002 20.475 15.4752 21 14.8332 21H11.2572C11.8762 22.746 13.5442 24 15.5002 24ZM22.5002 6C23.3272 6 24.0002 6.673 24.0002 7.5C24.0002 8.327 23.3272 9 22.5002 9C21.6732 9 21.0002 8.327 21.0002 7.5C21.0002 6.673 21.6732 6 22.5002 6ZM25.0002 7.5C25.0002 8.881 23.8812 10 22.5002 10C21.1192 10 20.0002 8.881 20.0002 7.5C20.0002 6.119 21.1192 5 22.5002 5C23.8812 5 25.0002 6.119 25.0002 7.5Z"
      />
    </svg>
  ),
  displayName: 'TeamsMonochromeIcon',
});
