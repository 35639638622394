import { CommandButton, TextField, Text } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import { IAnswerOption } from '../../../store/business/interfaces';
import { EntityList } from '../EntityList';

import { createGuid } from '../../../shared/utils';

import styles from './../Sidepanel.module.scss';
import listStyles from './../EntityList.module.scss';


export interface Props{
	answerOptions:IAnswerOption[];
	disabled:boolean;
	onChange:(answerOptions:IAnswerOption[])=>void;
}

export const AnswerOptionsList = (props: Props) => {
	const [hoveredAnswer, setHoveredAnswer] = useState<string|null>(null);

	const onChangeAnswerOption = (data: { id: string; title?: string }) => {
		const changedAnswerOption = { ...props.answerOptions.find((o) => o.id === data.id), ...data };
		const index = props.answerOptions.findIndex((o) => o.id === changedAnswerOption.id);
		if (index === -1) {
			props.onChange(props.answerOptions.concat([changedAnswerOption]));
		} else {
			const newAnswerOptions = props.answerOptions.map((m) => m);
			newAnswerOptions[index] = changedAnswerOption;
			props.onChange(newAnswerOptions);
		}
	};

	return (
		<>
			<div className={styles.answersList}>
				<span className={styles.label}>Antworten</span>
				<EntityList
					entities={props.answerOptions}
					allowMove={true}
					onChange={(options) => {props.onChange(options);}}
					textNewEntity='Neue Antwort'
					disabled={props.disabled}
					textNewEmptyEntity='Antwort'
					className={styles.answerOptionsEntityList}
					renderItem={(o, i, all, hitEnter) => {
						return (
							<td>
								<TextField
									key={`${o.id}-title`}
									defaultValue={o.title}
									placeholder='Antwort'
									onPaste={(evt) => {
										if (i === all.length - 1) {
											const input = evt.clipboardData.getData('text');
											if (input) {
												const parts = input.split(/\r?\n/);
												if (parts.length > 1) {
													evt.preventDefault();
													const newOption = parts.map(
														(p) =>
															({
																id: createGuid(),
																title: p,
																votesCount: 0,
																attendees: [],
															} as IAnswerOption)
													);
													props.onChange(props.answerOptions.concat(newOption).filter((a) => a.title));
												}
											}
										}
									}}
									onKeyDown={(evt) => {
										if (evt.key === 'Enter') {
											hitEnter(o, evt.currentTarget as HTMLInputElement);
										}
									}}
									onBlur={(evt) => {
										onChangeAnswerOption({ id: o.id, title: evt.currentTarget.value });
									}}
									disabled={props.disabled}
									onFocus={(evt) => {
										evt.currentTarget.select();
									}}
									className='answerInputField'
									onRenderSuffix={()=>
										<div
											className={styles.entityListDeleteButtonContainer}
											onMouseOver={()=>{setHoveredAnswer(o.id);}}
											onMouseLeave={()=>{setHoveredAnswer(null);}}
										>
											{o.id === hoveredAnswer && (
												<CommandButton
													className={[styles.entityListDeleteIcon, 'noGrow'].join(' ')}
													iconProps={{ iconName: 'Delete' }}
													title='Löschen'
													onClick={() => {
														const newAnswerOptions = props.answerOptions.filter((ao) => ao.id !== o.id);
														props.onChange(newAnswerOptions);
													}}
												/>
											)}
										</div>
									}
									onMouseOver={()=>{setHoveredAnswer(o.id);}}
									onMouseLeave={()=>{setHoveredAnswer(null);}}
								/>
							</td>
						);
					}}
					onRenderNewEntityContainer={(addNewEntityButton)=>{
						return (
							<tr>
								<td></td>
								<td>
									<div className={[listStyles.newListItem, styles.newAnswerOption].join(' ')} key={`new`}>
										{addNewEntityButton}
									</div>
								</td>
							</tr>
						);
					}}
					renderNewEntryBelow={true}
				/>
			</div>
			{props.answerOptions.length < 1 && (
				<Text variant='medium' block className={styles.errorMessage} >Mindestens eine Antwort muss vorhanden sein.</Text>
			)}
		</>
	);
};