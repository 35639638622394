import styles from './Sidepanel.module.scss';
import memberStyles from './MemberImport.module.scss';

import React, { useState } from 'react';
import { CommandButton, Icon, PrimaryButton } from 'office-ui-fabric-react';
import { IconWithCallout } from '../atoms/IconWithCallout';
import { IResponse, download } from '../../xhr';
import { ISurvey } from '../../store/business/interfaces';

export interface Props {
	survey: ISurvey;
	onUpload: (file: File) => Promise<IResponse>;
	onClose: () => void;
}

export const MemberImport = (props: Props) => {
	const hiddenFileInput = React.useRef(null);

	const [showError, setShowError] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectedFile, setSelectedFile] = useState<File>();

	const onDownloadExampleClick = async () => {
		await download(`/Survey/${props.survey.id}/MemberImportTemplate`);
	};

	const onSelectFileClick = () => {
		setShowError(false);
		setShowSuccess(false);
		hiddenFileInput.current.click();
	};

	const onFileInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		setSelectedFile(event.currentTarget.files[0]);
	};

	const onUploadClick = async () => {
		setShowError(false);
		setShowSuccess(false);
		if(!selectedFile || !selectedFile?.name?.toLocaleLowerCase().endsWith('.csv')) {
			setShowError(true);
			setErrorMessage('Bitte wählen Sie eine CSV-Datei aus.');
		}
		else {
			const response = await props.onUpload(selectedFile);
			if(response.data.error){
				setShowError(true);
				setErrorMessage(response.data.errorMessage);
			}
			else {
				setShowSuccess(true);
			}
		}
	};

	return (
		<div className={[styles.formContainer, memberStyles.memberImport].join(" ")}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Teilnehmer importieren</h1>
					</div>
					<CommandButton
						onClick={() => {
							props.onClose();
						}}
						iconProps={{ iconName: 'ChromeClose' }}
						className={styles.closeIcon}
					/>
				</div>
			</header>
			<div className={[styles.flexContainer].join(' ')}>
				<span>CSV-Datei hochladen</span>
				<IconWithCallout
					iconName='Info'
					renderCalloutContent={()=>{ return (
						<span>Hochgeladene CSV-Dateien müssen eine vorgeschriebene Struktur aufweisen. Bitte nutzen Sie hierfür die Beispiel-Datei oder eine bereits exportierte Teilnehmerliste.</span>
					);}}
					iconContainerClassName="votrInfoIcon"
				/>
			</div>
			<div className={[memberStyles.noMarginBottom].join(' ')}>
				<CommandButton
					onClick={onDownloadExampleClick}
					iconProps={{ iconName: 'Download', style: {marginLeft: '-3px'} }}
					text="Beispiel-Datei herunterladen"
					className={styles.closeIcon}
				/>
			</div>
			<div className={memberStyles.uploadInputContainer}>
				<input type="file" name="file" ref={hiddenFileInput} style={{display: 'none'}} onChange={onFileInputChange}/>
				<div className={[styles.flexContainer, styles.spaceBetween, memberStyles.uploadButton, showError ? memberStyles.showButtonError : '', showSuccess ? memberStyles.showButtonSuccess : ''].join(' ')} onClick={onSelectFileClick}>
					<div className={memberStyles.inputText}>{(selectedFile)?selectedFile.name:'CSV-Datei auswählen'}</div>
					<Icon iconName={showSuccess ? 'SkypeCheck' : 'FabricFolder'} ></Icon>
				</div>
				<div className={[memberStyles.uploadError, showError ? memberStyles.showError : ''].join(' ')} >
					{errorMessage}
				</div>
				<div className={[memberStyles.uploadSuccess, showSuccess ? memberStyles.showSuccess : ''].join(' ')} >
					Alle Teilnehmer wurden erfolgreich importiert.
				</div>
			</div>
			<div className={[styles.flexContainer, styles.panelButtons, memberStyles.buttonPanel].join(' ')}>
				<PrimaryButton
					text='Prüfen und hochladen'
					iconProps={{ iconName: 'Upload' }}
					onClick={onUploadClick}
				/>
			</div>
		</div>
	);
};
