import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const CallBlockedIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path d="M17.9594 6.93999L17.8015 7.76912C17.6535 8.54606 16.9272 9.0678 16.1042 8.98832L14.4666 8.83014C13.753 8.76121 13.2241 8.24003 13 7.5C12.6957 6.49524 12.5 5.75004 12.5 5.75004C11.7522 5.44352 11.0138 5.25 10 5.25C8.98623 5.25 8.26225 5.46487 7.5 5.75004C7.5 5.75004 7.29566 6.49592 7 7.5C6.80244 8.17094 6.49595 8.75663 5.79708 8.82686L4.16895 8.99048C3.35656 9.07212 2.57765 8.55543 2.3467 7.78171L2.09921 6.95254C1.85286 6.12721 2.0727 5.25864 2.67633 4.6724C4.10141 3.28838 6.6656 2.50825 9.99245 2.50392C13.3241 2.49964 15.5858 3.27554 17.154 4.65971C17.8139 5.24215 18.116 6.11786 17.9594 6.93999Z" />
      <path d="M6.81802 16.682C8.57539 18.4394 11.4246 18.4394 13.182 16.682C14.9394 14.9246 14.9394 12.0754 13.182 10.318C11.4246 8.56066 8.57539 8.56066 6.81802 10.318C5.06066 12.0754 5.06066 14.9246 6.81802 16.682ZM12.4749 15.9749C11.2281 17.2217 9.27466 17.3312 7.90375 16.3034L12.8034 11.4037C13.8312 12.7747 13.7217 14.7281 12.4749 15.9749ZM12.0963 10.6966L7.19664 15.5963C6.16885 14.2254 6.27834 12.2719 7.52513 11.0251C8.77192 9.77834 10.7254 9.66885 12.0963 10.6966Z" />
    </svg>
  ),
  displayName: 'CallBlockedIcon',
});
