import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const PlugsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M5 3.5C5 3.22386 4.77614 3 4.5 3C4.22386 3 4 3.22386 4 3.5V6.29289L2.14645 8.14645C2.05268 8.24021 2 8.36739 2 8.5V13.5C2 13.7761 2.22386 14 2.5 14H4V16.5C4 16.7761 4.22386 17 4.5 17C4.77614 17 5 16.7761 5 16.5V14H6V16.5C6 16.7761 6.22386 17 6.5 17C6.77614 17 7 16.7761 7 16.5V14H8.5C8.77614 14 9 13.7761 9 13.5V8.5C9 8.36739 8.94732 8.24021 8.85355 8.14645L7 6.29289V3.5C7 3.22386 6.77614 3 6.5 3C6.22386 3 6 3.22386 6 3.5V6.5C6 6.63261 6.05268 6.75979 6.14645 6.85355L8 8.70711V13H3V8.70711L4.85355 6.85355C4.94732 6.75979 5 6.63261 5 6.5V3.5Z" />
        <path d="M15 16.5V13.5C15 13.3674 15.0527 13.2402 15.1464 13.1464L17 11.2929V7H16.5C16.5001 7 16.4999 7 16.5 7L12.4993 7C12.4996 7 12.4991 7 12.4993 7H12V11.2929L13.8536 13.1464C13.9473 13.2402 14 13.3674 14 13.5V16.5C14 16.7761 13.7761 17 13.5 17C13.2239 17 13 16.7761 13 16.5V13.7071L11.1464 11.8536C11.0527 11.7598 11 11.6326 11 11.5V6.5C11 6.22386 11.2239 6 11.5 6H12V3.5C12 3.22386 12.2239 3 12.5 3H16.5C16.7761 3 17 3.22386 17 3.5V6H17.5C17.7761 6 18 6.22386 18 6.5V11.5C18 11.6326 17.9473 11.7598 17.8536 11.8536L16 13.7071V16.5C16 16.7761 15.7761 17 15.5 17C15.2239 17 15 16.7761 15 16.5ZM13 6H16V4H13V6Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M4.5 3C4.22386 3 4 3.22386 4 3.5V6.29289L2.14645 8.14645C2.05268 8.24021 2 8.36739 2 8.5V13.5C2 13.7761 2.22386 14 2.5 14H4V16.5C4 16.7761 4.22386 17 4.5 17C4.77614 17 5 16.7761 5 16.5V14H6V16.5C6 16.7761 6.22386 17 6.5 17C6.77614 17 7 16.7761 7 16.5V14H8.5C8.77614 14 9 13.7761 9 13.5V8.5C9 8.36739 8.94732 8.24021 8.85355 8.14645L7 6.29289V3.5C7 3.22386 6.77614 3 6.5 3H4.5Z" />
        <path d="M15.5 17C15.7761 17 16 16.7761 16 16.5V13.7071L17.8536 11.8536C17.9473 11.7598 18 11.6326 18 11.5V6.5C18 6.22386 17.7761 6 17.5 6H17V3.5C17 3.22386 16.7761 3 16.5 3H12.5C12.2239 3 12 3.22386 12 3.5V6H11.5C11.2239 6 11 6.22386 11 6.5V11.5C11 11.6326 11.0527 11.7598 11.1464 11.8536L13 13.7071V16.5C13 16.7761 13.2239 17 13.5 17H15.5ZM13 6V4H16V6H13Z" />
      </g>
    </svg>
  ),
  displayName: 'PlugsIcon',
});
