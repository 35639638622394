import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ParticipantAddIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2ZM6 6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6C12 7.65685 10.6569 9 9 9C7.34315 9 6 7.65685 6 6Z"
        />
        <path d="M4.00873 11C2.90315 11 2 11.8869 2 13C2 14.6912 2.83281 15.9663 4.13499 16.7966C5.41697 17.614 7.14526 18 9 18C9.41085 18 9.8155 17.9811 10.2105 17.9427C9.97298 17.6472 9.7654 17.3266 9.59233 16.9855C9.39798 16.9951 9.20041 17 9 17C7.26489 17 5.74318 16.636 4.67262 15.9534C3.62226 15.2837 3 14.3088 3 13C3 12.4467 3.44786 12 4.00873 12L9.59971 12C9.7826 11.6422 10.0035 11.3071 10.2572 11L4.00873 11Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 19C16.9853 19 19 16.9853 19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9853 12.0147 19 14.5 19ZM14.5 12C14.7761 12 15 12.2239 15 12.5V14H16.5C16.7761 14 17 14.2239 17 14.5C17 14.7761 16.7761 15 16.5 15H15V16.5C15 16.7761 14.7761 17 14.5 17C14.2239 17 14 16.7761 14 16.5V15H12.5C12.2239 15 12 14.7761 12 14.5C12 14.2239 12.2239 14 12.5 14H14V12.5C14 12.2239 14.2239 12 14.5 12Z"
        />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2Z" />
        <path d="M4.00873 11C2.90315 11 2 11.8869 2 13C2 14.6912 2.83281 15.9663 4.13499 16.7966C5.41697 17.614 7.14526 18 9 18C9.41085 18 9.8155 17.9811 10.2105 17.9427C9.45316 17.0003 9 15.8031 9 14.5C9 13.1704 9.47182 11.9509 10.2572 11L4.00873 11Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 19C16.9853 19 19 16.9853 19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9853 12.0147 19 14.5 19ZM14.5 12C14.7761 12 15 12.2239 15 12.5V14H16.5C16.7761 14 17 14.2239 17 14.5C17 14.7761 16.7761 15 16.5 15H15V16.5C15 16.7761 14.7761 17 14.5 17C14.2239 17 14 16.7761 14 16.5V15H12.5C12.2239 15 12 14.7761 12 14.5C12 14.2239 12.2239 14 12.5 14H14V12.5C14 12.2239 14.2239 12 14.5 12Z"
        />
      </g>
    </svg>
  ),
  displayName: 'ParticipantAddIcon',
});
