import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const ExcelColorIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 32 32" className={classes.svg}>
      <path
        d="M9.5 29H28.5C28.775 29 29 28.775 29 28.5V9H24.5C23.673 9 23 8.327 23 7.5V3H9.5C9.225 3 9 3.225 9 3.5V28.5C9 28.775 9.225 29 9.5 29Z"
        fill="white"
      />
      <path d="M28.293 8.00003L24 3.70703V7.50003C24 7.77503 24.225 8.00003 24.5 8.00003H28.293Z" fill="white" />
      <path
        opacity="0.64"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.56 7.854L24.146 2.439C23.8642 2.15891 23.4833 2.00117 23.086 2H9.5C8.673 2 8 2.673 8 3.5V28.5C8 29.327 8.673 30 9.5 30H28.5C29.327 30 30 29.327 30 28.5V8.914C30 8.514 29.844 8.137 29.56 7.854ZM24 3.707L28.293 8H24.5C24.2241 7.99945 24.0005 7.77591 24 7.5V3.707ZM9.5 29H28.5C28.775 29 29 28.775 29 28.5V9H24.5C23.673 9 23 8.327 23 7.5V3H9.5C9.225 3 9 3.225 9 3.5V28.5C9.00055 28.7759 9.22409 28.9995 9.5 29Z"
        fill="#605E5C"
      />
      <path
        d="M25 23H23C22.4477 23 22 22.5523 22 22C22 21.4477 22.4477 21 23 21H25C25.5523 21 26 21.4477 26 22C26 22.5523 25.5523 23 25 23Z"
        fill="#134A2C"
      />
      <path
        d="M20 23H18C17.4477 23 17 22.5523 17 22C17 21.4477 17.4477 21 18 21H20C20.5523 21 21 21.4477 21 22C21 22.5523 20.5523 23 20 23Z"
        fill="#185C37"
      />
      <path
        d="M25 19H23C22.4477 19 22 18.5523 22 18C22 17.4477 22.4477 17 23 17H25C25.5523 17 26 17.4477 26 18C26 18.5523 25.5523 19 25 19Z"
        fill="#21A366"
      />
      <path
        d="M20 19H18C17.4477 19 17 18.5523 17 18C17 17.4477 17.4477 17 18 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19Z"
        fill="#107C41"
      />
      <path
        d="M25 15H23C22.4477 15 22 14.5523 22 14C22 13.4477 22.4477 13 23 13H25C25.5523 13 26 13.4477 26 14C26 14.5523 25.5523 15 25 15Z"
        fill="#33C481"
      />
      <path
        d="M20 15H18C17.4477 15 17 14.5523 17 14C17 13.4477 17.4477 13 18 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15Z"
        fill="#21A366"
      />
      <path
        d="M3.5 25H14.5C15.3284 25 16 24.3284 16 23.5V12.5C16 11.6716 15.3284 11 14.5 11H3.5C2.67157 11 2 11.6716 2 12.5V23.5C2 24.3284 2.67157 25 3.5 25Z"
        fill="#107C41"
      />
      <path
        d="M6 22L8.174 17.99L6.182 14H7.784L8.871 16.549C8.971 16.791 9.04 16.972 9.077 17.091H9.092C9.163 16.897 9.238 16.709 9.316 16.527L10.478 14H11.948L9.906 17.967L12 22H10.435L9.18 19.2C9.12023 19.0791 9.07008 18.9537 9.03 18.825H9.012C8.97608 18.9506 8.92749 19.0722 8.867 19.188L7.574 22H6Z"
        fill="#F9F7F7"
      />
    </svg>
  ),
  displayName: 'ExcelColorIcon',
});
