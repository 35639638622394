import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const RaiseHandDisabledIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg className={classes.svg} viewBox="2 2 16 16" role="presentation" focusable="false">
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M4 6c0-.361.128-.693.34-.952L2.147 2.854a.5.5 0 11.708-.708l15 15a.5.5 0 01-.708.708l-3.568-3.569-.213.222c-.698.727-1.213 1.564-1.713 2.43A2.16 2.16 0 019.78 18.02H7.234c-.3 0-.595-.073-.861-.211a2.548 2.548 0 01-1.18-1.29l-.585-1.422A8.088 8.088 0 014 12.02V6zm8.871 7.578L7 7.708V9.5a.5.5 0 01-1 0V6.707l-.942-.942A.498.498 0 005 6v6.02c0 .925.181 1.841.533 2.697l.585 1.42c.139.338.393.616.716.785.124.064.261.098.4.098H9.78a1.16 1.16 0 001.006-.582c.54-.936 1.104-1.838 1.858-2.623l.227-.237zM10 3.5v4.379l.909.909A.498.498 0 0011 8.5v-4a.5.5 0 011 0v5.379l.924.924c.199-.198.399-.386.654-.513.697-.349 2.223-.575 2.407.498-.176.137-.356.272-.537.408a15.22 15.22 0 00-1.182.949l.707.706c.375-.345.79-.653 1.203-.96.2-.148.4-.295.592-.446A.602.602 0 0017 10.97c0-.754-.536-1.575-1.217-1.79-.91-.306-1.796-.213-2.652.215a2.452 2.452 0 00-.131.07V4.5a1.5 1.5 0 00-2.053-1.395 1.5 1.5 0 00-2.894 0 1.5 1.5 0 00-1.958.869L7 4.879V4.5a.5.5 0 011 0v1.379l1 1V3.5a.5.5 0 011 0z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M4 5.25c0-.154.047-.297.126-.417l-1.98-1.98a.5.5 0 11.708-.707l15 15a.5.5 0 01-.708.708l-3.716-3.717c-.254.318-.488.646-.694.98a31.683 31.683 0 00-1.054 1.867C11.322 17.661 11.094 18 10 18H6.955C6 18 5.637 17.467 5 16s-1-2.944-1-3.5V5.25zm2.5 1.957l-1-1V9a.5.5 0 001 0V7.207zM8 3.75v2.129l-1.5-1.5V3.75a.75.75 0 011.5 0zM11.11 8.988l3.7 3.7c.224-.198.454-.386.686-.56l1.313-.985a.478.478 0 00.191-.382.605.605 0 00-.016-.145C16.787 9.824 15.971 9.5 15 9.5c-.608 0-1.338.324-2 .75v-6.5a.75.75 0 00-1.5 0V8.5a.5.5 0 01-.39.488zM9 6.879l1.5 1.5V2.75a.75.75 0 00-1.5 0v4.129z" />
      </g>
    </svg>
  ),
  displayName: 'RaiseHandDisabledIcon',
});
