import { BusinessDataType } from './enums';
import { ISurvey, IQuestion, IMember, ISurveyBase } from './interfaces';

export const RESET_ALL = 'RESET_ALL';
export const DATA_LOADING = 'DATA_LOADING';
export const SURVEYS_LOADED = 'SURVEYS_LOADED';
export const SURVEY_LOADED = 'SURVEY_LOADED';
export const QUESTIONS_LOADED = 'QUESTIONS_LOADED';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const MEMBERS_LOADED = 'MEMBERS_LOADED';
export const UPSERT_MEMBERS = 'UPSERT_MEMBERS';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const STARTSTOP_QUESTION = 'STARTSTOP_QUESTION';

// Action Definition
interface IResetAllAction {
	type: typeof RESET_ALL;
}

interface IDataLoadingAction {
	type: typeof DATA_LOADING;
	businessDataType: BusinessDataType;
	isLoading: boolean;
}

interface ISurveysLoadedAction {
	type: typeof SURVEYS_LOADED;
	surveys: ISurveyBase[];
}

interface ISurveyLoadedAction {
	type: typeof SURVEY_LOADED;
	survey?: ISurvey;
}

interface IQuestionsLoadedAction {
	type: typeof QUESTIONS_LOADED;
	questions: IQuestion[];
}

interface IUpdateQuestionAction {
	type: typeof UPDATE_QUESTION;
	question: IQuestion;
}

interface IRemoveQuestionAction {
	type: typeof REMOVE_QUESTION;
	question: IQuestion;
}

interface IMembersLoadedAction {
	type: typeof MEMBERS_LOADED;
	surveyId: string;
	members: IMember[];
	lastChanged: Date | undefined;
}

interface IUpsertMembersAction {
	type: typeof UPSERT_MEMBERS;
	surveyId: string;
	members: IMember[];
	lastChanged: Date | undefined;
}

interface IRemoveMemberAction {
	type: typeof REMOVE_MEMBER;
	surveyId: string;
	member: IMember;
	lastChanged: Date | undefined;
}

interface IStartStopQuestionAction {
	type: typeof STARTSTOP_QUESTION;
	question: IQuestion;
}

// Union Action Types
export type IBusinessActionTypes =
	| IResetAllAction
	| IDataLoadingAction
	| ISurveysLoadedAction
	| ISurveyLoadedAction
	| IQuestionsLoadedAction
	| IUpdateQuestionAction
	| IRemoveQuestionAction
	| IMembersLoadedAction
	| IUpsertMembersAction
	| IRemoveMemberAction
	| IStartStopQuestionAction;
