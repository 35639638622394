import { IQuestion, IAnswerOption, ISurvey } from '../store/business/interfaces';
import { xhr, download } from '../xhr';
import { store } from '../store';
import { businessLoading, updateQuestion, removeQuestion } from '../store/business/actions';
import { BusinessDataType } from '../store/business/enums';
import { createGuid } from '../shared/utils';

export class QuestionService {
	public static parse(data: any): IQuestion {
		const answerOptions = (data['answerOptions'] as []) || [];
		const votesPerMember = parseInt(data['votesPerMember']);
		const attendees = data['attendees'] || [];
		const title = data['title'];
		const startTime = data['startTime'] ? new Date(data['startTime']) : undefined;
		const stopTime = data['stopTime'] ? new Date(data['stopTime']) : undefined ;
		const status = stopTime > new Date(0) ? 'stopped' : (startTime > new Date(0) ? 'started' : 'new');

		return {
			id: data['id'],
			title,
			description: data['description'],
			kind: data['kind'],
			votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
			allowVotesSplitting: !!data['allowVotesSplitting'],
			answerOptions: answerOptions.map(
				(a: any) =>
					({
						id: a['id'],
						title: a['title'],
						votesCount: parseFloat(a['votes'] || 0),
						attendees: a['attendees'] || [],
						type: 'answer',
					} as IAnswerOption)
			),
			security: data['security'],
			votesCount: attendees.length, // TODO: sum up vote count of attendees
			attendees,
			eTag: data['eTag'],
			startTime: startTime,
			stopTime: stopTime,
			status: status,
			type: 'question',
			sortOrder: title,
			loaded: new Date()
		} as IQuestion;
	}
	public static parseArray(data: any[]): IQuestion[] {
		if (data && data.length) {
			return data.map(QuestionService.parse);
		}
		return [];
	}
	public static emptyQuestion(): IQuestion {
		return {
			id: '',
			title: '',
			description: '',
			votesPerMember: 1,
			allowVotesSplitting: true,
			kind: 'choice',
			security: 'public',
			answerOptions: [],
			votesCount: 0,
			attendees: [],
			status: 'unsaved',
			eTag: '*',
			startTime: undefined,
			stopTime: undefined,
			type: 'question',
			loaded: new Date()
		};
	}
	public static createQuestion(): IQuestion {
		const question = QuestionService.emptyQuestion();
		question.id = createGuid();
		store.dispatch(updateQuestion(question));
		return question;
	}

	public static removeQuestion(question: IQuestion) {
		store.dispatch(removeQuestion(question));
	}

	public static async upsertQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question`, {
				method: 'POST',
				data: question,
			});
			const q = QuestionService.parse(response.data);
			store.dispatch(updateQuestion(q));
			// showMessage(`Abstimmung erfolgreich aktualisiert`, MessageType.SUCCESS);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}

	public static async loadQuestion(survey: ISurvey, id?: string) {
		const { business } = store.getState();
		if (!survey || !id || business.loading[BusinessDataType.Question]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Question, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question/${id}`, {
				method: 'GET',
			});
			const q = QuestionService.parse(response.data);
			q.loaded = new Date();
			store.dispatch(updateQuestion(q));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Question, false));
		}
	}

	public static async exportQuestion(survey: ISurvey, question: IQuestion) {
		if (!survey || !question) return;
		await download(`Survey/${survey.id}/Question/${question.id}/Export`);
	}

	public static async resetQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question/${question.id}/Reset`, {
				method: 'PATCH',
			});
			const q = QuestionService.parse(response.data);
			q.votesCount = 0;
			store.dispatch(updateQuestion(q));
			// showMessage(`Abstimmung erfolgreich zurückgesetzt`, MessageType.SUCCESS);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}

	public static async deleteQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			await xhr(`Survey/${survey.id}/Question/${question.id}`, {
				method: 'DELETE',
			});
			store.dispatch(removeQuestion(question));
			// showMessage(`Abstimmung erfolgreich gelöscht`, MessageType.SUCCESS);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}
}
