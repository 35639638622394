import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const BellIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M9.99766 2C13.1466 2 15.7416 4.33488 15.9821 7.3554L15.9955 7.57762L16 7.80214L15.999 11.398L16.9244 13.6202C16.947 13.6743 16.9647 13.7302 16.9774 13.7871L16.9926 13.8733L17.0013 14.0046C17.0013 14.4526 16.7048 14.8387 16.2521 14.9677L16.1358 14.9945L16.0013 15.0046L12.4996 15.004L12.4946 15.1653C12.4095 16.469 11.3252 17.5 10 17.5C8.67453 17.5 7.58998 16.4685 7.50533 15.1644L7.49962 15.004L3.99891 15.0046C3.91096 15.0046 3.82358 14.993 3.73902 14.9702L3.61456 14.9277C3.20378 14.7567 2.96181 14.3392 3.01221 13.8757L3.0333 13.7483L3.07572 13.6202L3.99902 11.401L4.0001 7.79281L4.0044 7.56824C4.12702 4.45115 6.77104 2 9.99766 2ZM11.4996 15.004H8.49962L8.50697 15.1454C8.57552 15.8581 9.14275 16.425 9.85556 16.4931L10 16.5C10.7797 16.5 11.4205 15.9051 11.4931 15.1445L11.4996 15.004ZM9.99766 3C7.37511 3 5.22717 4.92372 5.01715 7.38498L5.00393 7.59723L5.00002 7.80214V11.5L4.96161 11.6922L3.9989 14.0046L15.9566 14.0066L16.0019 14.0045L15.0384 11.6922L15 11.5L15.0001 7.81241L14.996 7.60831C14.8909 5.0349 12.6947 3 9.99766 3Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M12.4497 16.0016C12.2174 17.1419 11.2089 18 10 18C8.79108 18 7.78257 17.1419 7.55033 16.0016H12.4497ZM9.99765 2C13.1466 2 15.7415 4.33488 15.9821 7.3554L15.9955 7.57762L16 7.80214L15.999 11.408L16.9528 13.9951L16.9784 14.0796L16.9939 14.1664L16.9991 14.2546C16.9991 14.5697 16.8034 14.845 16.4771 14.9618L16.3636 14.9947L16.2491 15.0046H3.75136C3.66286 15.0046 3.57507 14.9889 3.49203 14.9583C3.20513 14.8526 3.01636 14.5865 3.00958 14.2422L3.01418 14.1247L3.04762 13.9952L3.99901 11.411L4.00009 7.79281L4.00439 7.56824C4.12701 4.45115 6.77103 2 9.99765 2Z"
      />
    </svg>
  ),
  displayName: 'BellIcon',
});
