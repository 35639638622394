import styles from './Sidepanel.module.scss'; // TODO: use own module.scss
import attendeesStyle from './AttendeesPanel.module.scss'; // TODO: use own module.scss

import React, { useEffect, useState } from 'react';
import { IAttendee, IVotable } from '../../store/business/interfaces';
import { AttendeesList } from './AttendeesList';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { formatFloat } from '../../shared/utils';
import { CommandButton } from 'office-ui-fabric-react';

export interface Props {
	votable?: IVotable;
	members?: IAttendee[];
	showVoteWeight: boolean;
	onClose: () => void;
}

export const AttendeesPanel = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const [membersNotVotedYet, setMembersNotVotedYet] = useState(new Array<IAttendee>());
	const [membersAlreadyVoted, setMembersAlreadyVoted] = useState(new Array<IAttendee>());
	const [columnCountVoted, setColumnCountVoted] = useState(0);
	const [columnCountNotVoted, setColumnCountNotVoted] = useState(4);

	const attendeeComparer = (a1: IAttendee, a2: IAttendee) => {
		return a1.title.localeCompare(a2.title);
	};

	useEffect(() => {
		const membersNotVoted = props.members?.filter((m) => !props.votable?.attendees?.find((a) => a.id === m.id)).sort(attendeeComparer) || new Array<IAttendee>();
		setMembersNotVotedYet(membersNotVoted);

		const membersVoted = props.votable?.attendees?.map(a=>{ return {id:a.id, voteWeight:a.voteWeight, title:a.text} as IAttendee;}) || new Array<IAttendee>();
		setMembersAlreadyVoted(membersVoted);

		if(membersVoted.length === 0) {
			setColumnCountVoted(0);
			setColumnCountNotVoted(4);
		}
		else if(membersNotVoted.length !== 0) {
			const totalVotes = membersNotVoted.length + membersVoted.length;
			const votedPercentage = (membersVoted.length * 100) / totalVotes;
			if(votedPercentage <= ((1/3)*100) || membersVoted.length === 1) {
				setColumnCountVoted(1);
				setColumnCountNotVoted(3);
			}
			else if(votedPercentage <= ((2/3)*100)) {
				setColumnCountVoted(2);
				setColumnCountNotVoted(2);
			}
			else {
				setColumnCountVoted(3);
				setColumnCountNotVoted(1);
			}
		}
		else {
			setColumnCountVoted(4);
			setColumnCountNotVoted(0);
		}

	}, [props.members, props.votable]);

	const getAttendeeCountInfo = (attendees: IAttendee[]): string => {
		let info = `(`;
		if(survey.useVoteWeightInPercent) {
			const attendeesPercent = formatFloat(attendees?.reduce((sum, currentAttendee) => sum + currentAttendee.voteWeight, 0));
			info += `${attendeesPercent}%`;
		}
		else {
			info += `${attendees?.length}`;
		}
		info += `)`;
		return info;
	};

	if (!props.votable?.attendees) {
		return <></>;
	}

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Teilnehmer</h1>
					</div>
					<CommandButton
						onClick={() => {
							props.onClose();
						}}
						iconProps={{ iconName: 'ChromeClose' }}
						className={styles.closeIcon}
					/>
				</div>
			</header>
			<div className={attendeesStyle.attendeesGrid}  style={{gridTemplateColumns:` ${columnCountVoted * 25}% ${columnCountNotVoted * 25}%`}}>
				<AttendeesList
					votableAttendees={membersAlreadyVoted}
					showVoteWeight={props.showVoteWeight}
					showNobodyLabel={true}
					onClose={props.onClose}
					title={`${props.votable?.type === 'virtualNotVoted' ? 'Nicht abgestimmt' : 'Abgestimmt'} ${getAttendeeCountInfo(membersAlreadyVoted)}`}
					columnCount={columnCountVoted}
				/>
				{props.votable.type === 'question' ? (
					<AttendeesList
						votableAttendees={membersNotVotedYet}
						showVoteWeight={props.showVoteWeight}
						showNobodyLabel={false}
						title={`Nicht abgestimmt ${getAttendeeCountInfo(membersNotVotedYet)}`}
						columnCount={columnCountNotVoted}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
