import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const LeaveIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      role="presentation"
      style={{ overflow: 'visible' }}
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svgFlippingInRtl}
    >
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2ZM6 6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6C12 7.65685 10.6569 9 9 9C7.34315 9 6 7.65685 6 6ZM4.00873 11C2.90315 11 2 11.8869 2 13C2 14.6912 2.83281 15.9663 4.13499 16.7966C5.41697 17.614 7.14526 18 9 18C9.41085 18 9.8155 17.9811 10.2105 17.9427C9.97298 17.6472 9.7654 17.3266 9.59233 16.9855C9.39798 16.9951 9.20041 17 9 17C7.26489 17 5.74318 16.636 4.67262 15.9534C3.62226 15.2837 3 14.3088 3 13C3 12.4467 3.44786 12 4.00873 12L9.59971 12C9.7826 11.6422 10.0035 11.3071 10.2572 11L4.00873 11ZM19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM12.1468 14.146L12.1443 14.1486C12.0974 14.196 12.062 14.2505 12.0379 14.3086C12.0139 14.3667 12.0004 14.4303 12 14.497L12 14.5L12 14.503C12.0004 14.5697 12.0139 14.6333 12.0379 14.6914C12.0623 14.7504 12.0985 14.8056 12.1464 14.8536L14.1464 16.8536C14.3417 17.0488 14.6583 17.0488 14.8536 16.8536C15.0488 16.6583 15.0488 16.3417 14.8536 16.1464L13.7071 15H16.5C16.7761 15 17 14.7761 17 14.5C17 14.2239 16.7761 14 16.5 14H13.7071L14.8536 12.8536C15.0488 12.6583 15.0488 12.3417 14.8536 12.1464C14.6583 11.9512 14.3417 11.9512 14.1464 12.1464L12.1468 14.146Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2ZM4.00873 11C2.90315 11 2 11.8869 2 13C2 14.6912 2.83281 15.9663 4.13499 16.7966C5.41697 17.614 7.14526 18 9 18C9.41085 18 9.8155 17.9811 10.2105 17.9427C9.45316 17.0003 9 15.8031 9 14.5C9 13.1704 9.47182 11.9509 10.2572 11L4.00873 11ZM19 14.5C19 16.9853 16.9853 19 14.5 19C12.0147 19 10 16.9853 10 14.5C10 12.0147 12.0147 10 14.5 10C16.9853 10 19 12.0147 19 14.5ZM12.1468 14.146L12.1443 14.1486C12.0974 14.196 12.062 14.2505 12.0379 14.3086C12.0139 14.3667 12.0004 14.4303 12 14.497L12 14.5L12 14.503C12.0004 14.5697 12.0139 14.6333 12.0379 14.6914C12.0623 14.7504 12.0985 14.8056 12.1464 14.8536L14.1464 16.8536C14.3417 17.0488 14.6583 17.0488 14.8536 16.8536C15.0488 16.6583 15.0488 16.3417 14.8536 16.1464L13.7071 15H16.5C16.7761 15 17 14.7761 17 14.5C17 14.2239 16.7761 14 16.5 14H13.7071L14.8536 12.8536C15.0488 12.6583 15.0488 12.3417 14.8536 12.1464C14.6583 11.9512 14.3417 11.9512 14.1464 12.1464L12.1468 14.146Z"
      />
    </svg>
  ),
  displayName: 'LeaveIcon',
});
