import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ContactGroupCallIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      className={classes.svg}
      viewBox="2 2 16 16"
      role="presentation"
      focusable="false"
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M11 10C12.1046 10 13 10.8954 13 12V13.5C13 15.5544 10.912 17 7.5 17C4.08805 17 2 15.5544 2 13.5V12C2 10.8954 2.89543 10 4 10H11ZM4 11C3.44772 11 3 11.4477 3 12V13.5C3 14.9071 4.57862 16 7.5 16C10.4214 16 12 14.9071 12 13.5V12C12 11.4477 11.5523 11 11 11H4Z" />
        <path d="M15.1615 14.8399L15.0865 14.8148C15.1314 14.8027 15.1754 14.79 15.2186 14.7767C15.1998 14.7981 15.1808 14.8192 15.1615 14.8399Z" />
        <path d="M14.9039 10.8982L14.867 10.9995L13.829 10.9991C13.6971 10.6265 13.4937 10.2876 13.2353 9.99912L15.2849 9.99977L14.9039 10.8982Z" />
        <path d="M7.5 2C9.433 2 11 3.567 11 5.5C11 7.433 9.433 9 7.5 9C5.567 9 4 7.433 4 5.5C4 3.567 5.567 2 7.5 2ZM7.5 3C6.11929 3 5 4.11929 5 5.5C5 6.88071 6.11929 8 7.5 8C8.88071 8 10 6.88071 10 5.5C10 4.11929 8.88071 3 7.5 3Z" />
        <path d="M14.5 4C15.8807 4 17 5.11929 17 6.5C17 7.88071 15.8807 9 14.5 9C13.1193 9 12 7.88071 12 6.5C12 5.11929 13.1193 4 14.5 4ZM14.5 5C13.6716 5 13 5.67157 13 6.5C13 7.32843 13.6716 8 14.5 8C15.3284 8 16 7.32843 16 6.5C16 5.67157 15.3284 5 14.5 5Z" />
        <path d="M15.8345 11.2649L16.2337 10.3236C16.4209 9.88219 16.8693 9.64207 17.3091 9.73369L17.4031 9.75845L17.9029 9.91817C18.3986 10.0765 18.7781 10.5011 18.9007 11.0343C19.192 12.3013 18.8425 13.8439 17.8522 15.6622C16.8634 17.4777 15.7796 18.5694 14.6009 18.9371C14.1432 19.0798 13.6515 18.9761 13.2816 18.6656L13.1839 18.5758L12.8043 18.1947C12.4753 17.8644 12.4075 17.3419 12.6281 16.9331L12.6842 16.8409L13.2563 16.0003C13.4815 15.6695 13.8666 15.513 14.2377 15.5921L14.3384 15.6195L15.3947 15.9718C15.8162 15.6528 16.1677 15.2513 16.4491 14.7673C16.6904 14.3524 16.8481 13.9319 16.9225 13.5057L16.9527 13.2922L16.0732 12.4574C15.7835 12.1824 15.6782 11.7542 15.7967 11.3688L15.8345 11.2649Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M11 10C12.1046 10 13 10.8954 13 12V13.5C13 15.5544 10.912 17 7.5 17C4.08805 17 2 15.5544 2 13.5V12C2 10.8954 2.89543 10 4 10H11Z" />
        <path d="M14.4461 14.614C14.2463 14.5715 14.0464 14.5617 13.8519 14.5815C13.9287 14.3135 13.9765 14.0322 13.9932 13.7387L14 13.5V12C14 11.3081 13.7658 10.6709 13.3723 10.1634L13.2353 9.99912L15.2849 9.99977L14.9039 10.8982L14.8484 11.0504L14.8409 11.075C14.6155 11.8081 14.8083 12.6356 15.3848 13.1827L15.8641 13.6376C15.8014 13.8408 15.7098 14.0495 15.5847 14.2646C15.4588 14.4811 15.3177 14.6721 15.1615 14.8399L14.6283 14.6621L14.4736 14.6199L14.4461 14.614Z" />
        <path d="M7.5 2C9.433 2 11 3.567 11 5.5C11 7.433 9.433 9 7.5 9C5.567 9 4 7.433 4 5.5C4 3.567 5.567 2 7.5 2Z" />
        <path d="M14.5 4C15.8807 4 17 5.11929 17 6.5C17 7.88071 15.8807 9 14.5 9C13.1193 9 12 7.88071 12 6.5C12 5.11929 13.1193 4 14.5 4Z" />
        <path d="M15.8345 11.2649L16.2337 10.3236C16.4209 9.88219 16.8693 9.64207 17.3091 9.73369L17.4031 9.75845L17.9029 9.91817C18.3986 10.0765 18.7781 10.5011 18.9007 11.0343C19.192 12.3013 18.8425 13.8439 17.8522 15.6622C16.8634 17.4777 15.7796 18.5694 14.6009 18.9371C14.1432 19.0798 13.6515 18.9761 13.2816 18.6656L13.1839 18.5758L12.8043 18.1947C12.4753 17.8644 12.4075 17.3419 12.6281 16.9331L12.6842 16.8409L13.2563 16.0003C13.4815 15.6695 13.8666 15.513 14.2377 15.5921L14.3384 15.6195L15.3947 15.9718C15.8162 15.6528 16.1677 15.2513 16.4491 14.7673C16.6904 14.3524 16.8481 13.9319 16.9225 13.5057L16.9527 13.2922L16.0732 12.4574C15.7835 12.1824 15.6782 11.7542 15.7967 11.3688L15.8345 11.2649Z" />
      </g>
    </svg>
  ),
  displayName: 'ContactGroupCallIcon',
});
