import React from 'react';
import styles from './TeamsSettings.module.scss';

export interface Props {
}

export const TeamsSettings = (props: Props) => {
	return (
		<div className={styles.welcomeToVotr}/>
	);
};