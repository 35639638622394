import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ShiftActivityIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M10.0024 2.48352C10.0204 2.75907 9.8116 2.99706 9.53604 3.01507C5.88659 3.25361 3 6.28989 3 9.99995C3 13.8659 6.13401 17 10 17C13.7105 17 16.747 14.1127 16.985 10.4627C17.0029 10.1872 17.2409 9.97835 17.5164 9.99631C17.792 10.0143 18.0008 10.2522 17.9828 10.5278C17.7108 14.7002 14.241 18 10 18C5.58172 18 2 14.4182 2 9.99995C2 5.75946 5.29899 2.28988 9.47082 2.0172C9.74637 1.99919 9.98436 2.20796 10.0024 2.48352Z" />
        <path d="M11.0071 2.55024C11.062 2.27959 11.3258 2.10464 11.5964 2.15947C12.1561 2.27285 12.6946 2.44464 13.2053 2.66819C13.4583 2.77893 13.5736 3.07377 13.4628 3.32673C13.3521 3.5797 13.0573 3.695 12.8043 3.58426C12.3579 3.38884 11.8872 3.23868 11.3979 3.13956C11.1272 3.08473 10.9523 2.82088 11.0071 2.55024Z" />
        <path d="M17.3358 6.80347C17.2254 6.55037 16.9307 6.43471 16.6776 6.54514C16.4245 6.65557 16.3089 6.95027 16.4193 7.20337C16.6128 7.64691 16.7617 8.11436 16.8602 8.60011C16.9151 8.87074 17.179 9.04562 17.4497 8.99071C17.7203 8.93581 17.8952 8.67191 17.8403 8.40128C17.7275 7.84563 17.5572 7.31083 17.3358 6.80347Z" />
        <path d="M14.4148 3.91597C14.5916 3.70385 14.9068 3.67521 15.119 3.85202C15.5074 4.1758 15.8647 4.53571 16.1857 4.92661C16.3609 5.14003 16.33 5.45509 16.1165 5.63033C15.9031 5.80556 15.588 5.7746 15.4128 5.56118C15.1318 5.21889 14.8189 4.90371 14.4787 4.62018C14.2666 4.44338 14.238 4.12809 14.4148 3.91597Z" />
        <path d="M10 5.5C10 5.22386 9.77614 5 9.5 5C9.22386 5 9 5.22386 9 5.5V10.5C9 10.7761 9.22386 11 9.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H10V5.5Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M9.99925 2.70657C10.0415 3.11863 9.74166 3.48689 9.3296 3.52911C6.05282 3.86486 3.49609 6.63447 3.49609 10.0003C3.49609 13.5931 6.40865 16.5057 10.0015 16.5057C13.3669 16.5057 16.1363 13.9495 16.4725 10.6733C16.5148 10.2612 16.8831 9.96147 17.2952 10.0038C17.7072 10.046 18.007 10.4144 17.9647 10.8264C17.5507 14.8599 14.1439 18.0057 10.0015 18.0057C5.58023 18.0057 1.99609 14.4215 1.99609 10.0003C1.99609 5.85734 5.14259 2.45027 9.17671 2.03692C9.58877 1.9947 9.95703 2.29451 9.99925 2.70657Z" />
        <path d="M11.0196 2.64066C11.116 2.23782 11.5207 1.98942 11.9235 2.08584C12.3023 2.1765 12.6708 2.29373 13.0268 2.43544C13.4117 2.58861 13.5995 3.02476 13.4463 3.40961C13.2932 3.79447 12.857 3.98228 12.4722 3.82911C12.1824 3.71379 11.8826 3.61841 11.5744 3.54463C11.1715 3.44822 10.9231 3.04349 11.0196 2.64066Z" />
        <path d="M17.5656 6.97335C17.4124 6.58852 16.9762 6.40078 16.5914 6.55402C16.2066 6.70726 16.0188 7.14345 16.1721 7.52827C16.2875 7.81815 16.383 8.11811 16.4568 8.42649C16.5533 8.82932 16.958 9.07767 17.3609 8.98121C17.7637 8.88475 18.012 8.48 17.9156 8.07718C17.8248 7.69821 17.7075 7.32956 17.5656 6.97335Z" />
        <path d="M14.2919 3.92605C14.5638 3.61356 15.0375 3.58065 15.35 3.85253C15.6571 4.11977 15.9439 4.40975 16.2078 4.71988C16.4762 5.03535 16.4381 5.50869 16.1226 5.77712C15.8071 6.04554 15.3338 6.0074 15.0654 5.69193C14.8501 5.43887 14.616 5.20223 14.3654 4.98416C14.0529 4.71227 14.02 4.23854 14.2919 3.92605Z" />
        <path d="M10.0017 5.75C10.0017 5.33579 9.66592 5 9.25171 5C8.8375 5 8.50171 5.33579 8.50171 5.75V10.75C8.50171 11.1642 8.8375 11.5 9.25171 11.5H12.2517C12.6659 11.5 13.0017 11.1642 13.0017 10.75C13.0017 10.3358 12.6659 10 12.2517 10H10.0017V5.75Z" />
      </g>
    </svg>
  ),
  displayName: 'ShiftActivityIcon',
});
