import React, { useRef, ReactNode } from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styles from './EntityItem.module.scss';

export interface Props {
	children?: ReactNode;
	id: string;
	index: Number;
	onMove?: (dragIndex: Number, hoverIndex: Number) => void;
	onDrop?: () => void;
	className: string;
}

interface IDragItem {
	index: Number;
	id: string;
	type: string;
}

export const EntityItem = (props: Props) => {
	const ref = useRef<HTMLTableRowElement>(null);
	const [, drop] = useDrop({
		accept: 'Entity',
		hover: (item: IDragItem, monitor) => {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = props.index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			if (props.onMove) props.onMove(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
		drop: () => {
			if (props.onDrop) props.onDrop();
		},
	});
	const [{ opacity }, drag] = useDrag({
		item: { type: 'Entity', id: props.id, index: props.index },
		collect: (monitor) => ({
			opacity: monitor.isDragging() ? 0.4 : 1,
		}),
	});

	drop(ref);

	return (
		<tr className={[styles.entityItem, props.className, props.onMove ? styles.droppableContainer : ''].join(' ')} ref={ref} style={{ opacity }}>
			{props.onMove && (
				<td className={[styles.entityItemScrollIconContainer, 'noGrow'].join(' ')} ref={drag}>
					<FontIcon iconName='GripperDotsVertical' title='Verschieben' />
				</td>
			)}
			{props.children}
		</tr>
	);
};
