import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const ShieldKeyholeIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M11.5 9C11.5 9.65311 11.0826 10.2087 10.5 10.4146V12.5C10.5 12.7761 10.2761 13 10 13C9.72386 13 9.5 12.7761 9.5 12.5V10.4146C8.9174 10.2087 8.5 9.65311 8.5 9C8.5 8.17157 9.17157 7.5 10 7.5C10.8284 7.5 11.5 8.17157 11.5 9ZM10.2774 2.08397C10.1094 1.97201 9.8906 1.97201 9.72265 2.08397C7.78446 3.3761 5.68833 4.1823 3.42929 4.50503C3.18296 4.54021 3 4.75117 3 5V9.5C3 13.3913 5.30699 16.2307 9.82051 17.9667C9.93605 18.0111 10.064 18.0111 10.1795 17.9667C14.693 16.2307 17 13.3913 17 9.5V5C17 4.75117 16.817 4.54021 16.5707 4.50503C14.3117 4.1823 12.2155 3.3761 10.2774 2.08397ZM4 5.42787C5.98541 5.09055 7.85275 4.39606 9.59914 3.34583L10 3.09715L10.4009 3.34583C12.1473 4.39606 14.0146 5.09055 16 5.42787V9.5C16 12.892 14.0321 15.3634 10 16.9632C5.96795 15.3634 4 12.892 4 9.5V5.42787Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M10.2774 2.08397C10.1094 1.97201 9.8906 1.97201 9.72265 2.08397C7.78446 3.3761 5.68833 4.1823 3.42929 4.50503C3.18296 4.54021 3 4.75117 3 5V9.5C3 13.3913 5.30699 16.2307 9.82051 17.9667C9.93605 18.0111 10.064 18.0111 10.1795 17.9667C14.693 16.2307 17 13.3913 17 9.5V5C17 4.75117 16.817 4.54021 16.5707 4.50503C14.3117 4.1823 12.2155 3.3761 10.2774 2.08397ZM11.5 9C11.5 9.65311 11.0826 10.2087 10.5 10.4146V12.5C10.5 12.7761 10.2761 13 10 13C9.72386 13 9.5 12.7761 9.5 12.5V10.4146C8.9174 10.2087 8.5 9.65311 8.5 9C8.5 8.17157 9.17157 7.5 10 7.5C10.8284 7.5 11.5 8.17157 11.5 9Z"
      />
    </svg>
  ),
  displayName: 'ShieldKeyholeIcon',
});
