import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const OneNoteIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="0 0 28 28" className={classes.svg}>
      <path
        d="M25 3.00011H9C8.448 3.00011 8 3.44811 8 4.00011V24.0001C8 24.5521 8.448 25.0001 9 25.0001H22.229C24.312 25.0001 26 23.3121 26 21.2291V4.00011C26 3.44811 25.552 3.00011 25 3.00011Z"
        fill="#CA64EA"
      />
      <path
        opacity="0.75"
        d="M15.8332 8.00011H8.00024V22.0001H15.6042C16.3122 22.0001 17.0002 21.2871 17.0002 20.6461V9.16711C17.0002 8.52511 16.4752 8.00011 15.8332 8.00011Z"
        fill="black"
      />
      <path
        d="M14.8334 20.9999H3.16637C2.52537 20.9999 2.00037 20.4749 2.00037 19.8329V8.16689C2.00037 7.52489 2.52537 6.99989 3.16637 6.99989H14.8334C15.4754 6.99989 16.0004 7.52489 16.0004 8.16689V19.8329C16.0004 20.4749 15.4754 20.9999 14.8334 20.9999Z"
        fill="#7719AA"
      />
      <path
        d="M5.79846 9.9998H7.50346L10.4435 15.1628C10.5965 15.3958 10.6985 15.7888 10.7745 15.9298H10.7965C10.7635 15.7338 10.7475 15.3798 10.7475 14.8678V9.9998H12.2015V17.9998H10.6055L7.55246 12.6788C7.40746 12.4538 7.30346 12.2698 7.24146 12.1288H7.21446C7.24346 12.3678 7.25846 12.7568 7.25846 13.2938V17.9998H5.79846V9.9998Z"
        fill="white"
      />
      <path d="M20 13H26V7H20V13Z" fill="#AE4BD5" />
      <path d="M20 19H26V13H20V19Z" fill="#9332BF" />
      <path d="M20 19.0001V25.0001H25C25.552 25.0001 26 24.5521 26 24.0001V19.0001H20Z" fill="#7719AA" />
    </svg>
  ),
  displayName: 'OneNoteIcon',
});
